import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ListSubheader from '@mui/material/ListSubheader';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import GroupIcon from '@mui/icons-material/Group';
import InputAdornment from '@mui/material/InputAdornment';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import { Trans } from '@lingui/macro';

import Avatar from './misc/Avatar';
import Select from './misc/Select';
import Dialog from './misc/modals/Dialog';
import { useService } from './contexts/Service';

export default function Header(props) {
	const navigate = useNavigate();
	const location = useLocation();
	const { service, control, dispatch, backdrop } = useService();
	const [$addGroup, setAddGroup] = React.useState({
		open: false,
		name: '',
	});

	const handleGroupChange = (event) => {
		const value = event.target.value;

		if (value.length === 0) {
			return;
		}

		if (value === 'create') {
			handleAddGroup();
			return;
		}

		let pathname = location.pathname;

		if (control.selectedGroupId.length === 0) {
			pathname = '/group/' + value + '/cores';
		} else {
			pathname = pathname.replace('/group/' + control.selectedGroupId + '/', '/group/' + value + '/');
		}

		dispatch('select_group', value);
		navigate(pathname);
	};

	const handleAction = async (action) => {
		switch (action) {
			case 'logout':
				await props.onLogout();
				navigate('/');
				break;
			case 'invitations':
				dispatch('select_group', '');
				navigate('/invitations');
				break;
			case 'settings':
				break;
			default:
				break;
		}
	};

	const handleAddGroup = () => {
		setAddGroup({
			...$addGroup,
			open: true,
			name: '',
		});
	};

	const handleAddGroupAbort = () => {
		setAddGroup({
			...$addGroup,
			open: false,
		});
	};

	const handleAddGroupName = (event) => {
		const value = event.target.value;

		setAddGroup({
			...$addGroup,
			name: value,
		});
	};

	const handleAddGroupDone = async () => {
		backdrop(true);

		const group = await service.GroupAdd($addGroup.name);
		if (group !== null) {
			await service.UpdateUser();

			dispatch('set_groups', service.Groups());
			navigate('/group/' + group.id);
		}

		setAddGroup({
			...$addGroup,
			open: false,
		});

		backdrop(false);
	};

	const myGroups = control.groups
		.filter((group) => group.owner === true)
		.map((group) => (
			<MenuItem key={group.id} value={group.id}>
				{group.name}
			</MenuItem>
		));

	const joinedGroups = control.groups
		.filter((group) => group.owner === false)
		.map((group) => (
			<MenuItem key={group.id} value={group.id}>
				{group.name}
			</MenuItem>
		));

	let selectedGroupId = control.selectedGroupId.length === 0 ? 'none' : control.selectedGroupId;

	return (
		<React.Fragment>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
				sx={{
					width: '100%',
					mt: '0px',
					pl: '0px',
					pr: '0px',
					marginBottom: -1,
					paddingLeft: 1.85,
					paddingRight: 2,
					paddingTop: 1,
					paddingBottom: 1,
					borderBottom: '1px solid #000',
					background: 'rgb(51, 51, 51)',
				}}
			>
				<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
					<Button size="small" color="yellow2" style={{ whiteSpace: 'nowrap', height: 44, minWidth: 10, width: 10 }}>
						<MenuIcon />
					</Button>
					{control.groups.length !== 0 && (
						<Select
							label=""
							value={selectedGroupId}
							size="small"
							fullWidth={false}
							onChange={handleGroupChange}
							startAdornment={
								<InputAdornment position="start">
									<GroupIcon
										style={{
											color: '#fff',
											marginRight: 4,
											borderRadius: 4,
											border: '1px solid rgba(255,255,255,.2)',
											background: 'rgba(255,255,255,.15)',
											padding: 2,
										}}
									/>
								</InputAdornment>
							}
						>
							<MenuItem disabled={true} value="none">
								<Trans>Select a group ...</Trans>
							</MenuItem>
							<ListSubheader sx={{ lineHeight: '2em' }}>
								<Trans>My Groups</Trans>
							</ListSubheader>
							{myGroups.length === 0 ? (
								<MenuItem value="create">
									<Trans>Create a group ...</Trans>
								</MenuItem>
							) : (
								myGroups
							)}
							<ListSubheader>
								<Trans>Joined Groups</Trans>
							</ListSubheader>
							{joinedGroups.length === 0 ? (
								<MenuItem disabled={true} value="_">
									<Trans>none</Trans>
								</MenuItem>
							) : (
								joinedGroups
							)}
						</Select>
					)}
				</Stack>
				<Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
					<Button variant="outlined" size="small" color="yellow2" style={{ whiteSpace: 'nowrap', height: 44 }}>
						<ReportProblemIcon color="warning" />
					</Button>
					<Button size="small" color="yellow2" style={{ whiteSpace: 'nowrap', height: 44, minWidth: 35, width: 35 }}>
						<Badge badgeContent={control.badges.invitations} color="secondary" overlap="circular" max={9}>
							<Avatar onAction={handleAction} invitations={control.badges.invitations} />
						</Badge>
					</Button>
					<Button size="small" color="yellow2" style={{ whiteSpace: 'nowrap', height: 44, minWidth: 35, width: 35 }}>
						<ExitToAppIcon />
					</Button>
				</Stack>
			</Stack>
			<Dialog
				open={$addGroup.open}
				onClose={handleAddGroupAbort}
				title={<Trans>Create Group</Trans>}
				buttonsLeft={
					<Button variant="outlined" color="secondary" onClick={handleAddGroupAbort}>
						<Trans>Abort</Trans>
					</Button>
				}
				buttonsRight={
					<Button variant="outlined" color="primary" disabled={$addGroup.name.length === 0} onClick={handleAddGroupDone}>
						<Trans>Create Group</Trans>
					</Button>
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField variant="outlined" fullWidth label={<Trans>Group Name</Trans>} value={$addGroup.name} onChange={handleAddGroupName} />
					</Grid>
				</Grid>
			</Dialog>
		</React.Fragment>
	);
}

Header.defaultProps = {
	expand: false,
	control: null,
};
