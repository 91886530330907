import React from 'react';
import PropTypes from 'prop-types';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import ServiceClass from '../utils/service';
import useInterval from '../hooks/useInterval';
import { NotifyProvider } from './Notify';

const Service = React.createContext();

const reducer = (state, action) => {
	const value = action.value;
	let control = state.control;

	switch (action.type) {
		case 'set_groups':
			control = { ...control, groups: value.slice() };
			break;
		case 'select_group':
			if (control.selectedGroupId !== value) {
				control = {
					...control,
					selectedGroupId: value,
				};
			}
			break;
		case 'set_group_topic':
			control = { ...control, groupTopic: value };
			break;
		case 'set_badge':
			let badges = { ...control.badges, [value.name]: value.value };
			control = { ...control, badges };
			break;
		default:
			console.error('unknown action type: ' + action.type);
			break;
	}

	return { ...state, control };
};

function ServiceProvider(props) {
	let service = null;
	if (props.service) {
		service = props.service;
	} else {
		service = new ServiceClass(props.address);
	}

	const initialState = {
		service: service,
		control: {
			groups: [],
			selectedGroupId: '',
			groupTopic: '',
			badges: {
				intivations: 0,
				alerts: 0,
				tickets: 0,
			},
		},
	};

	const [$backdrop, setBackdrop] = React.useState(false);
	const [$snack, setSnack] = React.useState({
		open: false,
		message: '',
		severity: 'info',
	});

	const [$state, setState] = React.useReducer(reducer, initialState);

	React.useEffect(() => {
		(async () => {
			if ($state.control.selectedGroupId === '') {
				return;
			}
			/*
			const tickets = await $state.service.CountTickets($state.control.selectedGroupId);
			setState({ type: 'set_badge', value: { name: 'tickets', value: tickets } });

			const alerts = await $state.service.CountAlerts($state.control.selectedGroupId);
			setState({ type: 'set_badge', value: { name: 'alerts', value: alerts } });
*/
		})();
	}, [$state.service, $state.control.selectedGroupId]);

	useInterval(async () => {
		await update();
	}, 60000);

	const update = async () => {
		const service = $state.service;

		if (!service.IsRegistered()) {
			return;
		}

		await service.UpdateUser();
		setState({ type: 'set_groups', value: service.Groups() });
		setState({ type: 'set_badge', value: { name: 'invitations', value: service.UserInvitations().length } });
		/*
		if ($state.control.selectedGroupId !== 'none') {
			const tickets = await service.CountTickets($state.control.selectedGroupId);
			setState({ type: 'set_badge', value: { name: 'tickets', value: tickets } });

			const alerts = await service.CountAlerts($state.control.selectedGroupId);
			setState({ type: 'set_badge', value: { name: 'alerts', value: alerts } });
		}
*/
	};

	const notify = (severity, message) => {
		setSnack({
			...$snack,
			open: true,
			message: message,
			severity: severity,
		});
	};

	const handleCloseSnack = () => {
		setSnack({
			...$snack,
			open: false,
		});
	};

	const value = {
		...$state,
		dispatch: (type, value) => {
			setState({ type, value });
		},
		notify,
		backdrop: (value) => {
			setBackdrop(value);
		},
	};

	return (
		<React.Fragment>
			<NotifyProvider value={{ Dispatch: notify }}>
				<Service.Provider value={value}>{props.children}</Service.Provider>
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={$snack.open}
					autoHideDuration={6000}
					onClose={handleCloseSnack}
				>
					<Alert variant="filled" elevation={6} onClose={handleCloseSnack} severity={$snack.severity}>
						<AlertTitle>{$snack.severity.toUpperCase()}</AlertTitle>
						{$snack.message}
					</Alert>
				</Snackbar>
				<Backdrop open={$backdrop}>
					<CircularProgress color="inherit" />
				</Backdrop>
			</NotifyProvider>
		</React.Fragment>
	);
}

ServiceProvider.propTypes = {
	address: PropTypes.string,
	service: PropTypes.object,
	children: PropTypes.node.isRequired,
};

ServiceProvider.defaultProps = {
	address: '',
	service: null,
	children: null,
};

function useService() {
	return React.useContext(Service);
}

export { ServiceProvider, useService };
