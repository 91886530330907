/* eslint-disable import/no-anonymous-default-export */
export default {
	styleOverrides: {
		root: {
			minHeight: 30,
			margin: '0em!important',
			paddingTop: 'unset!important',
			textAlign: 'unset!important',
			minWidth: 'unset!important',
		},
		indicator: {
			backgroundColor: 'transparent!important',
		},
	},
};
