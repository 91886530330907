/* eslint-disable import/no-anonymous-default-export */
export default {
	common: {
		black: '#000',
		white: '#fff',
	},
	background: {
		paper: 'rgba(56, 56, 56, .95)',
		default: '#333333',
		dark: 'rgba(0, 0, 0, .1)',
		dark1: 'rgba(0, 0, 0, .1)',
		darker: 'rgba(0, 0, 0, .25)',
		darker1: 'rgba(0, 0, 0, .25)',
		modal: 'rgb(51, 51, 51)',
		modalbox: 'rgb(91, 91, 91)',
		light: 'rgba(255, 255, 255, .1)',
		//lighter: 'rgba(255, 255, 255, .2)',
		//lighter1: 'rgba(255, 255, 255, .25)',
		accordion: 'rgba(77, 77, 77, .4)',
		accordion_border: 'rgba(128, 128, 128, .6)',
		box_default: 'rgb(77, 77, 77)',
		box_danger: 'rgb(193, 39, 45)',
		footer1: 'rgba(66, 61, 63, .9)',
		footer2: 'rgba(39, 36, 37, .9)',
		transparent: 'rgba(0, 0, 0, 0)',
	},
	// default color
	primary: {
		light: '#919090',
		main: '#686868',
		dark: '#4D4D4D',
		contrastText: '#fff',
	},
	// success color
	secondary: {
		main: '#FCEE21',
		contrastText: '#323232',
	},
	selected: {
		main: '#fff',
		contrastText: '#323232',
	},
	unselected: {
		main: '#919090',
		contrastText: '#323232',
	},
	yellow2: {
		main: '#C4BF10',
	},
	success: {
		main: '#39B54A',
		contrastText: '#fff',
	},
	error: {
		main: '#C1272D',
		contrastText: '#fff',
	},
	warning: {
		main: '#C1272D',
	},
	text: {
		primary: '#fff',
		secondary: 'rgba(255, 255, 255, 0.7)',
		disabled: 'rgba(255, 255, 255, 0.38)',
		hint: 'rgba(255, 255, 255, 0.7)',
	},
};
