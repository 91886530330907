import React from 'react';
import moment from 'moment';

import { makeStyles, useTheme } from '@mui/styles';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { Trans } from '@lingui/macro';

import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import Number from './Number';

const useStyles = makeStyles((theme) => ({
	current3: {
		fontSize: '4rem',
		fontWeight: 600,
		[theme.breakpoints.between('xs', 'md')]: {
			fontSize: '2.5rem',
			marginBottom: -10,
		},
	},
	current4: {
		fontSize: '2.6rem',
		fontWeight: 600,
		marginBottom: -10,
		[theme.breakpoints.between('xs', 'md')]: {
			fontSize: '2.5rem',
			marginBottom: -10,
		},
	},
	current5: {
		fontSize: '1.95rem',
		fontWeight: 600,
		marginBottom: -10,
		[theme.breakpoints.between('xs', 'md')]: {
			fontSize: '2.5rem',
			marginBottom: -10,
		},
	},
	title3: {
		fontSize: '1.65rem',
		fontWeight: 400,
		[theme.breakpoints.between('xs', 'md')]: {
			fontSize: '1.1rem',
			paddingBottom: 10,
		},
	},
	title4: {
		fontSize: '1.15rem',
		fontWeight: 400,
		textTransform: 'uppercase',
		[theme.breakpoints.between('xs', 'md')]: {
			fontSize: '1.1rem',
			paddingBottom: 10,
		},
	},
	title5: {
		fontSize: '1rem',
		fontWeight: 400,
		textTransform: 'uppercase',
		[theme.breakpoints.between('xs', 'md')]: {
			fontSize: '1.1rem',
			paddingBottom: 10,
		},
	},
	subTitle: {
		fontSize: '.85rem',
		marginTop: 0,
		textTransform: 'uppercase',
		color: 'rgba(255,255,255,.95)',
		[theme.breakpoints.between('xs', 'md')]: {
			fontSize: '.85rem',
		},
	},
	tooltip: {
		backgroundColor: '#333333',
		borderRadius: 4,
		border: '1px solid rgba(255,255,255,.35)',
		padding: '5px 10px 5px 10px',
		left: 500,
	},
	tooltipTitle: {
		fontSize: '.85rem',
		marginTop: 0,
		color: 'rgba(255,255,255,.95)',
	},
	tooltipTitle2: {
		fontSize: '.85rem',
		marginTop: 0,
		color: 'rgba(255,255,255,.55)',
	},
}));

export default function PageChart(props) {
	const classes = useStyles();
	const theme = useTheme();
	const mediaDownMd = useMediaQuery(theme.breakpoints.down('md'));

	let format = {
		factor: 1,
		digits: props.current.digits,
		unit: props.current.unit,
	};

	if (props.formatter !== null) {
		format = {
			...format,
			...props.formatter,
		};
	}

	if (props.data === null) {
		return (
			<Stack direction="column" justifyContent="center" alignItems="center" spacing={5} style={{ margin: 50 }}>
				<CircularProgress color="inherit" />
			</Stack>
		);
	}

	let max = props.data.reduce((acc, v) => (v[1] === null ? acc : v[1] > acc ? v[1] : acc), props.min);
	const min = props.data.reduce((acc, v) => (v[1] === null ? acc : v[1] < acc ? v[1] : acc), props.max);

	// fixes min === max y-axis
	if (max === min) {
		max++;
	}

	const CustomTooltip = ({ active, payload }) => {
		if (!active || !payload || !payload.length) {
			return null;
		}

		return (
			<div className={classes.tooltip}>
				<Typography variant="body2" className={classes.tooltipTitle}>
					<strong>
						<Trans>Timestamp</Trans>:{' '}
					</strong>
					{moment.unix(payload[0].payload[0]).format('YYYY-MM-DD HH:mm:ss')}
				</Typography>
				<Typography variant="body2" className={classes.tooltipTitle}>
					<strong>
						<Trans>Value</Trans>:{' '}
					</strong>
					<Number value={payload[0].payload[1] * format.factor} digits={format.digits} unit={' ' + format.unit} />
				</Typography>
				<Divider style={{ marginLeft: -10, width: 'calc(100% + 20px)' }} />
				<Typography variant="body2" className={classes.tooltipTitle2}>
					<strong>
						<Trans>Max. in range</Trans>:{' '}
					</strong>
					<Number value={max * format.factor} digits={format.digits} unit={' ' + format.unit} />
				</Typography>
				<Typography variant="body2" className={classes.tooltipTitle2}>
					<strong>
						<Trans>Min. in range</Trans>:{' '}
					</strong>
					<Number value={min * format.factor} digits={format.digits} unit={' ' + format.unit} />
				</Typography>
			</div>
		);
	};

	var aspect = 0;
	if (props.size <= 3) {
		if (mediaDownMd) {
			aspect = 7;
		} else {
			aspect = 5;
		}
	} else if (props.size === 4) {
		if (mediaDownMd) {
			aspect = 8;
		} else {
			aspect = 5;
		}
	} else {
		if (mediaDownMd) {
			aspect = 10;
		} else {
			aspect = 4;
		}
	}

	return (
		<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={1} style={{ marginLeft: -20, marginRight: -20, marginBottom: 20 }}>
			{props.current && (
				<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={1} style={{ marginLeft: 20 }}>
					<Typography variant="stattitel1" className={props.size <= 3 ? classes.current3 : props.size === 4 ? classes.current4 : classes.current5}>
						<Number value={props.current.value} minDigits={props.current.digits} />
					</Typography>
					<Typography variant="h3" className={props.size <= 3 ? classes.title3 : props.size === 4 ? classes.title4 : classes.title5}>
						{props.title} {props.current.unit}
					</Typography>
				</Stack>
			)}
			<div style={{ width: 'calc(100% - 20px)' }}>
				<ResponsiveContainer width="100%" height="100%" minWidth={0} aspect={aspect}>
					<LineChart
						height={10}
						width={100}
						margin={{ top: 10, right: 0, left: 20, bottom: 10 }}
						data={props.data}
						//syncId="anyId"
					>
						<XAxis dataKey={0} hide />
						<YAxis datakey={1} hide domain={[min, max]} />
						<ReferenceLine y={max} stroke="rgba(255,255,255,.35)" strokeWidth={2} strokeDasharray="3 3" />
						<Line
							type="monotone"
							dataKey={1}
							interval="preserveStartEnd"
							stroke={props.color}
							strokeWidth={2}
							dot={false}
							isAnimationActive={false}
						/>
						<Tooltip content={<CustomTooltip />} wrapperStyle={{ top: '95%', left: 0 }} />
					</LineChart>
				</ResponsiveContainer>
			</div>
			{(props.cores || props.arch || props.usage || props.free || props.limit) && (
				<Stack direction="column" justifyContent="center" alignItems="stretch" spacing={0} style={{ marginLeft: 20, paddingTop: 4 }}>
					{props.cores && (
						<Typography variant="body2" className={classes.subTitle}>
							<strong>
								<Trans>CORES</Trans>:
							</strong>{' '}
							<Number value={props.cores.value} minDigits={props.cores.digits} unit={' ' + props.cores.unit} />
						</Typography>
					)}
					{props.arch && (
						<Typography variant="body2" className={classes.subTitle}>
							<strong>
								<Trans>ARCH</Trans>:
							</strong>{' '}
							{props.arch.value}
						</Typography>
					)}
					{props.usage && (
						<Typography variant="body2" className={classes.subTitle}>
							<strong>
								<Trans>Usage</Trans>:
							</strong>{' '}
							<Number value={props.usage.value} minDigits={props.usage.digits} unit={' ' + props.usage.unit} />
						</Typography>
					)}
					{props.free && (
						<Typography variant="body2" className={classes.subTitle}>
							<strong>
								<Trans>Free</Trans>:
							</strong>{' '}
							<Number value={props.free.value} minDigits={props.free.digits} unit={' ' + props.free.unit} />
						</Typography>
					)}
					{props.limit && (
						<Typography variant="body2" className={classes.subTitle}>
							<strong>
								<Trans>Limit</Trans>:
							</strong>{' '}
							{props.limit.value !== 0 ? (
								<Number value={props.limit.value} minDigits={props.limit.digits} unit={' ' + props.limit.unit} />
							) : (
								<Trans>unlimited</Trans>
							)}
						</Typography>
					)}
				</Stack>
			)}
		</Stack>
	);
}

PageChart.defaultProps = {
	size: 3,
	title: null,
	data: null,
	min: 0,
	max: 100,
	formatter: null,
	color: '#39B54A',
	current: null,
	cores: null,
	arch: null,
	usage: null,
	free: null,
	limit: null,
};
