import React from 'react';

import company_logo from './images/logo-sign.svg';

export default function Logo(props) {
	let link = 'https://datarhei.com';

	// eslint-disable-next-line no-useless-escape
	return (
		<a href={link} className="Footer-logo" target="_blank" rel="noopener noreferrer">
			<img src={company_logo} alt="datarhei logo" />
		</a>
	);
}
