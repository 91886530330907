import Cores from './Cores';
import Core from './Core';
import CoreProcess from './Core/Process';
import Settings from './Settings';
import Tokens from './Tokens';
import Tickets from './Tickets';
import Ticket from './Ticket';
import Alerts from './Alerts';
import Logs from './Logs';

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
	Cores,
	Core,
	CoreProcess,
	Settings,
	Tokens,
	Tickets,
	Ticket,
	Alerts,
	Logs,
};
