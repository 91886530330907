/* eslint-disable import/no-anonymous-default-export */
import base from '../base';

export default {
	styleOverrides: {
		root: {
			color: base.palette.text.primary,
			backgroundColor: base.palette.background.dark,
			border: `2px solid ${base.palette.primary.main}`,
			'&:hover': {
				color: base.palette.primary.contrastText,
				backgroundColor: base.palette.background.dark,
				border: `2px solid ${base.palette.primary.light}`,
			},
			'&:disabled': {
				color: base.palette.text.disabled,
				border: `2px solid ${base.palette.primary.light}`,
			},
		},
		icon: {
			color: base.palette.text.secondary,
			'&.Mui-disabled': {
				color: base.palette.text.secondary,
			},
		},
	},
};
