import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { useLingui } from '@lingui/react';
import { Trans, t } from '@lingui/macro';

import Table from '../misc/Table';
import Dialog from '../misc/modals/Dialog';
import Page from '../misc/Page';
import { useService } from '../contexts/Service';

export default function Invitations(props) {
	const navigate = useNavigate();
	const { service, dispatch, backdrop } = useService();
	const { i18n } = useLingui();
	const [$ready, setReady] = React.useState(false);
	const [$errorDialog, setErrorDialog] = React.useState({
		open: false,
		message: '',
	});
	const [$acceptedDialog, setAcceptedDialog] = React.useState({
		open: false,
		name: '',
		id: '',
	});
	const [$invitations, setInvitations] = React.useState([]);

	const tableCells = [
		{
			id: 'name',
			align: 'left',
			disablePadding: false,
			sortable: true,
			label: i18n._(t`Gruppe`),
			field: 'name',
		},
		{
			id: 'actions',
			align: 'right',
			disablePadding: false,
			sortable: false,
			label: '',
			field: 'actions',
		},
	];

	React.useEffect(() => {
		(async () => {
			await handleMount();
		})();

		return () => {
			handleUnmount();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleMount = async () => {
		await handleRefresh();

		setReady(true);
	};

	const handleUnmount = async () => {};

	const handleHelp = () => {};

	const handleRefresh = async () => {
		backdrop(true);

		const invitations = await service.UpdateUserInvitations();
		setInvitations(invitations);

		dispatch('set_badge', { name: 'invitations', value: invitations.length });

		backdrop(false);
	};

	const handleDecline = (id) => async () => {
		backdrop(true);

		const res = await service.InvitationRevoke(id);

		const invitations = await service.UpdateUserInvitations();
		setInvitations(invitations);

		dispatch('set_badge', { name: 'invitations', value: invitations.length });

		setErrorDialog({
			...$errorDialog,
			open: true,
			message: service.StatusString(res),
		});

		backdrop(false);
	};

	const handleAccept = (id) => async () => {
		backdrop(true);

		const res = await service.InvitationVerify(id);

		if (res.status === 'INVITE_ACCEPTED') {
			await service.UpdateUser();

			const invitations = service.UserInvitations();
			setInvitations(invitations);

			dispatch('set_groups', service.Groups());
			dispatch('set_badge', { name: 'invitations', value: invitations.length });

			setAcceptedDialog({
				...$acceptedDialog,
				open: true,
				id: res.detail.group_id,
				name: res.detail.group_name,
			});
		} else {
			setErrorDialog({
				...$errorDialog,
				open: true,
				message: service.StatusString(res.status),
			});
		}

		backdrop(false);
	};

	const handleCloseErrorDialog = () => {
		setErrorDialog({
			...$errorDialog,
			open: false,
		});
	};

	const handleCloseAcceptedDialog = () => {
		setAcceptedDialog({
			...$acceptedDialog,
			open: false,
		});
	};

	if ($ready === false) {
		return null;
	}

	const tableRows = $invitations.map((invitation) => {
		return {
			_key: invitation.id,
			name: invitation.name,
			actions: (
				<Stack direction="row" spacing={1} justifyContent="flex-end">
					<Button variant="outlined" color="secondary" onClick={handleDecline(invitation.id)}>
						<ThumbDownIcon fontSize="small" />
						<Trans>Decline</Trans>
					</Button>
					<Button variant="outlined" color="primary" onClick={handleAccept(invitation.id)}>
						<ThumbUpIcon fontSize="small" />
						<Trans>Accept</Trans>
					</Button>
				</Stack>
			),
		};
	});

	return (
		<React.Fragment>
			<Page supTitle={<ReadMoreIcon />} title={<Trans>Invitations</Trans>} onHelp={handleHelp} onRefresh={handleRefresh}>
				{$invitations.length !== 0 ? (
					<Table order="asc" orderBy="name" cells={tableCells} rows={tableRows} rowsPerPage={5} rowsPerPageOptions={[5, 10, 25]} />
				) : (
					<Trans>You don't have any invitations. Nobody likes you!</Trans>
				)}
			</Page>
			<Dialog
				open={$errorDialog.open}
				onClose={handleCloseErrorDialog}
				title={<Trans>Invitation</Trans>}
				buttonsRight={
					<Button variant="outlined" color="secondary" onClick={handleCloseErrorDialog}>
						<Trans>Close</Trans>
					</Button>
				}
			>
				<Typography>{$errorDialog.message}</Typography>
			</Dialog>
			<Dialog
				open={$acceptedDialog.open}
				onClose={handleCloseAcceptedDialog}
				title={<Trans>Invitation accepted</Trans>}
				buttonsRight={
					<Button variant="outlined" color="secondary" onClick={handleCloseAcceptedDialog}>
						<Trans>Close</Trans>
					</Button>
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography>
							<Trans>You accepted the invitation to the group "{$acceptedDialog.name}".</Trans>
						</Typography>
					</Grid>
					<Grid item xs={12} align="center">
						<Button variant="outlined" color="primary" onClick={() => navigate('/group/' + $acceptedDialog.id)}>
							<Trans>Change to group "{$acceptedDialog.name}"</Trans>
						</Button>
					</Grid>
				</Grid>
			</Dialog>
		</React.Fragment>
	);
}

Invitations.defaultProps = {
	service: null,
};
