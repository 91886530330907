import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import ServiceUI from './ServiceUI';
import theme from './theme';

import I18nProvider from './I18n';
import { ServiceProvider } from './contexts/Service';

import '@fontsource/roboto';

const address = 'https://datarhei.ddnss.ch';

createRoot(document.getElementById('root')).render(
	<BrowserRouter>
		<ServiceProvider address={address}>
			<I18nProvider>
				<StyledEngineProvider>
					<ThemeProvider theme={theme}>
						<CssBaseline />
						<ServiceUI />
					</ThemeProvider>
				</StyledEngineProvider>
			</I18nProvider>
		</ServiceProvider>
	</BrowserRouter>
);
