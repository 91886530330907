import React from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Chip from '@mui/material/Chip';

export default function Component(props) {

	let color = '';
	if (props.level === 'HIGH') {
		color = '#D84848';
	} else {
		color = '#fff';
	}

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="flex-start"
			spacing={2}
			style={{
				marginBottom: 5,
				backgroundColor: 'rgb(51, 51, 51)',
				borderRadius: 5,
				border: '2px solid rgba(255,255,255,.25)',
			}}
		>
			<div style={{ width: 10 }}>
				<SvgIcon style={{ height: 55, width: 15, marginTop: -8 , marginLeft: -1}}>
					<path
						fill={color}
						d="M1.495 55.155C.672 57.195 0 57.065 0 54.865V2.051C0-.149 1.257-.661 2.794.913l11.752 12.041c1.537 1.574 2.121 4.532 1.3 6.573L1.495 55.155z"
					/>
				</SvgIcon>
			</div>
			<Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={0} style={{ width: '100%', padding: '10px 25px 12px 0px' }}>
				<Typography variant="body" style={{ fontWeight: 900 }}>
					{props.title}
				</Typography>
				<Typography variant="body" style={{ fontWeight: 400 }}>
					{props.core_name}
				</Typography>
				<Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1} style={{ marginTop: 10, marginBottom: 13 }}>
					<Chip
						label={props.level}
						style={{ padding: '0px 0px 1px 0px', width: '100%', fontWeight: 800, borderRadius: 5, backgroundColor: color, height: 25, color: '#333333' }}
					/>
					<Chip
						label={props.what}
						style={{ padding: '0px 0px 1px 0px', width: '100%', fontWeight: 800, borderRadius: 5, backgroundColor: 'rgba(255,255,255,.1', height: 25, color: '#fff' }}
					/>
					<Chip
						label={props.date}
						style={{
							padding: '0px 0px 1px 0px',
							width: '100%',
							fontWeight: 900,
							borderRadius: 5,
							backgroundColor: 'rgba(255,255,255,.1)',
							height: 25,
							color: '#fff',
						}}
					/>
				</Stack>
				{props.message.length !== 0 && <Typography variant="body2">{props.message}</Typography>}
			</Stack>
		</Stack>
	);
}

Component.defaultProps = {
	variant: 'danger',
	title: '',
	core_name: '',
	what: '',
	message: '',
	date: '',
	level: '',
};
