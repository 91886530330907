import React from 'react';

import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function Component(props) {
	return (
		<Button size="small" color={props.color} fullWidth onClick={props.onClick} style={props.style}>
			<Grid container direction="row" alignItems="center">
				<Grid item xs={12}>
					{props.badges > 0 && (
						<Badge badgeContent={props.badges} color="secondary" overlap="circular" max={9} style={{ width: '2rem', paddingLeft: 6 }}>
							{props.icon}
						</Badge>
					)}
					{props.badges === 0 && <React.Fragment>{props.icon}</React.Fragment>}
				</Grid>
				<Grid item xs={12} style={{ marginTop: props.iconDistance }}>
					<Typography variant="caption" display="block" style={{ fontSize: '0.5rem' }}>
						{props.titel}
					</Typography>
				</Grid>
			</Grid>
		</Button>
	);
}

Component.defaultProps = {
	icon: '',
	titel: '',
	color: 'unselected',
	badge: false,
	badges: 0,
	style: {},
	iconDistance: -12,
	disabled: false,
	onClick: function (event) {},
};
