import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';

export default function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort, cells } = props;

	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead style={{height: 60, backgroundColor: '#333333'}}>
			<TableRow style={{height: 60}}>
				<TableCell
					key="firstCell"
					align="left"
					padding="normal"
					style={{
						borderBottom: '1px solid rgba(0,0,0,.35)',
						width: 40,
						padding: 0,
					}}
				></TableCell>
				{props.collapse && (
					<TableCell
						key="collabse"
						align="left"
						padding="normal"
						style={{
							borderBottom: '1px solid rgba(0,0,0,.35)',
							width: 40,
							padding: 0,
						}}
					></TableCell>
				)}
				{cells.map((cell) => (
					<TableCell
						key={cell.id}
						align={cell.align}
						padding={cell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === cell.id ? order : false}
						style={{
							borderBottom: '1px solid rgba(0,0,0,.35)',
							width: cell.disablePadding ? 'unset' : '40px',
							padding: cell.disablePadding ? 'unset' : '0px',
						}}
					>
						{cell.sortable ? (
							<TableSortLabel
								active={orderBy === cell.id}
								direction={orderBy === cell.id ? order : 'asc'}
								onClick={createSortHandler(cell.id)}
							>
								{cell.label}
								{orderBy === cell.id ? (
									<Box component="span" sx={visuallyHidden}>
										{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
									</Box>
								) : null}
							</TableSortLabel>
						) : (
							<React.Fragment>{cell.label}</React.Fragment>
						)}
					</TableCell>
				))}
				<TableCell
					key="lastCell"
					align="left"
					padding="normal"
					style={{
						borderBottom: '1px solid rgba(0,0,0,.35)',
						width: 40,
						padding: 0,
					}}
				></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	cells: PropTypes.array.isRequired,
	collapse: PropTypes.bool.isRequired,
};
