import moment from 'moment';

class NullDataProvider {
	constructor() {
		this.start = moment().utc();
	}

	Type() {
		return 'null';
	}

	async CoreRange(ids, timerange, interval) {
		const now = moment().utc();
		const then = now.clone().subtract(timerange, 'seconds');

		const data = {};

		for (const id of ids) {
			let core = {
				id: id,
				version: 'dummy 1.0 (javascript)',
				uptime: this.start.diff(moment().utc(), 'second'),
				last_update: moment().utc().unix(),
				metrics: {},
				processes: [],
			};

			core.metrics = this.createDummyData();

			for (let topic in core.metrics) {
				for (let d in core.metrics[topic]) {
					core.metrics[topic][d] = resample(core.metrics[topic][d], then, now, Math.floor(timerange / interval), null);
				}
			}

			data[id] = core;
		}

		return data;
	}

	async CoreCurrent(ids) {
		const data = {};

		for (const id of ids) {
			let core = {
				id: id,
				version: 'dummy 1.0 (javascript)',
				uptime: this.start.diff(moment().utc(), 'second'),
				last_update: moment().utc().unix(),
				metrics: {},
				processes: [],
			};

			core.metrics = this.createDummyData();

			for (let topic in core.metrics) {
				for (let d in core.metrics[topic]) {
					core.metrics[topic][d] = addSample([], core.metrics[topic][d], 1);
				}
			}

			data[id] = core;
		}
	}

	createDummyData(metrics) {
		const data = {
			system: {},
			core: {},
			process: {},
		};

		data.system.cpu_ncores = 'sys_cpu_ncores' in metrics ? metrics.sys_cpu_ncores : [];
		data.system.cpu_used = 'sys_cpu_used' in metrics ? metrics.sys_cpu_used : [];
		data.system.mem_used_bytes = 'sys_mem_used_bytes' in metrics ? metrics.sys_mem_used_bytes : [];
		data.system.mem_total_bytes = 'sys_mem_total_bytes' in metrics ? metrics.sys_mem_total_bytes : [];
		data.system.disk_used_bytes = 'sys_disk_used_bytes' in metrics ? metrics.sys_disk_used_bytes : [];
		data.system.disk_total_bytes = 'sys_disk_total_bytes' in metrics ? metrics.sys_disk_total_bytes : [];
		data.system.viewer_used = 'sys_viewer_used' in metrics ? metrics.sys_viewer_used : [];
		data.system.viewer_limit = 'sys_viewer_limit' in metrics ? metrics.sys_viewer_limit : [];

		data.core.uptime_seconds = 'uptime_seconds' in metrics ? metrics.uptime_seconds : [];
		data.core.mem_used_bytes = 'fs_mem_used_bytes' in metrics ? metrics.fs_mem_used_bytes : [];
		data.core.mem_limit_bytes = 'fs_mem_limit_bytes' in metrics ? metrics.fs_mem_limit_bytes : [];
		data.core.disk_used_bytes = 'fs_disk_used_bytes' in metrics ? metrics.fs_disk_used_bytes : [];
		data.core.disk_limit_bytes = 'fs_disk_limit_bytes' in metrics ? metrics.fs_disk_limit_bytes : [];
		data.core.net_tx_used_kbit = 'net_tx_used_kbit' in metrics ? metrics.net_tx_used_kbit : [];
		data.core.net_tx_limit_kbit = 'net_tx_limit_kbit' in metrics ? metrics.net_tx_limit_kbit : [];
		data.core.net_rx_used_kbit = 'net_rx_used_kbit' in metrics ? metrics.net_rx_used_kbit : [];
		data.core.net_rx_limit_kbit = 'net_rx_limit_kbit' in metrics ? metrics.net_rx_limit_kbit : [];

		data.process.starting = 'processes_starting' in metrics ? metrics.processes_starting : [];
		data.process.running = 'processes_running' in metrics ? metrics.processes_running : [];
		data.process.finishing = 'processes_finishing' in metrics ? metrics.processes_finishing : [];
		data.process.finished = 'processes_finished' in metrics ? metrics.processes_finished : [];
		data.process.killed = 'processes_killed' in metrics ? metrics.processes_killed : [];
		data.process.failed = 'processes_failed' in metrics ? metrics.processes_failed : [];

		return data;
	}
}

class ServiceDataProvider {
	constructor(dataFn) {
		this.dataFn = dataFn;
	}

	Type() {
		return 'service';
	}

	async CoreRange(ids, timerange, interval) {
		const now = moment().utc();
		const then = now.clone().subtract(timerange, 'seconds');

		const monitor = await this.dataFn(ids, then, now, interval);

		const data = {};

		for (const id of ids) {
			let core = {
				id: id,
				version: 'unknown 3.0',
				uptime: -1,
				last_update: -1,
				metrics: {},
				processes: [],
			};

			let metrics = {};

			for (let c of monitor) {
				if (c.core_id !== id) {
					continue;
				}

				metrics = c.metrics;
				break;
			}

			core.uptime = 'uptime_seconds' in metrics ? current(metrics.uptime_seconds) : -1;
			core.last_update = 'version' in metrics ? current(metrics.version) : -1;

			core.metrics = this.convertCoreData(metrics);

			for (let topic in core.metrics) {
				for (let d in core.metrics[topic]) {
					core.metrics[topic][d] = resample(core.metrics[topic][d], then, now, Math.floor(timerange / interval), null);
				}
			}

			data[id] = core;
		}

		return data;
	}

	async CoreCurrent(ids) {
		const monitor = await this.dataFn(ids);

		const data = {};

		for (const id of ids) {
			let core = {
				id: id,
				version: 'unknown 3.0',
				uptime: -1,
				last_update: -1,
				metrics: {},
				processes: [],
			};

			let metrics = {};

			for (let c of monitor) {
				if (c.core_id !== id) {
					continue;
				}

				metrics = c.metrics;
				break;
			}

			core.uptime = 'uptime_seconds' in metrics ? current(metrics.uptime_seconds) : -1;
			core.last_update = 'version' in metrics ? current(metrics.version) : -1;

			core.metrics = this.convertCoreData(metrics);

			for (let topic in core.metrics) {
				for (let d in core.metrics[topic]) {
					core.metrics[topic][d] = addSample([], core.metrics[topic][d], 1);
				}
			}

			data[id] = core;
		}

		return data;
	}

	convertCoreData(metrics) {
		const data = {
			system: {},
			core: {},
			process: {},
		};

		data.system.cpu_ncores = 'sys_cpu_ncores' in metrics ? metrics.sys_cpu_ncores : [];
		data.system.cpu_used = 'sys_cpu_used' in metrics ? metrics.sys_cpu_used : [];
		data.system.mem_used_bytes = 'sys_mem_used_bytes' in metrics ? metrics.sys_mem_used_bytes : [];
		data.system.mem_total_bytes = 'sys_mem_total_bytes' in metrics ? metrics.sys_mem_total_bytes : [];
		data.system.disk_used_bytes = 'sys_disk_used_bytes' in metrics ? metrics.sys_disk_used_bytes : [];
		data.system.disk_total_bytes = 'sys_disk_total_bytes' in metrics ? metrics.sys_disk_total_bytes : [];
		data.system.viewer_used = 'sys_viewer_used' in metrics ? metrics.sys_viewer_used : [];
		data.system.viewer_limit = 'sys_viewer_limit' in metrics ? metrics.sys_viewer_limit : [];

		data.core.uptime_seconds = 'uptime_seconds' in metrics ? metrics.uptime_seconds : [];
		data.core.mem_used_bytes = 'fs_mem_used_bytes' in metrics ? metrics.fs_mem_used_bytes : [];
		data.core.mem_limit_bytes = 'fs_mem_limit_bytes' in metrics ? metrics.fs_mem_limit_bytes : [];
		data.core.disk_used_bytes = 'fs_disk_used_bytes' in metrics ? metrics.fs_disk_used_bytes : [];
		data.core.disk_limit_bytes = 'fs_disk_limit_bytes' in metrics ? metrics.fs_disk_limit_bytes : [];
		data.core.net_tx_used_kbit = 'net_tx_used_kbit' in metrics ? metrics.net_tx_used_kbit : [];
		data.core.net_tx_limit_kbit = 'net_tx_limit_kbit' in metrics ? metrics.net_tx_limit_kbit : [];
		data.core.net_rx_used_kbit = 'net_rx_used_kbit' in metrics ? metrics.net_rx_used_kbit : [];
		data.core.net_rx_limit_kbit = 'net_rx_limit_kbit' in metrics ? metrics.net_rx_limit_kbit : [];

		data.process.starting = 'processes_starting' in metrics ? metrics.processes_starting : [];
		data.process.running = 'processes_running' in metrics ? metrics.processes_running : [];
		data.process.finishing = 'processes_finishing' in metrics ? metrics.processes_finishing : [];
		data.process.finished = 'processes_finished' in metrics ? metrics.processes_finished : [];
		data.process.killed = 'processes_killed' in metrics ? metrics.processes_killed : [];
		data.process.failed = 'processes_failed' in metrics ? metrics.processes_failed : [];

		return data;
	}
}

class CoreDataProvider {
	constructor(dataFn) {
		this.dataFn = dataFn;
	}

	Type() {
		return 'core';
	}

	async CoreRange(ids, timerange, interval) {
		const id = ids[0];
		const now = moment().utc();
		//const then = now.clone().subtract(timerange, 'seconds');

		const monitor = await this.dataFn(timerange + interval, interval);

		const data = {};

		let core = {
			id: id,
			version: monitor.about.app + ' ' + monitor.about.version.number + ' (' + monitor.about.version.arch + ')',
			uptime: monitor.about.uptime_seconds,
			last_update: now,
			metrics: {},
			processes: monitor.processes,
		};

		core.metrics = this.convertCoreData(monitor.resources);
		/*
		for (let topic in core.metrics) {
			for (let d in core.metrics[topic]) {
				core.metrics[topic][d] = resample(core.metrics[topic][d], then, now, Math.floor(timerange / interval), null);
			}
		}
		*/
		data[id] = core;

		return data;
	}

	async CoreCurrent(ids) {
		const id = ids[0];
		const now = moment().utc();

		const monitor = await this.dataFn();

		const data = {};

		let core = {
			id: id,
			version: monitor.about.app + ' ' + monitor.about.version.number + ' (' + monitor.about.version.arch + ')',
			uptime: monitor.about.uptime_seconds,
			last_update: now,
			metrics: {},
			processes: monitor.processes,
		};

		core.metrics = this.convertCoreData(monitor.resources);
		/*
		for (let topic in core.metrics) {
			for (let d in core.metrics[topic]) {
				core.metrics[topic][d] = addSample([], core.metrics[topic][d], 1);
			}
		}
		*/

		data[id] = core;

		return data;
	}

	convertCoreData(metrics) {
		const data = {
			system: {},
			core: {},
			process: {},
		};

		data.system.cpu_ncores = metrics.system.cpu_ncores;
		data.system.cpu_used = metrics.system.cpu_used;
		data.system.mem_used_bytes = metrics.system.mem_used_bytes;
		data.system.mem_total_bytes = metrics.system.mem_total_bytes;
		data.system.disk_used_bytes = metrics.system.disk_used_bytes;
		data.system.disk_total_bytes = metrics.system.disk_total_bytes;
		data.system.viewer_used = metrics.core.session_used;
		data.system.viewer_limit = metrics.core.session_limit;

		data.core.uptime_seconds = metrics.core.uptime_seconds;
		data.core.mem_used_bytes = metrics.core.memfs_used_bytes;
		data.core.mem_limit_bytes = metrics.core.memfs_limit_bytes;
		data.core.disk_used_bytes = metrics.core.diskfs_used_bytes;
		data.core.disk_limit_bytes = metrics.core.diskfs_limit_bytes;
		data.core.net_tx_used_kbit = metrics.core.nettx_used_kbit;
		data.core.net_tx_limit_kbit = metrics.core.nettx_limit_kbit;
		data.core.net_rx_used_kbit = metrics.core.netrx_used_kbit;
		data.core.net_rx_limit_kbit = metrics.core.netrx_limit_kbit;

		data.process.starting = metrics.process.starting;
		data.process.running = metrics.process.running;
		data.process.finishing = metrics.process.finishing;
		data.process.finished = metrics.process.finished;
		data.process.killed = metrics.process.killed;
		data.process.failed = metrics.process.failed;

		return data;
	}
}

/**
 * Helper function for handling lists of metrics
 *
 * A metric is an array of arrays of size 2, where the first entry is
 * a unix timestamp and the second entry is the value.
 *
 */

const resample = (data, from, to, n, zeroval) => {
	let start = from;
	let end = to;
	let endValue = zeroval;

	if (data.length !== 0) {
		start = moment.unix(data[0][0]);
		end = moment.unix(data[data.length - 1][0]);
		endValue = data[data.length - 1][1];
	}

	const range = to.diff(from, 'milliseconds');
	const stepsize = range / n;

	const values = [];

	if (data.length === 0) {
		for (let i = 0; i < n; i++) {
			const now = from.clone().add(i * stepsize, 'milliseconds');
			values.push([now.unix(), zeroval]);
		}

		return values;
	}

	let lastJ = 0;
	for (let i = 0; i < n; i++) {
		const now = from.clone().add(i * stepsize, 'milliseconds');

		if (now.isBefore(start)) {
			values.push([now.unix(), zeroval]);
			continue;
		}

		if (now.isAfter(end)) {
			values.push([now.unix(), endValue]);
			continue;
		}

		for (let j = lastJ; j < data.length - 1; j++) {
			const x = moment.unix(data[j][0]);
			const y = moment.unix(data[j + 1][0]);

			if (now.isSameOrAfter(x) && now.isBefore(y)) {
				values.push([now.unix(), data[j][1]]);
				lastJ = j;
				break;
			}
		}
	}

	return values;
};

const addSample = (arr, value, npoints) => {
	arr = arr.map((v) => [...v]);

	if (!Array.isArray(value)) {
		value = [value];
	}

	arr.push(...value);

	if (arr.length < npoints) {
		const length = npoints - arr.length;
		for (let i = 0; i < length; i++) {
			arr.unshift([0, null]);
		}
	}

	arr.splice(0, arr.length - npoints);

	return arr;
};

const getAllMetrics = (metrics, metric, labels) => {
	const mts = [];

	loop: for (const m of metrics) {
		if (m.name !== metric) {
			continue;
		}

		if (labels !== null) {
			for (const key in labels) {
				const value = labels[key];

				if (!(key in m.labels)) {
					continue loop;
				}

				if (m.labels[key] !== value) {
					continue loop;
				}
			}
		}

		mts.push({
			name: m.name,
			labels: m.labels,
			values: m.values.map((v) => [...v]),
		});
	}

	return mts;
};

const getMetrics = (metrics, metric, labels) => {
	const mts = getAllMetrics(metrics, metric, labels);

	if (mts.length === 0) {
		return null;
	}

	return mts[0];
};

const getLabelValues = (metrics, metric, labels, label) => {
	const values = [];
	const mts = getAllMetrics(metrics, metric, labels);

	if (mts.length === 0) {
		return values;
	}

	for (let m of mts) {
		if (!(label in m.labels)) {
			continue;
		}

		values.push(m.labels[label]);
	}

	return values;
};

const getValues = (metrics, metric, labels) => {
	const m = getMetrics(metrics, metric, labels);
	if (m === null) {
		return [];
	}

	return m.values;
};

/**
 * diffValues converts a metric series to a series of changes
 *
 * @param {array} data List of metrics
 * @returns array
 */
const diffValues = (data) => {
	const arr = [];

	if (data.length === 0) {
		return [];
	}

	let last = data[0][1];

	for (let d of data) {
		if (d[1] === null) {
			arr.push([d[0], null]);
			continue;
		}

		if (last === null) {
			last = d[1] + 1;
		}

		let diff = d[1] - last;

		if (diff >= 0) {
			arr.push([d[0], d[1] - last]);
		} else {
			arr.push([d[0], d[1]]);
		}

		last = d[1];
	}

	return arr;
};

const add = (arr1, arr2) => {
	const arr = [];

	if (arr1.length === 0) {
		for (let i = 0; i < arr2.length; i++) {
			arr.push(arr2[i]);
		}

		return arr;
	}

	for (let i = 0; i < arr1.length; i++) {
		// tmp. fix
		// https://github.com/datarhei/restreamer-serviceui-basic/issues/26
		//if (arr2[i]) {
		arr.push([arr1[i][0], arr1[i][1] + arr2[i][1]]);
		//}
	}

	return arr;
};

const sum = (data) => {
	return data.reduce((acc, v) => acc + v[1], 0);
};

const max = (data) => {
	let min = 0;
	if (data.length > 0) {
		min = data[0][1];
	}

	const max = data.reduce((acc, v) => (v[1] === null ? acc : v[1] > acc ? v[1] : acc), min);

	return max;
};

const min = (data) => {
	let max = 0;
	if (data.length > 0) {
		max = data[0][1];
	}

	const min = data.reduce((acc, v) => (v[1] === null ? acc : v[1] < acc ? v[1] : acc), max);

	return min;
};

const usage = (total, used) => {
	return total.map((v, i) => [v[0], (used[i][1] / v[1]) * 100]);
};

const current = (data) => {
	return data.length ? data[data.length - 1][1] : 0;
};

const round = (data, decimals) => {
	const factor = Math.pow(10, decimals);
	return data.map((v) => [v[0], Math.round((v[1] + Number.EPSILON) * factor) / factor]);
};

export {
	NullDataProvider,
	ServiceDataProvider,
	CoreDataProvider,
	resample,
	addSample,
	getAllMetrics,
	getMetrics,
	getValues,
	getLabelValues,
	diffValues,
	add,
	sum,
	min,
	max,
	usage,
	current,
	round,
};
