/* eslint-disable import/no-anonymous-default-export */
import base from '../base';

const root = {
	textAlign: 'center',
	textTransform: 'uppercase',
	userSelect: 'none',
};

const outlined = {
	base: {
		color: base.palette.text.primary,
		backgroundColor: base.palette.background.dark,
		border: `2px solid ${base.palette.primary.main}`,
		'&:hover': {
			color: base.palette.primary.contrastText,
			backgroundColor: base.palette.background.dark,
			border: `2px solid ${base.palette.primary.light}`,
		},
		'&:disabled': {
			color: base.palette.text.disabled,
			border: `2px solid ${base.palette.primary.light}`,
		},
	},
	primary: {
		color: base.palette.text.primary,
		backgroundColor: base.palette.background.dark,
		border: `2px solid ${base.palette.secondary.main}`,
		'&:hover': {
			color: base.palette.secondary.contrastText,
			backgroundColor: base.palette.secondary.main,
			border: `2px solid ${base.palette.secondary.main}`,
		},
		'&:disabled': {
			backgroundColor: base.palette.background.default,
			border: `2px solid ${base.palette.text.disabled}`,
			color: base.palette.text.disabled,
		},
	},
	// color secondary: danger
	secondary: {
		color: base.palette.text.primary,
		backgroundColor: base.palette.background.dark,
		border: `2px solid ${base.palette.error.main}`,
		'&:hover': {
			color: base.palette.error.contrastText,
			backgroundColor: base.palette.error.main,
			border: `2px solid ${base.palette.error.main}`,
		},
		'&:disabled': {
			backgroundColor: base.palette.background.default,
			border: `2px solid ${base.palette.text.disabled}`,
			color: base.palette.text.disabled,
		},
	},
};

export default {
	styleOverrides: {
		root: { ...root },
		outlined: { ...outlined.base },
		outlinedPrimary: { ...outlined.primary },
		outlinedSecondary: { ...outlined.secondary },
	},
};
