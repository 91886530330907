import React from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Trans } from '@lingui/macro';

import Checkbox from '../misc/Checkbox';
import Textarea from '../misc/Textarea';

import { useService } from '../contexts/Service';

const generateCode = (length) => {
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;

	let code = '';
	for (let i = 0; i < length; i++) {
		code += characters.charAt(Math.floor(Math.random() * charactersLength));
		if ((i + 1) % 15 === 0) {
			code += '\n';
		}
	}

	return code.trim();
};

export default function Register(props) {
	const { backdrop } = useService();
	const [$copied, setCopied] = React.useState(false);
	const [$checkedRecoveryCode, setCheckedRecoveryCode] = React.useState(false);
	const [$checkedNewsletter, setCheckedNewsletter] = React.useState(false);

	const $code = React.useRef(generateCode(60));

	const handleRegister = async () => {
		backdrop(true);
		await props.onRegister($code.current.replace(/\n/g, ''));
		backdrop(false);
	};

	const handleCopy = async () => {
		setCopied(true);
	};

	const handleCheckedRecoveryCode = async () => {
		setCheckedRecoveryCode(!$checkedRecoveryCode);
	};

	const handleCheckedNewsletter = async () => {
		setCheckedNewsletter(!$checkedNewsletter);
	};

	return (
		<Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
			<Grid item xs={3} align="center">
				<Typography variant="h1">
					<Trans>User registration</Trans>
				</Typography>
				<Grid container spacing={3}>
					<Grid item xs={12} align="center">
						<Typography>
							<Trans>Account Recovery Code</Trans>
						</Typography>
					</Grid>
					<Grid item xs={12} align="center">
						<Textarea title={<Trans>Recovery Code</Trans>} readOnly={true} allowCopy={false} onCopy={handleCopy} rows={4} value={$code.current} />
					</Grid>
					<Grid item xs={12} align="center">
						<Checkbox label={<Trans>Yes, I securely stored the recovery code!</Trans>} onChange={handleCheckedRecoveryCode} checked={$checkedRecoveryCode} />
					</Grid>
					<Grid item xs={12} align="center">
						<Checkbox label={<Trans>Yes, I would like to receive horny news</Trans>} onChange={handleCheckedNewsletter} checked={$checkedNewsletter} />
					</Grid>
					<Grid item xs={12}>
						<Button variant="outlined" color="primary" fullWidth disabled={!$copied && !$checkedRecoveryCode} onClick={handleRegister}>
							<Trans>Register</Trans>
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

Register.defaultProps = {
	onRegister: function (code) {},
};
