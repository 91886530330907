import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { useLingui } from '@lingui/react';
import { Trans, t } from '@lingui/macro';

import Table from '../../misc/Table';
import Page from '../../misc/Page';
import { useService } from '../../contexts/Service';

export default function Alerts(props) {
	const navigate = useNavigate();
	const { groupid } = useParams();
	const { service, dispatch, backdrop } = useService();
	const { i18n } = useLingui();
	const [$ready, setReady] = React.useState(false);
	const [$group, setGroup] = React.useState(null);
	const [$alerts, setAlerts] = React.useState(null);

	const tableCells = [
		{
			id: 'user',
			align: 'left',
			disablePadding: false,
			sortable: true,
			label: i18n._(t`From`),
			field: 'user_formatted',
		},
		{
			id: 'timestamp',
			align: 'right',
			disablePadding: false,
			sortable: true,
			label: i18n._(t`Date`),
			field: 'timestamp_formatted',
		},
	];

	React.useEffect(() => {
		(async () => {
			await handleMount();
		})();

		return () => {
			handleUnmount();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleMount = async () => {
		await handleRefresh();

		dispatch('select_group', groupid);
		dispatch('set_group_topic', 'alerts');

		setReady(true);
	};

	const handleUnmount = async () => {};

	const handleRefresh = async () => {
		backdrop(true);

		const group = await service.Group(groupid);
		if (group === null) {
			navigate('/');
			backdrop(false);
			return;
		}
		setGroup(group);

		const alerts = await service.Alerts(groupid);
		if (alerts === null) {
			navigate('/group/' + groupid);
			backdrop(false);
			return;
		}
		setAlerts(alerts);

		backdrop(false);
	};

	const handleHelp = () => {};

	if ($ready === false || $alerts === null) {
		return null;
	}

	const allowRead = $group.rights.alertRead;
	const allowWrite = $group.rights.alertWrite;

	const tableRows = [];

	return (
		<Page breadcrumb={<NotificationsIcon />} title={<Trans>Alerts</Trans>} onHelp={handleHelp} onRefresh={handleRefresh}>
			<Table order="asc" orderBy="user" cells={tableCells} rows={tableRows} rowsPerPage={10} rowsPerPageOptions={[10, 25, 50]} />
		</Page>
	);
}

Alerts.defaultProps = {};
