import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

import API from './api';
import CoreRegistry from './core';

class Service {
	constructor(address) {
		this._reset();

		try {
			new URL(address);
			this.valid = true;
		} catch (e) {
			this.valid = false;
		}

		this.address = address;
		this.api = new API(this.address);

		this.refreshToken = null;
		this.refreshTokenTimeout = null;

		this.coreRegistry = CoreRegistry;
	}

	_reset() {
		this.address = '';
		this.api = null;

		this.coreRegistry = null;
		this.coreAPIs = new Map();

		this.valid = false;
		this.connected = false;
		this.registered = false;

		this.user = null;
		this.groups = new Map();
		this.invites = [];

		this.auth0User = null;

		this.listeners = [];
	}

	// Events

	AddListener(listener) {
		return this.listeners.push(listener) - 1;
	}

	RemoveListener(id) {
		this.listeners.splice(id, 1);
	}

	_dispatchEvent(severity, type, status, message) {
		switch (severity) {
			case 'error':
			case 'warning':
			case 'info':
			case 'success':
				break;
			default:
				return;
		}

		message = this.StatusString(status, message);

		for (let l of this.listeners) {
			l({
				severity: severity,
				type: type,
				message: message,
			});
		}
	}

	DispatchEvent(severity, status, message) {
		this._dispatchEvent(severity, 'user', status, message);
	}

	_setUser(user) {
		user = {
			email: '',
			name: '',
			picture: '',
			locale: '',
			last_group: '',
			invites: [],
			groups: [],
			...user,
		};

		this.user = {
			email: user.email,
			name: user.name,
			picture: user.picture,
			last_group: user.last_group,
			locale: user.locale,
		};

		this.invites = user.invites.slice();

		this.groups = new Map();
		for (let i = 0; i < user.groups.length; i++) {
			let group = {
				id: '',
				name: '',
				owner: false,
				roles: [],
				...user.groups[i],
			};

			group.roles = group.roles.slice();
			group.rights = this._createGroupRights(group.roles);

			this.groups.set(group.id, group);
		}
	}

	_setGroup(group) {
		group = {
			id: '',
			name: '',
			owner: false,
			users: [],
			...group,
		};

		group.users = group.users.slice();

		let g = this.groups.get(group.id);
		if (!g) {
			return;
		}

		g.name = group.name;

		for (let i = 0; i < group.users.length; i++) {
			let user = group.users[i];

			if (user.email === this.user.email) {
				g.owner = user.owner;
				g.roles = user.roles.slice();
				g.rights = this._createGroupRights(g.roles);
				break;
			}
		}

		this.groups.set(g.id, g);
	}

	_addGroup(group) {
		group = {
			id: '',
			...group,
		};

		if (!group.id) {
			return;
		}

		if (this.groups.has(group.id)) {
			return;
		}

		this.groups.set(group.id, { id: group.id });

		this._setGroup(group);
	}

	_createGroupRights(roles) {
		const rights = {
			groupRead: roles.includes('group:admin') || roles.includes('group:read') || true,
			groupWrite: roles.includes('group:admin'),

			alertRead: roles.includes('group:admin') || roles.includes('alert:write') || roles.includes('alert:read'),
			alertWrite: roles.includes('group:admin') || roles.includes('alert:write'),

			billingRead: roles.includes('group:admin') || roles.includes('billing:write') || roles.includes('billing:read'),
			billingWrite: roles.includes('group:admin') || roles.includes('billing:write'),

			coreRead: roles.includes('group:admin') || roles.includes('core:write') || roles.includes('core:read'),
			coreWrite: roles.includes('group:admin') || roles.includes('core:write'),

			ticketRead: roles.includes('group:admin') || roles.includes('ticket:write') || roles.includes('ticket:read'),
			ticketWrite: roles.includes('group:admin') || roles.includes('ticket:write'),
			ticketPanic: roles.includes('ticket:panic'),

			tokenRead: roles.includes('group:admin') || roles.includes('token:write') || roles.includes('token:read'),
			tokenWrite: roles.includes('group:admin') || roles.includes('token:write'),

			logRead: roles.includes('group:admin') || roles.includes('log:read'),
		};

		return rights;
	}

	_hasGroupRight(groupid, right) {
		const group = this.groups.get(groupid);
		if (!group) {
			return false;
		}

		if (!(right in group.rights)) {
			console.warn('unknown right', right);
			return false;
		}

		return group.rights[right];
	}

	// API calls

	async _call(fn, ...args) {
		const res = await fn.apply(this.api, args);
		if (res.err !== null) {
			if (res.err.status === 'NETWORK_ERROR') {
				// Network error
				this._dispatchEvent('error', 'network', res.err.status, res.err.message);
			} else if (res.err.code === 401) {
				if (fn !== this.api.TokenRefresh) {
					// Try to refresh access token
					if ((await this._refreshServiceToken()) === true) {
						// Retry call after successfull token refresh
						const res = await fn.apply(this.api, args);
						if (res.err !== null) {
							if (res.err.status === 'NETWORK_ERROR') {
								// Network error
								this._dispatchEvent('error', 'network', res.err.status, res.err.message);
							} else if (res.err.code === 401) {
								// Auth error
								this._dispatchEvent('error', 'auth', res.err.status, res.err.message);
							}
						}

						return [res.val, res.err];
					}
				}

				// Auth error
				this._dispatchEvent('error', 'auth', res.err.status, res.err.message);
			} else {
				// HTTP response error
			}
		}

		return [res.val, res.err];
	}

	StatusString(status, defval) {
		defval = defval ? defval : i18n._(t`Unknown response status encountered`);

		let message = defval;

		if (status && status.length !== 0) {
			switch (status) {
				case 'INVITE_ACCEPTED':
					message = i18n._(`Your invitation has been verified and you are now member of the group.`);
					break;
				case 'INVITE_EMAIL_CHANGED':
					message = i18n._(`The invitation email has been updated. The group admin has to approve it.`);
					break;
				case 'AUTHENTICATION_FAILED':
					message = i18n._(`Authentication failed. Please login.`);
					break;
				case 'INVITE_NOT_FOUND':
					message = i18n._(`The invitation has not been found.`);
					break;
				case 'USER_NOT_JOINED':
					message = i18n._(`It was not possible to add you to the inviting group.`);
					break;
				case 'USER_NO_MEMBER':
					message = i18n._(`You are not member of the group.`);
					break;
				case 'INVITE_EMAIL_ALLREADY_CHANGED':
					message = i18n._(`The invitation email has already been updated. The group admin has to approve it.`);
					break;
				case 'INVITE_USER_IS_MEMBER':
					message = i18n._(`You are already member of the inviting group.`);
					break;
				case 'INVITE_USER_ALREADY_INVITED':
					message = i18n._(t`This user has already been invited`);
					break;
				case 'INVITE_DELETED':
					message = i18n._(`The invitation has been deleted.`);
					break;
				case 'NETWORK_ERROR':
					message = i18n._(`Failed to communicate with the backend.`);
					break;
				case 'CORE_NETWORK_ERROR':
					message = i18n._(`Failed to communicate with the core API.`);
					break;
				case 'UNKNOWN_ERROR':
					message = i18n._(`Unknown error happened.`);
					break;
				case 'VALIDATION_ERROR':
					message = i18n._(`There was an error in the provided data: ${defval}`);
					break;
				default:
					break;
			}
		}

		return message;
	}

	IsConnected() {
		return this.connected;
	}

	IsRegistered() {
		return this.registered;
	}

	_setCoreToken(token) {
		let tokenFn = () => {
			return '';
		};

		if (token !== null) {
			tokenFn = async () => {
				if (typeof token === 'function') {
					return await token();
				} else if (typeof token === 'string') {
					return token;
				}

				return '';
			};
		}

		this.coreRegistry.SetTokenCallback(tokenFn);
	}

	_setServiceToken(access_token) {
		let tokenFn = () => {
			return '';
		};

		if (access_token !== null) {
			tokenFn = async () => {
				if (typeof access_token === 'function') {
					return await access_token();
				} else if (typeof access_token === 'string') {
					return access_token;
				}

				return '';
			};
		}

		this.api.SetTokenCallback(tokenFn);
	}

	_setRefreshServiceToken(refresh_token, expires_in) {
		if (this.refreshTokenTimeout !== null) {
			clearTimeout(this.refreshTokenTimeout);
		}

		if (refresh_token === null) {
			return;
		}

		this.refreshToken = refresh_token;

		this.refreshTokenTimeout = setTimeout(async () => {
			await this._refreshServiceToken();
		}, (expires_in - 60) * 1000);
	}

	async _refreshServiceToken() {
		let [val, err] = await this._call(this.api.TokenRefresh, this.refreshToken);
		if (err !== null) {
			return false;
		}

		this._setServiceToken(val.access_token);

		this._setRefreshServiceToken(this.refreshToken, val.expires_in);

		return true;
	}

	async Login(auth0token) {
		let token = '';
		if (typeof auth0token === 'function') {
			token = await auth0token();
		} else if (typeof auth0token === 'string') {
			token = auth0token;
		}

		this.connected = true;
		this.registered = false;

		let [val, err] = await this._call(this.api.UserLogin, token);
		if (err !== null) {
			return false;
		}

		this._setCoreToken(auth0token);
		this._setServiceToken(val.access_token, val.refresh_token, val.expires_in);

		if (val.refresh_token !== null) {
			this.registered = true;
			this._setUser(val.user_data);

			if (val.refresh_token !== null) {
				this._setRefreshServiceToken(val.refresh_token, val.expires_in);
			}
		}

		return this.registered;
	}

	async Register(name, email, picture, recovery_code) {
		let [val, err] = await this._call(this.api.UserCreate, {
			name: name,
			email: email,
			picture: picture,
			recovery_code: recovery_code,
		});

		if (err === null) {
			this.registered = true;
			this._setServiceToken(val.access_token, val.refresh_token, val.expires_in);
			this._setUser(val.user_data);
		} else {
			this._dispatchEvent('error', 'register', err.status, err.message);
		}

		return this.registered;
	}

	Logout() {
		this._setServiceToken(null);
		this._setRefreshServiceToken(null);

		this.connected = false;
		this.registered = false;
	}

	Reset() {
		return;
	}

	Address() {
		return this.address;
	}

	// Groups

	Groups() {
		return Array.from(this.groups.values());
	}

	LastGroup() {
		if (!this.user) {
			return '';
		}

		return this.user.last_group;
	}

	Group(groupid) {
		const group = this.groups.get(groupid);
		if (!group) {
			return null;
		}

		return JSON.parse(JSON.stringify(group));
	}

	async GroupUpdate(groupid) {
		const [val, err] = await this._call(this.api.GroupGet, groupid);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
			return null;
		}

		this._setGroup(val);

		return this.Group(groupid);
	}

	async GroupUsers(groupid) {
		const [val, err] = await this._call(this.api.GroupGet, groupid);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
			return null;
		}

		this._setGroup(val);

		for (let i = 0; i < val.users.length; i++) {
			let user = val.users[i];

			user.myself = user.email === this.user.email;
			val.users[i] = user;
		}

		return val.users.slice();
	}

	async GroupEdit(groupid, config) {
		const [, err] = await this._call(this.api.GroupEdit, groupid, config);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
		}

		return err;
	}

	async _createDefaultGroup() {
		return this.GroupAdd('Default');
	}

	async GroupAdd(name) {
		const [val, err] = await this._call(this.api.GroupCreate, {
			name: name,
		});

		if (err !== null) {
			this._dispatchEvent('error', 'group', err.status, err.message);
			return null;
		}

		this._addGroup(val);

		return val;
	}

	async GroupOwnership(groupid, owner) {
		const [, err] = await this._call(this.api.GroupOwnerTransfer, groupid, {
			email: owner,
		});

		if (err !== null) {
			this._dispatchEvent('error', 'group', err.status, err.message);
			return false;
		}

		return true;
	}

	// Cores

	async Cores(groupid) {
		if (!this._hasGroupRight(groupid, 'coreRead')) {
			return [];
		}

		const [val, err] = await this._call(this.api.Cores, groupid);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
			return [];
		}

		return val.map((core) => this._sanitizeCore(core));
	}

	async Core(groupid, coreid) {
		if (!this._hasGroupRight(groupid, 'coreRead')) {
			return [];
		}

		const [val, err] = await this._call(this.api.CoreGet, groupid, coreid);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
			return null;
		}

		return this._sanitizeCore(val);
	}

	async CoreAdd(groupid, config) {
		const [, err] = await this._call(this.api.CoreCreate, groupid, config);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
		}

		return err;
	}

	async CoreEdit(groupid, id, config) {
		const [, err] = await this._call(this.api.CoreEdit, groupid, id, config);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
		}

		return err;
	}

	async CoreDelete(groupid, id) {
		const [, err] = await this._call(this.api.CoreDelete, groupid, id);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
			return false;
		}

		return true;
	}

	async CoreMonitorInfo(groupid, coreids = []) {
		if (!this._hasGroupRight(groupid, 'coreRead')) {
			return [];
		}

		const query = {
			core_ids: coreids.slice(),
			filter: ['version', 'last_update', 'uptime_seconds'],
		};

		const [val, err] = await this._call(this.api.CoreMonitor, groupid, query);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
			return [];
		}

		return val;
	}

	async CoreMonitor(groupid, coreids = [], from, to, filter = [], step = 0) {
		if (!this._hasGroupRight(groupid, 'coreRead')) {
			return [];
		}

		const allFilter = [
			'version',
			'sys_cpu_ncores',
			'sys_cpu_used',
			'sys_mem_used_bytes',
			'sys_mem_total_bytes',
			'sys_disk_used_bytes',
			'sys_disk_total_bytes',
			'fs_mem_used_bytes',
			'fs_mem_limit_bytes',
			'fs_disk_used_bytes',
			'fs_disk_limit_bytes',
			'net_tx_used_kbit',
			'net_tx_limit_kbit',
			'processes_starting',
			'processes_running',
			'processes_finishing',
			'processes_finished',
			'processes_killed',
			'processes_failed',
			'uptime_seconds',
			'last_update',
		];

		const query = {
			core_ids: coreids.slice(),
			filter: filter.length !== 0 ? filter.slice() : allFilter,
		};

		if (from && to) {
			query.start = from.format('YYYY-MM-DD[T]HH:mm:ss.SSSZ');
			query.end = to.format('YYYY-MM-DD[T]HH:mm:ss.SSSZ');
		}

		if (step) {
			query.step = step;
		}

		const [val, err] = await this._call(this.api.CoreMonitor, groupid, query);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
			return [];
		}

		for (const core of val) {
			for (const metric in core.metrics) {
				core.metrics[metric] = core.metrics[metric].map((m) => [m[0], parseFloat(m[1])]);
			}
		}

		return val;
	}

	_sanitizeCore(initialCore) {
		let core = {
			name: '',
			id: '',
			domain_public: '',
			uri_path: {},
			...initialCore,
		};

		core.uri_path = {
			api: '/api',
			disk: '/',
			memfs: '/memfs',
			...core.uri_path,
		};

		return core;
	}

	// Core User

	async CoreUser(groupid, coreid) {
		if (!this._hasGroupRight(groupid, 'coreRead')) {
			return [];
		}

		const [val, err] = await this._call(this.api.CoreUserGet, groupid, coreid);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
			return [];
		}

		return val;
	}

	async CoreUserEdit(groupid, id, config) {
		const [, err] = await this._call(this.api.CoreUserEdit, groupid, id, config);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
		}

		return err;
	}

	async CoreUserDelete(groupid, id, config) {
		const [, err] = await this._call(this.api.CoreUserDelete, groupid, id, config);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
		}

		return err;
	}

	// Core API

	async CoreAPI(coreid, address) {
		let api = this.coreAPIs.get(coreid);
		if (api) {
			return [api, null, null];
		}

		try {
			api = await this.coreRegistry.Get(coreid, address);
		} catch (e) {
			if (e.version) {
				api = await this.coreRegistry.GetVersion(e.version);
			} else {
				api = await this.coreRegistry.GetLatestVersion();
			}

			return [api, e.message, e.cause];
		}

		this.coreAPIs.set(coreid, api);

		return [api, null, null];
	}

	CoreAPIRanges() {
		return this.coreRegistry.Ranges();
	}

	CoreAPIError(error, cause) {
		let errormessage = '';
		let causemessage = '';

		switch (error) {
			case 'CONNECTION_FAILED':
				errormessage = i18n._(`Connecting to the core failed.`);
				break;
			default:
				errormessage = `Unhandled error (${error})`;
				break;
		}

		if (error === 'CONNECTION_FAILED') {
			switch (cause) {
				case 'INVALID_API_VERSION':
					causemessage = i18n._(`The core API responded with an invalid version information.`);
					break;
				case 'UNSUPPORTED_API_VERSION':
					causemessage = i18n._(`The version of the core is not supported.`);
					break;
				case 'INVALID_ADDRESS':
					causemessage = i18n._(`The provided address of the core is invalid.`);
					break;
				case 'NETWORK_ERROR':
					causemessage = i18n._(`There was a problem establishing a network connection to the core.`);
					break;
				case 'MIXED_CONTENT':
					causemessage = i18n._(`The network connection to the core failed probably because of mixed content.`);
					break;
				case 'INVALID_API_RESPONSE':
					causemessage = i18n._(`The core API didn't respond as expected.`);
					break;
				case 'INVALID_ID':
					causemessage = i18n._(`The core has an unexpected ID.`);
					break;
				case 'AUTHORIZATION_FAILED':
					causemessage = i18n._(`Getting authorized access to the core API was not possible.`);
					break;
				case 'AUTH0_UNSUPPORTED':
					causemessage = i18n._(`Auth0 is not configured on the core.`);
					break;
				default:
					causemessage = `Unhandled cause (${cause})`;
					break;
			}
		} else {
			causemessage = cause;
		}

		return { error: errormessage, cause: causemessage };
	}

	// Tokens

	async Tokens(groupid) {
		if (!this._hasGroupRight(groupid, 'tokenRead')) {
			return [];
		}

		const [val, err] = await this._call(this.api.Tokens, groupid);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
			return [];
		}

		return val;
	}

	async TokenAdd(groupid, name, expiry_date) {
		const [, err] = await this._call(this.api.TokenCreate, groupid, {
			name: name,
			expiry_date: expiry_date.format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
		});
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
		}

		return err;
	}

	async TokenEdit(groupid, id, name, expiry_date) {
		const [, err] = await this._call(this.api.TokenEdit, groupid, id, {
			name: name,
			expiry_date: expiry_date.format('YYYY-MM-DD[T]HH:mm:ss.SSSZ'),
		});
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
		}

		return err;
	}

	async TokenDelete(groupid, id) {
		const [, err] = await this._call(this.api.TokenDelete, groupid, id);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
			return false;
		}

		return true;
	}

	// Invitations

	UserInvitations() {
		return this.invites.slice();
	}

	async UpdateUserInvitations() {
		if (!this.user) {
			return [];
		}

		await this.UpdateUser();

		return this.invites.slice();
	}

	async Invitations(groupid) {
		const [val, err] = await this._call(this.api.Invitations, groupid);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
			return [];
		}

		return val;
	}

	async Invitation(groupid, email) {
		const [val, err] = await this._call(this.api.Invitation, groupid, email);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
			return false;
		}

		return val;
	}

	async InvitationRevoke(id) {
		const [val, err] = await this._call(this.api.InvitationRevoke, id);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
			return err.status;
		}

		return val.status;
	}

	async InvitationVerify(id) {
		const [val, err] = await this._call(this.api.InvitationVerify, id);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.status);
			return err;
		}

		return val;
	}

	async InvitationUpdateEmail(id, email) {
		const [, err] = await this._call(this.api.InvitationUpdateEmail, id, email);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
			return false;
		}

		return true;
	}

	async InvitationResendEmail(id, email) {
		const [, err] = await this._call(this.api.InvitationResendEmail, id, email);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
			return false;
		}

		return true;
	}

	// Users

	User() {
		let user = {
			name: '',
			email: '',
			picture: '',
			locale: 'en',
		};

		if (!this.user) {
			return user;
		}

		user.name = this.user.name;
		user.email = this.user.email;
		user.picture = this.user.picture;
		user.locale = this.user.locale;

		return user;
	}

	async UpdateUser() {
		let [val, err] = await this._call(this.api.UserGet);
		if (err === null) {
			this._setUser(val);
		}
	}

	async UserEdit(groupid, config) {
		const [, err] = await this._call(this.api.GroupUserEdit, groupid, config);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
			return false;
		}

		return true;
	}

	async UserDelete(groupid, email) {
		const [, err] = await this._call(this.api.GroupUserDelete, groupid, email);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
			return false;
		}

		return true;
	}

	// Tickets

	async CountTickets(groupid, tickets = null) {
		if (!this._hasGroupRight(groupid, 'ticketRead')) {
			return 0;
		}

		if (!groupid || groupid === 'none') {
			return 0;
		}

		if (!tickets) {
			tickets = await this.Tickets(groupid);
		}

		tickets = tickets.filter((ticket) => {
			if (ticket.ticket_status !== 'open') {
				return false;
			}

			return true;
		});

		return tickets.length;
	}

	async Tickets(groupid) {
		if (!this._hasGroupRight(groupid, 'ticketRead')) {
			return [];
		}

		const [val, err] = await this._call(this.api.Tickets, groupid);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
			return [];
		}

		return val;
	}

	async Ticket(groupid, ticketid) {
		if (!this._hasGroupRight(groupid, 'ticketRead')) {
			return null;
		}

		const [val, err] = await this._call(this.api.TicketGet, groupid, ticketid);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
			return null;
		}

		return val;
	}

	async TicketCreate(groupid, config) {
		const [, err] = await this._call(this.api.TicketCreate, groupid, config);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
		}

		return err;
	}

	async TicketAddMessage(groupid, id, config) {
		const [, err] = await this._call(this.api.TicketEdit, groupid, id, config);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
		}

		return err;
	}

	// Alerts

	async CountAlerts(groupid, alerts = null) {
		if (!this._hasGroupRight(groupid, 'alertRead')) {
			return 0;
		}

		if (!groupid || groupid === 'none') {
			return 0;
		}

		if (!alerts) {
			alerts = await this.Alerts(groupid);
		}

		return alerts.length;
	}

	async Alerts(groupid) {
		const empty = {
			cores: [],
			service: [],
		};

		if (!this._hasGroupRight(groupid, 'alertRead')) {
			return empty;
		}

		const [val, err] = await this._call(this.api.Alerts, groupid);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
			return empty;
		}

		return val;
	}

	// Logs

	async Logs(groupid) {
		if (!this._hasGroupRight(groupid, 'logRead')) {
			return [];
		}

		const [val, err] = await this._call(this.api.Logs, groupid);
		if (err !== null) {
			this._dispatchEvent('error', 'core', err.status, err.message);
			return [];
		}

		return val;
	}
}

export default Service;
