import React from 'react';
import moment from 'moment';

import MenuItem from '@mui/material/MenuItem';
import Select from './Select';

import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

export default function LanguageSelect(props) {
	const { i18n } = useLingui();

	const handleChange = (event) => {
		event.preventDefault();

		const language = event.target.value;

		i18n.activate(language);
		moment.locale(language);

		props.onChange(language);
	};

	return (
		<Select label={<Trans>Language</Trans>} value={i18n.locale} fullWidth onChange={handleChange}>
			<MenuItem value="en">English</MenuItem>
			<MenuItem value="de">Deutsch</MenuItem>
			<MenuItem value="fr">Français</MenuItem>
			<MenuItem value="it">Italiano</MenuItem>
			<MenuItem value="pt">Português</MenuItem>
			<MenuItem value="es">Español</MenuItem>
		</Select>
	);
}

LanguageSelect.defaultProps = {
	onChange: function (lang) {},
};
