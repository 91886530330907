import React from 'react';
import { useNavigate } from 'react-router-dom';

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import Drawer from '@mui/material/Drawer';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import GroupsIcon from '@mui/icons-material/Groups';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TranslateIcon from '@mui/icons-material/Translate';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { Trans } from '@lingui/macro';

import Logo from './misc/Logo';
import * as Storage from './utils/storage';
import LanguageSelect from './misc/LanguageSelect';
import Dialog from './misc/modals/Dialog';
import SidebarButton from './misc/SidebarButton';
import { useService } from './contexts/Service';

const drawerWidthLeft = 70;

export default function Sidebar(props) {
	const navigate = useNavigate();
	const { control } = useService();

	const handleGroupAction = (action) => () => {
		switch (action) {
			case 'cores':
				navigate('/group/' + control.selectedGroupId + '/cores');
				break;
			case 'tokens':
				navigate('/group/' + control.selectedGroupId + '/tokens');
				break;
			case 'settings':
				navigate('/group/' + control.selectedGroupId + '/');
				break;
			case 'alerts':
				navigate('/group/' + control.selectedGroupId + '/alerts');
				break;
			case 'tickets':
				navigate('/group/' + control.selectedGroupId + '/tickets');
				break;
			case 'logs':
				navigate('/group/' + control.selectedGroupId + '/logs');
				break;
			default:
				break;
		}
	};

	const [$languageDialog, setLanguageDialog] = React.useState(false);

	const handleLanguage = () => {
		setLanguageDialog(!$languageDialog);
	};

	const handleLanguageChange = (language) => {
		Storage.Set('language', language);
	};

	return (
		<Drawer
			sx={{
				width: drawerWidthLeft,
				flexShrink: 0,
				'& .MuiDrawer-paper': {
					width: drawerWidthLeft,
					boxSizing: 'border-box',
					backgroundColor: '#333333',
					borderRight: '2px solid rgba(0, 0, 0, .25)',
				},
			}}
			variant="permanent"
			anchor="left"
		>
			<Stack direction="column" justifyContent="space-between" alignItems="center" spacing={2} sx={{ height: '100%' }}>
				<Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={1}>
					<div style={{ width: 25, marginTop: 3, marginBottom: 20 }}>
						<Logo />
					</div>
					{control.selectedGroupId !== '' && (
						<React.Fragment>
							<SidebarButton
								titel="Core"
								icon={<AccountTreeIcon />}
								color={control.groupTopic === 'cores' ? 'selected' : 'unselected'}
								onClick={handleGroupAction('cores')}
							></SidebarButton>
							<SidebarButton
								titel="Alert"
								icon={<NotificationsIcon />}
								badge
								badges={control.badges.alerts}
								color={control.groupTopic === 'alerts' ? 'selected' : 'unselected'}
								onClick={handleGroupAction('alerts')}
							></SidebarButton>
							<SidebarButton
								titel="Ticket"
								icon={<MailOutlineIcon />}
								iconDistance={-6}
								badge
								badges={control.badges.tickets}
								color={control.groupTopic === 'tickets' ? 'selected' : 'unselected'}
								onClick={handleGroupAction('tickets')}
							></SidebarButton>
							<SidebarButton
								titel="Token"
								icon={<VpnKeyIcon />}
								iconDistance={-13}
								color={control.groupTopic === 'tokens' ? 'selected' : 'unselected'}
								onClick={handleGroupAction('tokens')}
							></SidebarButton>
							<SidebarButton
								titel="Setting"
								icon={<GroupsIcon />}
								iconDistance={-13}
								color={control.groupTopic === 'settings' ? 'selected' : 'unselected'}
								onClick={handleGroupAction('settings')}
							></SidebarButton>
							<SidebarButton
								titel="logs"
								icon={<FormatListBulletedIcon />}
								iconDistance={-13}
								color={control.groupTopic === 'logs' ? 'selected' : 'unselected'}
								onClick={handleGroupAction('logs')}
							></SidebarButton>
						</React.Fragment>
					)}
				</Stack>
				<Stack direction="column" justifyContent="flex-end" alignItems="center" spacing={2}>
					<SidebarButton titel="Language" icon={<TranslateIcon />} onClick={handleLanguage}></SidebarButton>
					<Dialog
						open={$languageDialog}
						onClose={handleLanguage}
						title={<Trans>Select language</Trans>}
						size="small"
						buttonsRight={
							<Button variant="outlined" color="unselected" onClick={handleLanguage}>
								<Trans>Abort</Trans>
							</Button>
						}
					>
						<LanguageSelect onChange={handleLanguageChange} />
					</Dialog>
				</Stack>
			</Stack>
		</Drawer>
	);
}

Sidebar.defaultProps = {
	onLogout: () => {},
};
