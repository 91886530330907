import React from 'react';

import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';

import { Trans } from '@lingui/macro';

import { useService } from '../contexts/Service';

const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 5,
		marginTop: theme.spacing(1),
		minWidth: 20,
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0',
			backgroundColor: theme.palette.background.paper,
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.common.white,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: theme.palette.background.box_default,
			},
			'&:hover': {
				backgroundColor: theme.palette.background.box_default,
			},
		},
	},
}));

export default function ServiceAvatar(props) {
	const { service } = useService();
	const { name, picture } = service.User();
	const [$anchorEl, setAnchorEl] = React.useState(null);

	const handleMenuToggle = (event) => {
		if ($anchorEl === null) {
			setAnchorEl(event.currentTarget);
		} else {
			setAnchorEl(null);
		}
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleAction = (what) => async () => {
		props.onAction(what);
	};

	return (
		<Stack direction="row" spacing={2}>
			<Button size="small" fullWidth onClick={handleMenuToggle}>
				<Grid container direction="row" alignItems="center">
					<Grid item xs={12}>
						<Avatar sx={{ boxShadow: 2, width: 25, height: 25, left: 14, bottom: 6, marginTop: 1 }} onClick={handleMenuToggle} src={picture}>
							{name.toUpperCase().charAt(0)}
						</Avatar>
						<StyledMenu anchorEl={$anchorEl} open={$anchorEl !== null} onClose={handleMenuClose} onClick={handleMenuClose}>
							<MenuItem onClick={handleAction('settings')}>
								<SettingsIcon fontSize="small" />
								<Trans>Settings</Trans>
							</MenuItem>
							<MenuItem onClick={handleAction('invitations')}>
								<Badge badgeContent={props.invitations} color="secondary" overlap="circular" max={9}>
									<MailOutlineIcon fontSize="small" />
								</Badge>
								<Trans>Invitations</Trans>
							</MenuItem>
							<MenuItem onClick={handleAction('logout')}>
								<LogoutIcon fontSize="small" />
								<Trans>Logout</Trans>
							</MenuItem>
						</StyledMenu>
					</Grid>
				</Grid>
			</Button>
		</Stack>
	);
}

ServiceAvatar.defaultProps = {
	invitations: 0,
	onAction: () => {},
};
