import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

import { useLingui } from '@lingui/react';
import { Trans, t } from '@lingui/macro';

import DatePicker from '../../misc/DatePicker';
import Dialog from '../../misc/modals/Dialog';
import Table from '../../misc/Table';
import Page from '../../misc/Page';
import { useService } from '../../contexts/Service';

function setTokenDefaults() {
	return {
		id: '',
		name: '',
		expiry_date: moment().add(1, 'year'),
	};
}

function ErrorTextField(props) {
	let { error, ...other } = props;

	return (
		<React.Fragment>
			<TextField color="secondary" {...other} />
			{error !== null && (
				<Typography color="red" variant="caption">
					{error}
				</Typography>
			)}
		</React.Fragment>
	);
}

export default function Tokens(props) {
	const navigate = useNavigate();
	const { groupid } = useParams();
	const { service, dispatch, backdrop } = useService();
	const { i18n } = useLingui();
	const [$ready, setReady] = React.useState(false);
	const [$error, setError] = React.useState({});
	const [$deleteDialog, setDeleteDialog] = React.useState(false);
	const [$tokens, setTokens] = React.useState(null);
	const [$addEditToken, setAddEditToken] = React.useState(false);
	const [$token, setToken] = React.useState(setTokenDefaults());
	const [$deleteTokenId, setDeleteTokenId] = React.useState('');
	const [$group, setGroup] = React.useState(null);

	const tableCells = [
		{
			id: 'name',
			align: 'left',
			disablePadding: false,
			sortable: true,
			label: i18n._(t`Name`),
			field: 'name',
		},
		{
			id: 'expiry_date',
			align: 'right',
			disablePadding: false,
			sortable: true,
			label: i18n._(t`Expires`),
			field: 'expiry_date_formatted',
		},
		{
			id: 'secret',
			align: 'left',
			disablePadding: false,
			sortable: false,
			label: i18n._(t`Secret`),
			field: 'secret',
		},
		{
			id: 'actions',
			align: 'right',
			disablePadding: false,
			sortable: false,
			label: '',
			field: 'actions',
		},
	];

	React.useEffect(() => {
		(async () => {
			await handleMount();
		})();

		return () => {
			handleUnmount();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleMount = async () => {
		await handleRefresh();

		dispatch('select_group', groupid);
		dispatch('set_group_topic', 'tokens');

		setReady(true);
	};

	const handleUnmount = async () => {};

	const handleRefresh = async () => {
		backdrop(true);

		const group = await service.Group(groupid);
		if (group === null) {
			backdrop(false);
			navigate('/');
			return;
		}
		setGroup(group);

		const tokens = await service.Tokens(groupid);
		setTokens(tokens);

		backdrop(false);
	};

	const handleTokenAdd = () => {
		setError({});
		setToken(setTokenDefaults());
		setAddEditToken(true);
	};

	const handleTokenEdit = (id) => () => {
		let token = null;

		for (let i = 0; i < $tokens.length; i++) {
			if ($tokens[i].id !== id) {
				continue;
			}

			token = $tokens[i];
			break;
		}

		if (token === null) {
			return;
		}

		setError({});

		setToken({
			id: token.id,
			name: token.name,
			expiry_date: moment(token.expiry_date),
		});

		setAddEditToken(true);
	};

	const handleTokenAddEditAbort = () => {
		setAddEditToken(false);
	};

	const handleTokenAddEditDone = async () => {
		backdrop(true);

		setError({});

		let err = null;
		if ($token.id.length === 0) {
			// add token ...
			err = await service.TokenAdd(groupid, $token.name, $token.expiry_date);
		} else {
			err = await service.TokenEdit(groupid, $token.id, $token.name, $token.expiry_date);
		}

		if (err !== null) {
			if (err.status === 'VALIDATION_ERROR' && err.where === 'body') {
				setError({
					...$error,
					[err.field]: err.message,
				});
			}

			backdrop(false);
			return;
		}

		setAddEditToken(false);

		setToken(setTokenDefaults());

		let tokens = await service.Tokens(groupid);
		setTokens(tokens);

		backdrop(false);
	};

	const handleTokenName = (event) => {
		const name = event.target.value;

		setToken({
			...$token,
			name: name,
		});
	};

	const handleTokenExpiryDate = (value) => {
		if (value === null) {
			return;
		}

		setToken({
			...$token,
			expiry_date: value,
		});
	};

	const handleTokenDeleteDialog = (id) => () => {
		setDeleteTokenId(id);

		setDeleteDialog(!$deleteDialog);
	};

	const handleTokenDelete = async () => {
		backdrop(true);

		const res = await service.TokenDelete(groupid, $deleteTokenId);

		if (res === true) {
			let tokens = await service.Tokens(groupid);

			setTokens(tokens);
		}

		setDeleteDialog(false);
		setDeleteTokenId('');

		backdrop(false);
	};

	const handleHelp = () => {};

	const hasError = (field) => {
		if (field in $error) {
			return $error[field];
		}

		return null;
	};

	const isValidToken = (token) => {
		if (token.name.length === 0) {
			return false;
		}

		if (token.expiry_date.length === 0) {
			return false;
		}

		return true;
	};

	if ($ready === false) {
		return null;
	}

	const allowRead = $group.rights.tokenRead;
	const allowWrite = $group.rights.tokenWrite;

	let tableRows = [];

	if (allowRead) {
		tableRows = $tokens.map((token) => {
			return {
				_key: token.id,
				name: token.name,
				expiry_date: moment(token.expiry_date).format('YYYY-MM-DD HH:mm:ss'),
				expiry_date_formatted: moment(token.expiry_date).format('YYYY-MM-DD HH:mm:ss'),
				secret: token.secret,
				actions: (
					<React.Fragment>
						<IconButton size="small" onClick={handleTokenEdit(token.id)}>
							<EditIcon fontSize="small" />
						</IconButton>
						<IconButton size="small" disabled={!allowWrite} onClick={handleTokenDeleteDialog(token.id)}>
							<DeleteIcon fontSize="small" />
						</IconButton>
					</React.Fragment>
				),
			};
		});
	}

	return (
		<React.Fragment>
			<Page
				breadcrumb={<VpnKeyIcon fontSize="normal" />}
				title={<Trans>Tokens</Trans>}
				onHelp={handleHelp}
				onRefresh={handleRefresh}
				onAdd={handleTokenAdd}
				onAddLabel={<Trans>Add token</Trans>}
				onAddDisabled={!allowWrite}
			>
				{$tokens.length !== 0 ? (
					<Table order="asc" orderBy="name" cells={tableCells} rows={tableRows} rowsPerPage={10} rowsPerPageOptions={[10, 25, 50]} />
				) : (
					<Button variant="outlined" color="primary" disabled={!allowWrite} onClick={handleTokenAdd}>
						<Trans>Add token</Trans>
					</Button>
				)}
			</Page>
			<Dialog
				open={$deleteDialog}
				onClose={handleTokenDeleteDialog('')}
				title={<Trans>Do you want to delete this token?</Trans>}
				buttonsLeft={
					<Button variant="outlined" onClick={handleTokenDeleteDialog('')}>
						<Trans>Abort</Trans>
					</Button>
				}
				buttonsRight={
					<Button variant="outlined" color="secondary" disabled={!allowWrite} onClick={handleTokenDelete}>
						<Trans>Delete</Trans>
					</Button>
				}
			>
				<Typography>
					<Trans>Deleting a token cannot be reversed. The token will stop working immediately.</Trans>
				</Typography>
			</Dialog>
			<Dialog
				open={$addEditToken}
				onClose={handleTokenAddEditAbort}
				title={$token.id.length === 0 ? <Trans>Add token</Trans> : <Trans>Edit Token</Trans>}
				buttonsLeft={
					<Button variant="outlined" color="secondary" onClick={handleTokenAddEditAbort}>
						<Trans>Abort</Trans>
					</Button>
				}
				buttonsRight={
					<Button variant="outlined" color="primary" disabled={!isValidToken($token) || !allowWrite} onClick={handleTokenAddEditDone}>
						<Trans>Save</Trans>
					</Button>
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<ErrorTextField
							error={hasError('name')}
							variant="outlined"
							fullWidth
							label={<Trans>Token Name</Trans>}
							value={$token.name}
							disabled={!allowWrite}
							onChange={handleTokenName}
						/>
					</Grid>
					<Grid item xs={12}>
						<DatePicker
							label={<Trans>Expiry Date</Trans>}
							minDateTime={moment()}
							value={moment($token.expiry_date)}
							disabled={!allowWrite}
							onChange={handleTokenExpiryDate}
						/>
					</Grid>
				</Grid>
			</Dialog>
		</React.Fragment>
	);
}

Tokens.defaultProps = {};
