import React from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Trans } from '@lingui/macro';

export default function Network(props) {
	return (
		<Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
			<Grid item xs={3} align="center">
				<Typography variant="h1">
					<Trans>Network Error</Trans>
				</Typography>
				<Grid container spacing={3}>
					<Grid item xs={12} align="center">
						<Typography>
							<Trans>There was an error connecting to the API.</Trans>
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Button variant="outlined" color="primary" fullWidth onClick={() => window.location.reload()}>
							<Trans>Retry</Trans>
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

Network.defaultProps = {
	address: '',
};
