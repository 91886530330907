import React from 'react';

import { styled } from '@mui/material/styles';
import { Trans } from '@lingui/macro';

import AddIcon from '@mui/icons-material/Add';
import ArticleIcon from '@mui/icons-material/Article';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import Grid from '@mui/material/Grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';
import SvgIcon from '@mui/material/SvgIcon';
import BarChartIcon from '@mui/icons-material/BarChart';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';

import * as Storage from '../utils/storage';
import Checkbox from './Checkbox';
import Select from './Select';

const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 5,
		marginTop: theme.spacing(1),
		minWidth: 20,
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0',
			backgroundColor: theme.palette.background.paper,
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.common.white,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: theme.palette.background.box_default,
			},
			'&:hover': {
				backgroundColor: theme.palette.background.box_default,
			},
		},
	},
}));

// drawer
const drawerWidth = 400;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
	flexGrow: 1,
	width: { sm: `calc(100% - (${drawerWidth}px - 80px))` },
	padding: 0,
	transition: theme.transitions.create('margin', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	marginRight: 0,
	...(open && {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: drawerWidth,
	}),
}));

export default function Page(props) {
	// drawer
	const [openDrawer, setOpenDrawer] = React.useState(Storage.Get('alert_sidebar', 'true') === 'true');
	const handleDrawerOpen = () => {
		setOpenDrawer(true);
		Storage.Set('alert_sidebar', true);
	};
	const handleDrawerClose = () => {
		setOpenDrawer(false);
		Storage.Set('alert_sidebar', false);
	};
	const { window } = props;

	// settings menu
	const [anchorSettingsMenu, setSettingsMenu] = React.useState(null);
	const openSettingsMenu = Boolean(anchorSettingsMenu);
	const handleSettingsMenuClick = (event) => {
		setSettingsMenu(event.currentTarget);
	};
	const handleSettingsMenuClose = () => {
		setSettingsMenu(null);
	};

	// stats menu
	const [anchorStatsMenu, setStatsMenu] = React.useState(null);
	const openStatsMenu = Boolean(anchorStatsMenu);
	const handleStatsMenuClick = (event) => {
		setStatsMenu(event.currentTarget);
	};
	const handleStatsMenuClose = () => {
		setStatsMenu(null);
	};

	const container = window !== undefined ? () => window().document.body : undefined;

	const alertSidebarContent = (
		<React.Fragment>
			<Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={0} style={{ padding: 20 }}>
				<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} style={{ marginTop: 15 }}>
					<Typography variant="h2" gutterBottom>
						<Trans>Alerts</Trans>
					</Typography>
					<IconButton variant="text" color="secondary" style={{ padding: 0, marginTop: -10 }} onClick={handleDrawerClose}>
						<HighlightOffIcon xs={{ width: '1.5rem', height: '1.5rem' }} />
					</IconButton>
				</Stack>
				{props.sidebarContent}
			</Stack>
			{(!props.sidebarContent || props.sidebarContent.length === 0) && (
				<Stack direction="column" justifyContent="center" alignItems="center" spacing={2} style={{ minHeight: 'calc(100% - 800px)' }}>
					Good job
				</Stack>
			)}
		</React.Fragment>
	);

	return (
		<React.Fragment>
			<Main open={openDrawer}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="flex-start"
					spacing={2}
					style={{
						background: 'linear-gradient(110deg, rgba(31,31,31,1) 0%, rgba(45,45,45,1) 20%)',
						borderRight: '1px solid rgba(0,0,0,.35)',
						borderBottom: '1px solid rgba(0,0,0,.35)',
					}}
				>
					<Grid container spacing={0} style={{ width: 8, marginTop: 20 }}>
						<Grid item>
							<SvgIcon style={{ height: 120, marginTop: -28, marginLeft: 1 }}>
								<path
									fill="#eaea05"
									d="M.99 80.01C.445 82.142 0 82.086 0 79.886v-78c0-2.2 1.028-2.522 2.284-.716L13.779 17.7c1.257 1.806 1.839 5.028 1.295 7.159L.99 80.01z"
								/>
							</SvgIcon>
						</Grid>
					</Grid>
					<Grid container spacing={2} style={{ marginTop: 10 }}>
						<Grid item xs={12}>
							<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
								<Stack spacing={0}>
									<Typography style={{ fontSize: '2.8rem', fontWeight: 300 }}>{props.title}</Typography>
									{props.breadcrumb}
								</Stack>
								{!openDrawer && (
									<Button
										color="inherit"
										aria-label="open drawer"
										edge="end"
										onClick={handleDrawerOpen}
										sx={{
											background: props.sidebarContent && props.sidebarContent.length !== 0 ? 'rgba(216, 72, 72, .75)' : 'unset',
											...(openDrawer && { display: 'none' }),
										}}
									>
										<ChevronLeftIcon />
									</Button>
								)}
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<Stack
								direction={{ xs: 'column', md: 'row' }}
								justifyContent="space-between"
								alignItems="flex-start"
								spacing={2}
								style={{ marginBottom: 35 }}
							>
								<Stack direction={{ xs: 'column', md: 'row' }} spacing={1} alignItems="flex-start" justifyContent="flex-start">
									{props.subTitle}
								</Stack>
								<Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
									{props.subMenu}
									{props.statsMenu && (
										<React.Fragment>
											<Button
												variant="outlined"
												size="medium"
												color="yellow2"
												id="page-statsmenu-button"
												aria-controls={anchorStatsMenu ? 'page-statsmenu' : undefined}
												aria-haspopup="true"
												aria-expanded={openStatsMenu ? 'true' : undefined}
												onClick={handleStatsMenuClick}
												style={{ whiteSpace: 'nowrap', height: 35 }}
											>
												<BarChartIcon style={{ marginRight: 10 }} />{' '}
												{props.statsMenu.timerange_value <= 3600
													? props.statsMenu.timerange_value / 60 + ' min.'
													: props.statsMenu.timerange_value / 60 / 60 + ' hours'}
											</Button>
											<StyledMenu
												id="page-statsmenu"
												anchorEl={anchorStatsMenu}
												open={openStatsMenu}
												onClose={handleStatsMenuClose}
												MenuListProps={{
													'aria-labelledby': 'page-statsmenu-button',
												}}
											>
												<Grid container spacing={1} style={{ padding: 15, maxWidth: 350 }}>
													<Grid item xs={12} style={{ marginBottom: 10 }}>
														<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
															<BarChartIcon />
															<Typography>
																<Trans>Chart settings</Trans>
															</Typography>
														</Stack>
													</Grid>
													<Grid item xs={12}>
														<Select
															label={<Trans>Timerange</Trans>}
															description={<Trans>Period of metrics, which are represented.</Trans>}
															fullWidth={true}
															size="small"
															value={props.statsMenu.timerange_value}
															onChange={props.statsMenu.timerange_onchange}
														>
															<MenuItem value={5 * 60}>Last 5 minutes</MenuItem>
															<MenuItem value={10 * 60}>Last 10 minutes</MenuItem>
															<MenuItem value={30 * 60}>Last 30 minutes</MenuItem>
															<MenuItem value={1 * 3600}>Last 1 hour</MenuItem>
															<MenuItem value={3 * 3600}>Last 3 hours</MenuItem>
														</Select>
													</Grid>
													<Grid item xs={12}>
														<Select
															label={<Trans>Resolution</Trans>}
															description={<Trans>Period between two metrics points.</Trans>}
															fullWidth={true}
															size="small"
															value={props.statsMenu.interval_value}
															onChange={props.statsMenu.interval_onchange}
														>
															<MenuItem value={5}>
																<Trans>5 seconds steps</Trans>
															</MenuItem>
															<MenuItem value={10}>
																<Trans>10 seconds steps</Trans>
															</MenuItem>
															<MenuItem value={15}>
																<Trans>15 seconds steps</Trans>
															</MenuItem>
															<MenuItem value={30}>
																<Trans>30 seconds steps</Trans>
															</MenuItem>
															<MenuItem value={60}>
																<Trans>1 minute steps</Trans>
															</MenuItem>
															<MenuItem value={5 * 60}>
																<Trans>5 minute steps</Trans>
															</MenuItem>
														</Select>
													</Grid>
													<Grid item xs={12}>
														<Divider />
													</Grid>
													<Grid item xs={12}>
														<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
															<BarChartIcon />
															<Typography>
																<Trans>Chart presentation</Trans>
															</Typography>
														</Stack>
													</Grid>
													<Grid item xs={12}>
														<Checkbox label={<Trans>Viewer & Bandwidth metrics</Trans>} checked={true} />
														<Checkbox label={<Trans>System- & Filesystem metrics</Trans>} checked={true} />
														<Checkbox label={<Trans>Process metrics</Trans>} checked={true} />
													</Grid>
												</Grid>
											</StyledMenu>
										</React.Fragment>
									)}
									<Button
										variant="outlined"
										size="medium"
										color="yellow2"
										id="page-submenu-button"
										aria-controls={anchorSettingsMenu ? 'page-submenu' : undefined}
										aria-haspopup="true"
										aria-expanded={openSettingsMenu ? 'true' : undefined}
										onClick={handleSettingsMenuClick}
									>
										<KeyboardArrowDownIcon />
									</Button>
									<StyledMenu
										id="page-submenu"
										anchorEl={anchorSettingsMenu}
										open={openSettingsMenu}
										onClose={handleSettingsMenuClose}
										MenuListProps={{
											'aria-labelledby': 'page-submenu-button',
										}}
									>
										{typeof props.onAdd === 'function' && (
											<MenuItem onClick={props.onAdd} disabled={props.onAddDisabled}>
												<ListItemIcon>
													<AddIcon fontSize="small" />
												</ListItemIcon>
												<Trans>Add</Trans>
											</MenuItem>
										)}
										{typeof props.onFs === 'function' && (
											<MenuItem onClick={props.onFs} disabled={props.onFsDisabled}>
												<ListItemIcon>
													<FindInPageIcon fontSize="small" />
												</ListItemIcon>
												<Trans>Filesystem</Trans>
											</MenuItem>
										)}
										{typeof props.onLog === 'function' && (
											<MenuItem onClick={props.onLog} disabled={props.onLogDisabled}>
												<ListItemIcon>
													<ArticleIcon fontSize="small" />
												</ListItemIcon>
												<Trans>Logging</Trans>
											</MenuItem>
										)}
										{typeof props.onFfCmd === 'function' && (
											<MenuItem onClick={props.onFfCmd} disabled={props.onFfCmdDisabled}>
												<ListItemIcon>
													<IntegrationInstructionsIcon fontSize="small" />
												</ListItemIcon>
												<Trans>FFmpeg Command</Trans>
											</MenuItem>
										)}
										{typeof props.onEdit === 'function' && (
											<MenuItem onClick={props.onEdit} disabled={props.onEditDisabled}>
												<ListItemIcon>
													<SettingsIcon fontSize="small" />
												</ListItemIcon>
												<Trans>Settings</Trans>
											</MenuItem>
										)}
										{typeof props.onDelete === 'function' && (
											<MenuItem onClick={props.onDelete} disabled={props.onDeleteDisabled}>
												<ListItemIcon>
													<DeleteForeverIcon fontSize="small" />
												</ListItemIcon>
												<Trans>Delete</Trans>
											</MenuItem>
										)}
										{typeof props.onHelp === 'function' && (
											<MenuItem onClick={props.onHelp} disabled={props.onHelpDisabled}>
												<ListItemIcon>
													<HelpOutlineIcon fontSize="small" />
												</ListItemIcon>
												<Trans>Documentation</Trans>
											</MenuItem>
										)}
									</StyledMenu>
								</Stack>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							{props.headerContent}
						</Grid>
					</Grid>
					<Grid container spacing={0} style={{ width: 30 }}></Grid>
				</Stack>
				{props.children}
			</Main>
			<Drawer
				container={container}
				ModalProps={{
					keepMounted: true,
				}}
				sx={{
					display: 'block',
					width: {
						xs: 0,
						sm: drawerWidth,
					},
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						backgroundColor: 'rgba(66,66,66,.95)',
						opacity: '1',
						borderLeft: 'unset',
						boxSizing: 'border-box',
					},
				}}
				variant="persistent"
				anchor="right"
				open={openDrawer}
			>
				{alertSidebarContent}
			</Drawer>
		</React.Fragment>
	);
}

Page.defaultProps = {
	supTitle: '',
	subTitle: '',
	title: '',
	variant: 'h2',
	contentAlign: 'left',
	onAbort: null,
	onDelete: null,
	onDeleteDisabled: false,
	onHelp: null,
	onHelpDisabled: false,
	onEdit: null,
	onEditDisabled: false,
	onLog: null,
	onLogDisabled: false,
	onAdd: null,
	onAddLabel: '',
	onAddDisabled: false,
	onFs: null,
	onFsDisabled: false,
	onFfCmd: null,
	onFfCmdDisabled: false,
	onRefresh: null,
	subMenu: null,
	breadcrumb: null,
	headerContent: null,
	sidebarContent: null,
	statsMenu: null,
};
