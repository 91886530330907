/* eslint-disable import/no-anonymous-default-export */
import base from '../base';

export default {
	styleOverrides: {
		root: {
			padding: '2em 2.5em 2em 2.5em',
			width: '95%',
			maxWidth: 540,
			maxHeight: '95%',
			overflow: 'scroll',
			backgroundColor: 'rgb(51, 51, 51)',
			color: base.palette.text.primary,
			border: '1px solid rgba(255,255,255,.1)',
		},
	},
};
