/* eslint-disable import/no-anonymous-default-export */
import base from '../base';

export default {
	styleOverrides: {
		root: {
			minWidth: '0px!important',
			textTransform: 'initial',
			margin: 'unset!important',
			color: base.palette.text.primary,
			textAlign: 'center!important',
			minHeight: 'unset!important',
			borderRadius: '4px 4px 0px 0px!important',
			'&.Mui-selected': {
				backgroundColor: `${base.palette.primary.dark}!important`,
			},
		},
		wrapper: {
			alignItems: 'flex-start!important',
		},
	},
};
