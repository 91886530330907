const isAvailable = () => {
	// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
	let storage = null;

	try {
		storage = window.localStorage;
		const x = '__storage_test__';
		storage.setItem(x, x);
		storage.removeItem(x);
		return true;
	} catch (e) {
		return (
			e instanceof DOMException &&
			// everything except Firefox
			(e.code === 22 ||
				// Firefox
				e.code === 1014 ||
				// test name field too, because code might not be present
				// everything except Firefox
				e.name === 'QuotaExceededError' ||
				// Firefox
				e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
			// acknowledge QuotaExceededError only if there's something already stored
			storage &&
			storage.length !== 0
		);
	}
};

const available = isAvailable();
const prefix = '@@datarhei-service@@';

const Set = (key, value) => {
	if (available === false) {
		return;
	}

	window.localStorage.setItem(prefix + key, value);
};

const Get = (key, defval) => {
	if (available === false) {
		return defval;
	}

	const value = window.localStorage.getItem(prefix + key);
	if (value === null) {
		return defval;
	}

	return value;
};

const Remove = (key) => {
	if (available === false) {
		return;
	}

	window.localStorage.removeItem(prefix + key);
};

export { Set, Get, Remove };
