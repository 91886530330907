/* eslint-disable import/no-anonymous-default-export */
import base from '../base';

export default {
	styleOverrides: {
		root: {
			color: base.palette.text.primary,
			backgroundColor: base.palette.primary.dark,
			'& $notchedOutline': {
				borderColor: base.palette.primary.main,
				borderWidth: 2,
			},
			'&:hover $notchedOutline': {
				borderColor: base.palette.primary.light,
			},
			'&$focused $notchedOutline': {
				borderColor: base.palette.secondary.main,
			},
			'&$disabled': {
				'& fieldset.MuiOutlinedInput-notchedOutline': {
					backgroundColor: base.palette.background.darker,
					borderColor: base.palette.primary.main,
				},
			},
		},
		focused: {},
		notchedOutline: {},
		input: {
			'&:-webkit-autofill': {
				WebkitTextFillColor: base.palette.text.primary,
				WebkitBoxShadow: `0 0 0 1000px ${base.palette.primary.dark} inset`,
			},
		},
	},
};
