import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import HelpIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

// todo: use MuiDialog

const Component = React.forwardRef((props, ref) => {
	const size = props.size;
	const style = {
		width: 540,
	};

	switch (size) {
		case 'small':
			style.width = 480;
			break;
		case 'large':
			style.width = 980;
			break;
		case 'max':
			style.width = 0.95;
			break;
		default:
			break;
	}

	return (
		<Modal open={props.open} onClose={props.onClose}>
			<Paper variant="modal" sx={style} elevation={0} ref={ref} tabIndex={-1}>
				<Grid container spacing={0}>
					<Grid
						item
						xs={12}
						style={{
							marginBottom: '.7em',
						}}
					>
						<div style={{ float: 'right' }}>
							<Stack direction="row" spacing={1}>
								{typeof props.onClose === 'function' && (
									<IconButton color="inherit" size="small" onClick={props.onClose}>
										<CloseIcon fontSize="small" />
									</IconButton>
								)}
								{typeof props.onHelp === 'function' && (
									<IconButton color="inherit" size="small" onClick={props.onHelp}>
										<HelpIcon fontSize="small" />
									</IconButton>
								)}
							</Stack>
						</div>
						<Typography variant="button">{props.title}</Typography>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					{props.children}
				</Grid>
				{(props.footer === "true") && (
					<Grid container spacing={0}>
						<Grid
							item
							xs={12}
							style={{
								marginTop: '1.2em',
								minHeight: '38px',
							}}
						>
							<div style={{ float: 'right' }}>
								<Stack direction="row" spacing={1}>
									{props.buttonsRight}
								</Stack>
							</div>
							<Stack direction="row" spacing={1}>
								{props.buttonsLeft}
							</Stack>
						</Grid>
					</Grid>
				)}
			</Paper>
		</Modal>
	);
});

export default Component;

Component.defaultProps = {
	open: false,
	title: '',
	onClose: null,
	onHelp: null,
	buttonsRight: null,
	buttonsLefts: null,
	size: 'normal',
	footer: "true",
};
