class API {
	constructor(address) {
		this.base = '/api/v1';
		this.address = address;
		this.token = () => {
			return '';
		};

		this.cache = new Map();
	}

	_debug(message) {
		//console.log(`[CoreAPI] ${message}`);
	}

	_error(message) {
		console.error(`[API] Error: ${message}`);
	}

	async _GET(path, options) {
		/*
		const key = path + JSON.stringify(options);

		const data = this.cache.get(key);
		if (data !== undefined) {
			const millis = Date.now() - data.ts;

			if (millis < 950) {
				return data.payload;
			}
		}

		const ts = Date.now();
*/
		const res = await this._call('GET', path, options);
		/*
		this.cache.set(key, {
			payload: res,
			ts: ts,
		});
*/
		return res;
	}

	async _HEAD(path, options) {
		return await this._call('HEAD', path, options);
	}

	async _POST(path, options) {
		return await this._call('POST', path, options);
	}

	async _PUT(path, options) {
		return await this._call('PUT', path, options);
	}

	async _DELETE(path, options) {
		return await this._call('DELETE', path, options);
	}

	async _PATCH(path, options) {
		return await this._call('PATCH', path, options);
	}

	async _call(method, path, options = {}) {
		options = {
			method: method.toUpperCase(),
			expect: 'any',
			headers: {},
			token: '',
			...options,
		};

		path = this.base + path;
		if (path !== '/') {
			// remove slash at the end of the path
			if (path[path.length - 1] === '/') {
				path = path.substring(0, path.length - 1);
			}
		}

		let token = '';

		if (options.token.length !== 0) {
			token = options.token;
		} else {
			token = await this.token();
		}

		if (token.length !== 0) {
			options.headers.Authorization = 'Bearer ' + token;
		}

		this._debug(`calling ${options.method} ${this.address + path}`);

		const res = {
			err: null,
			val: null,
		};

		let response = null;

		try {
			response = await fetch(this.address + path, options);
		} catch (err) {
			res.err = {
				code: -1,
				status: 'NETWORK_ERROR',
				message: err.message,
			};

			this._error(res.err.message);

			return res;
		}

		const contentType = response.headers.get('Content-Type');
		let isJSON = false;

		if (contentType != null) {
			isJSON = contentType.indexOf('application/json') !== -1;
		}

		if (response.ok === false) {
			res.err = {
				code: response.status,
				status: 'UNKNOWN_ERROR',
				message: response.statusText,
				where: '',
				field: '',
			};

			if (isJSON === true) {
				const body = await response.json();

				if ('detail' in body) {
					if (response.status === 422) {
						res.err.status = 'VALIDATION_ERROR';
						res.err.message = body.detail[body.detail.length - 1].msg;
						res.err.where = body.detail[body.detail.length - 1].loc[0];
						res.err.field = body.detail[body.detail.length - 1].loc[1];
					} else {
						res.err.status = body.status;
						res.err.message = body.detail;
					}
				} else {
					res.err.message = body;
				}
			} else {
				const body = await response.text();
				if (body.length > 0) {
					res.err.message = body;
				}
			}

			this._error(res.err.message);

			return res;
		}

		if (isJSON === true) {
			res.val = await response.json();
		} else {
			res.val = await response.text();
		}

		if (options.expect === 'json') {
			if (isJSON === false) {
				res.val = null;
				res.err = {
					code: -2,
					status: 'UNEXPECTED_RESPONSE_TYPE',
					message: `The response is not JSON as expected (${contentType})`,
				};

				this._error(res.err.message);
			}
		}

		return res;
	}

	SetTokenCallback(token) {
		this.token = token;
	}

	async TokenRefresh(token) {
		return await this._PUT('/user/login', {
			expect: 'json',
			token: token,
		});
	}

	// User Management

	async UserLogin(auth0token) {
		return await this._POST('/user/login', {
			expect: 'json',
			token: auth0token,
		});
	}

	async UserGet() {
		return await this._GET('/user', {
			expect: 'json',
		});
	}

	async UserCreate(config) {
		return await this._POST('/user', {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(config),
			expect: 'json',
		});
	}

	async UserDelete() {
		return await this._DELETE('/user', {
			expect: 'json',
		});
	}

	// Group Management

	async Groups() {
		return await this._GET('/group', {
			expect: 'json',
		});
	}

	async GroupCreate(config) {
		return await this._POST('/group', {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(config),
			expect: 'json',
		});
	}

	async GroupGet(id) {
		return await this._GET('/group/' + encodeURIComponent(id), {
			expect: 'json',
		});
	}

	async GroupEdit(id, config) {
		return await this._PUT('/group/' + encodeURIComponent(id), {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(config),
			expect: 'json',
		});
	}

	async GroupDelete(id) {
		return await this._DELETE('/group/' + encodeURIComponent(id), {
			expect: 'json',
		});
	}

	// Group User Management

	async GroupUserEdit(id, config) {
		return await this._PUT('/group/' + encodeURIComponent(id) + '/user', {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(config),
			expect: 'json',
		});
	}

	async GroupUserDelete(id, email) {
		return await this._DELETE('/group/' + encodeURIComponent(id) + '/user', {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email: email,
			}),
			expect: 'json',
		});
	}

	// Group Transfer

	async GroupOwnerTransfer(id, config) {
		return await this._PUT('/group/' + encodeURIComponent(id) + '/transfer', {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(config),
			expect: 'json',
		});
	}

	// Core Management

	async Cores(groupid) {
		return await this._GET('/group/' + encodeURIComponent(groupid) + '/core', {
			expect: 'json',
		});
	}

	async CoreCreate(groupid, config) {
		return await this._POST('/group/' + encodeURIComponent(groupid) + '/core', {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(config),
			expect: 'json',
		});
	}

	async CoreGet(groupid, id) {
		return await this._GET('/group/' + encodeURIComponent(groupid) + '/core/' + encodeURIComponent(id), {
			expect: 'json',
		});
	}

	async CoreEdit(groupid, id, config) {
		return await this._PUT('/group/' + encodeURIComponent(groupid) + '/core/' + encodeURIComponent(id), {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(config),
			expect: 'json',
		});
	}

	async CoreDelete(groupid, id) {
		return await this._DELETE('/group/' + encodeURIComponent(groupid) + '/core/' + encodeURIComponent(id), {
			expect: 'json',
		});
	}

	async CoreMonitor(groupid, query) {
		return await this._PUT('/group/' + encodeURIComponent(groupid) + '/core/monitor', {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(query),
			expect: 'json',
		});
	}

	// Core User Management

	async CoreUserGet(groupid, id) {
		return await this._GET('/group/' + encodeURIComponent(groupid) + '/core/' + encodeURIComponent(id) + '/user', {
			expect: 'json',
		});
	}

	async CoreUserEdit(groupid, id, config) {
		return await this._PUT('/group/' + encodeURIComponent(groupid) + '/core/' + encodeURIComponent(id) + '/user', {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(config),
			expect: 'json',
		});
	}

	async CoreUserDelete(groupid, id, config) {
		return await this._DELETE('/group/' + encodeURIComponent(groupid) + '/core/' + encodeURIComponent(id) + '/user', {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(config),
			expect: 'json',
		});
	}

	// Core API

	async CoreAPI(url, options = {}) {
		options = {
			method: 'GET',
			expect: 'any',
			headers: {},
			...options,
		};

		if (typeof this.token === 'function') {
			options.headers.Authorization = 'Bearer ' + (await this.token());
		} else if (this.token.length !== 0) {
			options.headers.Authorization = 'Bearer ' + this.token;
		}

		const res = {
			err: null,
			val: null,
		};

		let response = null;

		try {
			response = await fetch(url, options);
		} catch (err) {
			res.err = {
				code: 0,
				status: 'CORE_NETWORK_ERROR',
				message: err.message,
			};

			return res;
		}

		const contentType = response.headers.get('Content-Type');
		let isJSON = false;

		if (contentType != null) {
			isJSON = contentType.indexOf('application/json') !== -1;
		}

		if (response.ok === false) {
			res.err = {
				code: response.status,
				status: 'UNKNOWN_ERROR',
				message: response.statusText,
				where: '',
				field: '',
			};

			if (isJSON === true) {
				const body = await response.json();
				res.err.message = body;
			} else {
				const body = await response.text();
				if (body.length > 0) {
					res.err.message = body;
				}
			}

			return res;
		}

		if (isJSON === true) {
			res.val = await response.json();
		} else {
			res.val = await response.text();
		}

		if (options.expect === 'json') {
			if (isJSON === false) {
				res.val = null;
				res.err = {
					code: -2,
					status: 'UNEXPECTED_RESPONSE_TYPE',
					message: `The response is not JSON as expected (${contentType})`,
				};
			}
		}

		return res;
	}

	// Token Management

	async Tokens(groupid) {
		return await this._GET('/group/' + encodeURIComponent(groupid) + '/token', {
			expect: 'json',
		});
	}

	async TokenCreate(groupid, config) {
		return await this._POST('/group/' + encodeURIComponent(groupid) + '/token', {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(config),
			expect: 'json',
		});
	}

	async TokenGet(groupid, id) {
		return await this._GET('/group/' + encodeURIComponent(groupid) + '/token/' + encodeURIComponent(id), {
			expect: 'json',
		});
	}

	async TokenEdit(groupid, id, config) {
		return await this._PUT('/group/' + encodeURIComponent(groupid) + '/token/' + encodeURIComponent(id), {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(config),
			expect: 'json',
		});
	}

	async TokenDelete(groupid, id) {
		return await this._DELETE('/group/' + encodeURIComponent(groupid) + '/token/' + encodeURIComponent(id), {
			expect: 'json',
		});
	}

	// Invitation Management

	async Invitations(groupid) {
		return await this._GET('/group/' + encodeURIComponent(groupid) + '/user/invite', {
			expect: 'json',
		});
	}

	async Invitation(groupid, email) {
		return await this._POST('/group/' + encodeURIComponent(groupid) + '/user/invite', {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email: email }),
			expect: 'json',
		});
	}

	async InvitationRevoke(id) {
		return await this._DELETE('/group/user/invite/' + encodeURIComponent(id), {
			expect: 'json',
		});
	}

	async InvitationVerify(id) {
		return await this._PUT('/group/user/invite/' + encodeURIComponent(id) + '/verify', {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({}),
			expect: 'json',
		});
	}

	async InvitationUpdateEmail(id, email) {
		return await this._PUT('/group/user/invite/' + encodeURIComponent(id), {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email: email }),
			expect: 'json',
		});
	}

	async InvitationResendEmail(id, email) {
		return await this._PUT('/group/user/invite/' + encodeURIComponent(id) + '/resend', {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email: email }),
			expect: 'json',
		});
	}


	// Ticket Management

	async Tickets(groupid) {
		return await this._GET('/group/' + encodeURIComponent(groupid) + '/ticket', {
			expect: 'json',
		});
	}

	async TicketCreate(groupid, config) {
		return await this._POST('/group/' + encodeURIComponent(groupid) + '/ticket', {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(config),
			expect: 'json',
		});
	}

	async TicketGet(groupid, id) {
		return await this._GET('/group/' + encodeURIComponent(groupid) + '/ticket/' + encodeURIComponent(id), {
			expect: 'json',
		});
	}

	async TicketEdit(groupid, id, config) {
		return await this._POST('/group/' + encodeURIComponent(groupid) + '/ticket/' + encodeURIComponent(id), {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(config),
			expect: 'json',
		});
	}

	// Alerts

	async Alerts(groupid) {
		return await this._GET('/group/' + encodeURIComponent(groupid) + '/alert', {
			expect: 'json',
		});
	}

	// Logs

	async Logs(groupid) {
		return await this._GET('/group/' + encodeURIComponent(groupid) + '/log', {
			expect: 'json',
		});
	}
}

export default API;
