import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { useService } from './contexts/Service';
import * as auth0 from './utils/auth0';
import Router from './Router';
import Views from './views';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';

export default function ServiceUI(props) {
	const { service, notify, dispatch, backdrop } = useService();
	const [$state, setState] = React.useState({
		initialized: false,
		connected: false,
		registered: false,
	});
	const [$network, setNetwork] = React.useState(true);

	React.useEffect(() => {
		(async () => {
			await handleMount();
		})();

		return () => {
			handleUnmount();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const raise = (event) => {
		notify(event.severity, event.message);

		if (event.severity === 'error') {
			if (event.type === 'network') {
				setNetwork(false);
			} else if (event.type === 'auth') {
				setState({
					...$state,
					initialized: true,
					connected: false,
					registered: false,
				});
			}
		}
	};

	const handleMount = async () => {
		service.AddListener(raise);

		await auth0.init();
		if (await auth0.isAuthenticated()) {
			let user = await auth0.getUser();
			await service.Login(async () => {
				const token = await auth0.getToken();
				return token;
			}, user);

			dispatch('set_groups', service.Groups());
			dispatch('set_badge', { name: 'invitations', value: service.UserInvitations().length });
		} else {
			const result = await auth0.handleRedirectCallback();
			if (result.initialized === true) {
				if (result.error === true) {
					raise({ severity: 'error', type: 'auth0', message: 'Auth0: ' + result.description });
				}
			}
		}

		setState({
			...$state,
			initialized: true,
			connected: service.IsConnected(),
			registered: service.IsRegistered(),
		});
	};

	const handleUnmount = async () => {};

	const handleAuth0 = async () => {
		backdrop(true);
		const connected = await service.Login(async () => {
			const token = await auth0.getToken();
			return token;
		});
		backdrop(false);

		setState({
			...$state,
			connected: connected,
		});
	};

	const handleRegister = async (code) => {
		const user = await auth0.getUser();

		backdrop(true);
		const registered = await service.Register(
			user.nickname ? user.nickname : user.name,
			user.email ? user.email : '',
			user.picture ? user.picture : '',
			code
		);
		backdrop(false);

		setState({
			...$state,
			registered: registered,
		});

		return registered;
	};

	const handleLogout = async () => {
		setState({
			...$state,
			initialized: false,
			connected: false,
			registered: false,
		});

		service.Logout();

		if (await auth0.isAuthenticated()) {
			await auth0.logout();
		}

		service.Reset();

		setState({
			...$state,
			initialized: true,
			connected: service.IsConnected(),
			registered: service.IsRegistered(),
		});
	};

	if ($state.initialized === false) {
		return null;
	}

	let view = null;

	if ($network === false) {
		view = <Views.Network />;
	} else if ($state.connected === false) {
		view = <Views.Login onAuth0={handleAuth0} />;
	} else if ($state.registered === false) {
		view = <Views.Register onRegister={handleRegister} />;
	}

	if (view !== null) {
		return view;
	}

	return (
		<React.Fragment>
			<Box sx={{ display: 'flex' }}>
				<Sidebar onLogout={handleLogout} />
				<Box component="main" sx={{ flexGrow: 1, overflow: 'hidden', px: 5, paddingLeft: 0, paddingRight: 0 }}>
					<Stack direction="column" justifyContent="flex-start" alignItems="left" spacing={1}>
						<Header onLogout={handleLogout} />
						<Router />
					</Stack>
				</Box>
			</Box>
			<Footer />
		</React.Fragment>
	);
}

/*
<Box component="main" sx={{ flexGrow: 1, px: 5, paddingLeft: 0, paddingRight: 0 }}>
					<Router />
*/
