import React from 'react';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SettingsIcon from '@mui/icons-material/Settings';
import CircularProgress from '@mui/material/CircularProgress';

import { Trans } from '@lingui/macro';

import Dialog from '../../misc/modals/Dialog';
import Duration from '../../misc/Duration';
import Page from '../../misc/Page';
import Table from '../../misc/Table';
import Alert from '../../misc/Alert';
import PageChart from '../../misc/PageChart';

import useInterval from '../../hooks/useInterval';
import { useService } from '../../contexts/Service';

import * as M from '../../utils/metrics';
import * as S from '../../utils/storage';

function setCoreDefaults() {
	return {
		id: '',
		name: '',
		domain_public: '',
		uri_path_api: '/api',
		uri_path_disk: '/',
		uri_path_memfs: '/memfs',
	};
}

const getColor = (percent) => {
	const colors = [
		{
			to: 25,
			color: '#39B54A',
		},
		{
			from: 25,
			to: 50,
			color: '#39B54A',
		},
		{
			from: 50,
			to: 75,
			color: '#39B54A',
		},
		{
			from: 75,
			color: '#D84848',
		},
	];

	for (let i = 0; i < colors.length; i++) {
		let range = {
			from: -Infinity,
			to: Infinity,
			color: '#ffffff',
			...colors[i],
		};

		if (percent > range.from && percent <= range.to) {
			return range.color;
		}
	}

	return '#ffffff';
};

function ErrorTextField(props) {
	let { error, ...other } = props;

	return (
		<React.Fragment>
			<TextField color="secondary" {...other} />
			{error !== null && (
				<Typography color="red" variant="caption">
					{error}
				</Typography>
			)}
		</React.Fragment>
	);
}

export default function Cores(props) {
	const dataProvider = React.useRef();
	const navigate = useNavigate();
	const { groupid } = useParams();
	const { service, dispatch, backdrop } = useService();
	const [$ready, setReady] = React.useState(false);
	const [$error, setError] = React.useState({});
	const [$cores] = React.useState(new Map());
	const [$refresh, setRefresh] = React.useState('');
	const [$addEditCore, setAddEditCore] = React.useState(false);
	const [$core, setCore] = React.useState(setCoreDefaults());
	const [$group, setGroup] = React.useState(null);
	const [$sparks, setSparks] = React.useState({
		session: { data: [], min: 0, max: 0, current: 0, limit: 0, usage: 0, color: '#ffffff' },
		egress: { data: [], min: 0, max: 0, current: 0, limit: 0, usage: 0, color: '#ffffff' },
		ingress: { data: [], min: 0, max: 0, current: 0, limit: 0, usage: 0, color: '#ffffff' },
		running: { data: [], min: 0, max: 0, current: 0, limit: 0, usage: 0, color: '#ffffff' },
		finished: { data: [], min: 0, max: 0, current: 0, limit: 0, usage: 0, color: '#ffffff' },
		killed: { data: [], min: 0, max: 0, current: 0, limit: 0, usage: 0, color: '#ffffff' },
		failed: { data: [], min: 0, max: 0, current: 0, limit: 0, usage: 0, color: '#ffffff' },
	});
	const [$rows, setRows] = React.useState([]);
	const [$coreAlerts, setCoreAlerts] = React.useState([]);
	const [$monitor, setMonitor] = React.useState({
		timerange: S.Get('timerange', 30 * 60),
		interval: S.Get('interval', 10),
		npoints: 0,
	});
	const [$monitorSkipUpdate, setMonitorSkipUpdate] = React.useState(true);

	useInterval(async () => {
		await update();
	}, 15000);

	useInterval(async () => {
		if ($monitorSkipUpdate === true) {
			return;
		}

		await updateMonitor();
	}, $monitor.interval * 1000);

	React.useEffect(() => {
		(async () => {
			await handleMount();
		})();

		return () => {
			handleUnmount();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleMount = async () => {
		await handleRefresh();

		dispatch('select_group', groupid);
		dispatch('set_group_topic', 'cores');

		const npoints = Math.floor($monitor.timerange / $monitor.interval);

		setMonitor({
			...$monitor,
			npoints: npoints,
		});

		setMonitorSkipUpdate(false);

		setReady(true);
	};

	const handleUnmount = async () => {};

	const handleRefresh = async () => {
		backdrop(true);

		const group = await service.Group(groupid);
		if (group === null) {
			navigate('/');
			backdrop(false);
			return;
		}
		setGroup(group);

		dataProvider.current = new M.ServiceDataProvider(async (ids, then, now, interval) => {
			return await service.CoreMonitor(
				groupid,
				ids,
				then,
				now,
				[
					'version',
					'sys_viewer_used',
					'net_tx_used_kbit',
					'processes_running',
					'processes_finished',
					'processes_killed',
					'processes_failed',
					'uptime_seconds',
				],
				interval
			);
		});

		await updateCores();

		setRefresh(Date.now());

		backdrop(false);
	};

	const updateCores = async () => {
		const cores = await service.Cores(groupid);

		const newCoreIDs = [];
		for (let core of cores) {
			let c = $cores.get(core.id);
			if (!c) {
				newCoreIDs.push(core.id);

				// a new core
				core = {
					version: <Trans>n/a</Trans>,
					uptime_seconds: -1,
					last_update: -1,
					...core,
					sparks: null,
					alerts: new Map(),
				};

				$cores.set(core.id, core);
			} else {
				core = {
					...c,
					...core,
					sparks: c.sparks, // keep the sparks data
					about: c.about,
					alerts: c.alerts,
				};

				$cores.set(core.id, core);
			}
		}

		if (newCoreIDs.length !== 0) {
			await initializeCore(newCoreIDs, $cores, $monitor.timerange, $monitor.interval);
			updateAllSparks();
		}

		//setCores(new Map($cores));

		updateRows();
	};

	const update = async () => {
		if ($ready === false) {
			return;
		}

		await updateCores();
	};

	const handleCoreAdd = () => {
		setError({});
		setCore(setCoreDefaults());
		setAddEditCore(true);
	};

	const handleCoreEdit = (id) => () => {
		let core = $cores.get(id);
		if (!core) {
			return;
		}

		setError({});

		setCore({
			...$core,
			id: core.id,
			name: core.name,
			domain_public: core.domain_public,
			uri_path_api: core.uri_path.api,
			uri_path_disk: core.uri_path.disk,
			uri_path_memfs: core.uri_path.memfs,
		});

		setAddEditCore(true);
	};

	const handleCoreAddEditAbort = () => {
		setAddEditCore(false);
	};

	const handleCoreAddEditDone = async () => {
		backdrop(true);

		setError({});

		const config = {
			name: $core.name,
			domain_public: $core.domain_public,
			uri_path: {
				api: $core.uri_path_api,
				disk: $core.uri_path_disk,
				memfs: $core.uri_path_memfs,
			},
		};

		let err = null;
		if ($core.id.length === 0) {
			// add core ...
			err = await service.CoreAdd(groupid, config);
		} else {
			err = await service.CoreEdit(groupid, $core.id, config);
		}

		if (err !== null) {
			if (err.status === 'VALIDATION_ERROR' && err.where === 'body') {
				setError({
					...$error,
					[err.field]: err.message,
				});
			}

			backdrop(false);
			return;
		}

		setAddEditCore(false);

		setCore(setCoreDefaults());

		await updateCores();

		backdrop(false);
	};

	const handleCoreChange = (field) => (event) => {
		const value = event.target.value;

		setCore({
			...$core,
			[field]: value,
		});
	};

	const handleCoreDetails = (id) => () => {
		navigate('/group/' + groupid + '/cores/' + id);
	};

	const initializeCore = async (coreids, cores, timerange, interval) => {
		const monitor = await dataProvider.current.CoreRange(coreids, timerange, interval);

		for (const core of cores.values()) {
			if (!coreids.includes(core.id)) {
				continue;
			}

			let data = monitor[core.id];

			core.uptime_seconds = data.uptime;
			core.last_update = data.last_update;

			core.sparks = initializeCoreSparks(data.metrics);
		}

		return cores;
	};

	const initializeCoreSparks = (metrics) => {
		const sparks = {
			session: { data: metrics.system.viewer_used, min: 0, max: 0, limit: 0, usage: 0, current: 0 },
			egress: { data: metrics.core.net_tx_used_kbit, min: 0, max: 0, limit: 0, usage: 0, current: 0 },
			ingress: { data: metrics.core.net_rx_used_kbit, min: 0, max: 0, limit: 0, usage: 0, current: 0 },
			running: { data: metrics.process.running, min: 0, max: 0, limit: 0, usage: 0, current: 0 },
			finished: { _data: metrics.process.finished, min: 0, max: 0, limit: 0, usage: 0, current: 0 },
			killed: { _data: metrics.process.killed, min: 0, max: 0, limit: 0, usage: 0, current: 0 },
			failed: { _data: metrics.process.failed, min: 0, max: 0, limit: 0, usage: 0, current: 0 },
		};

		return updateCoreSparksData(sparks);
	};

	const updateCoreSparks = (sparks, data) => {
		if ($monitorSkipUpdate === true) {
			return;
		}

		sparks.session.data = M.addSample(sparks.session.data, data.system.viewer_used, $monitor.npoints);
		sparks.egress.data = M.addSample(sparks.egress.data, data.core.net_tx_used_kbit, $monitor.npoints);

		sparks.running.data = M.addSample(sparks.running.data, data.process.running, $monitor.npoints);
		sparks.finished._data = M.addSample(sparks.finished._data, data.process.finished, $monitor.npoints);
		sparks.killed._data = M.addSample(sparks.killed._data, data.process.killed, $monitor.npoints);
		sparks.failed._data = M.addSample(sparks.failed._data, data.process.failed, $monitor.npoints);

		return updateCoreSparksData(sparks);
	};

	const updateCoreSparksData = (sparks) => {
		sparks.session.min = M.min(sparks.session.data);
		sparks.session.max = M.max(sparks.session.data);
		sparks.session.current = M.current(sparks.session.data);
		sparks.session.usage = sparks.session.current;

		sparks.egress.min = M.min(sparks.egress.data);
		sparks.egress.max = M.max(sparks.egress.data);
		sparks.egress.current = M.current(sparks.egress.data);
		sparks.egress.usage = sparks.egress.current;

		sparks.running.min = M.min(sparks.running.data);
		sparks.running.max = M.max(sparks.running.data);
		sparks.running.current = M.current(sparks.running.data);
		sparks.running.usage = sparks.running.current;

		sparks.finished.data = M.diffValues(sparks.finished._data);
		sparks.finished.min = M.min(sparks.finished.data);
		sparks.finished.max = M.max(sparks.finished.data);
		sparks.finished.current = M.current(sparks.finished.data);
		sparks.finished.usage = sparks.finished.current;

		sparks.killed.data = M.diffValues(sparks.killed._data);
		sparks.killed.min = M.min(sparks.killed.data);
		sparks.killed.max = M.max(sparks.killed.data);
		sparks.killed.current = M.current(sparks.killed.data);
		sparks.killed.usage = sparks.killed.current;

		sparks.failed.data = M.diffValues(sparks.failed._data);
		sparks.failed.min = M.min(sparks.failed.data);
		sparks.failed.max = M.max(sparks.failed.data);
		sparks.failed.current = M.current(sparks.failed.data);
		sparks.failed.usage = sparks.failed.current;

		return sparks;
	};

	const updateMonitor = async () => {
		const coreids = Array.from($cores, ([, value]) => value).map((c) => c.id);
		const monitor = await dataProvider.current.CoreCurrent(coreids);

		for (const core of $cores.values()) {
			if (core.sparks === null) {
				continue;
			}

			if (!coreids.includes(core.id)) {
				continue;
			}

			let data = monitor[core.id];

			core.uptime = data.uptime;
			core.last_update = data.last_update;

			core.sparks = updateCoreSparks(core.sparks, data.metrics);
		}

		updateAllSparks();
	};

	const updateAllSparks = async () => {
		const updateSparks = (sparks, coresparks) => {
			sparks = {
				...sparks,
				session: {
					...sparks.session,
					data: coresparks.session.data,
					max: coresparks.session.max,
					current: coresparks.session.current,
					limit: coresparks.session.limit,
					usage: coresparks.session.usage,
					color: coresparks.session.color,
				},
				egress: {
					...sparks.egress,
					data: coresparks.egress.data,
					max: coresparks.egress.max,
					current: coresparks.egress.current,
					limit: coresparks.egress.limit,
					usage: coresparks.egress.usage,
					color: coresparks.egress.color,
				},
				ingress: {
					...sparks.ingress,
					data: coresparks.ingress.data,
					max: coresparks.ingress.max,
					current: coresparks.ingress.current,
					limit: coresparks.ingress.limit,
					usage: coresparks.ingress.usage,
					color: coresparks.ingress.color,
				},
				running: {
					...sparks.running,
					data: coresparks.running.data,
					max: coresparks.running.max,
					current: coresparks.running.current,
					limit: coresparks.running.limit,
					usage: coresparks.running.usage,
					color: coresparks.running.color,
				},
				finished: {
					...sparks.finished,
					data: coresparks.finished.data,
					max: coresparks.finished.max,
					current: coresparks.finished.current,
					limit: coresparks.finished.limit,
					usage: coresparks.finished.usage,
					color: coresparks.finished.color,
				},
				killed: {
					...sparks.killed,
					data: coresparks.killed.data,
					max: coresparks.killed.max,
					current: coresparks.killed.current,
					limit: coresparks.killed.limit,
					usage: coresparks.killed.usage,
					color: coresparks.killed.color,
				},
				failed: {
					...sparks.failed,
					data: coresparks.failed.data,
					max: coresparks.failed.max,
					current: coresparks.failed.current,
					limit: coresparks.failed.limit,
					usage: coresparks.failed.usage,
					color: coresparks.failed.color,
				},
			};

			return sparks;
		};

		const init = (m) => {
			return updateSparks(
				{
					session: {},
					egress: {},
					ingress: {},
					finished: {},
					running: {},
					killed: {},
					failed: {},
				},
				m
			);
		};

		const add = (o1, o2) => {
			o1.data = o1.data.map((v) => [...v]);

			for (let i = 0; i < o1.data.length; i++) {
				if (o1.data[i][0] === 0) {
					o1.data[i][0] = o2.data[i][0];
				}
				o1.data[i][1] += o2.data[i][1];
			}

			o1.max = o1.max > o2.max ? o1.max : o2.max;
			o1.current += o2.current;
			o1.usage += o2.usage;
			o1.limit += o2.limit;

			return o1;
		};

		let first = true;
		let data = null;

		for (const core of $cores.values()) {
			if (core.sparks === null) {
				continue;
			}

			if (first === true) {
				data = init(core.sparks);
				first = false;
				continue;
			}

			data.session = add(data.session, core.sparks.session);
			data.egress = add(data.egress, core.sparks.egress);
			data.ingress = add(data.ingress, core.sparks.ingress);
			data.finished = add(data.finished, core.sparks.finished);
			data.running = add(data.running, core.sparks.running);
			data.killed = add(data.killed, core.sparks.killed);
			data.failed = add(data.failed, core.sparks.failed);
		}

		if (data !== null) {
			const sparks = updateSparks($sparks, data);
			setSparks(sparks);
		}
	};

	const handleCoreAlerts = (id) => (alerts) => {
		let core = $cores.get(id);
		if (!core) {
			return;
		}

		core.alerts = setAlerts(core.alerts, alerts);

		$cores.set(id, core);

		updateRows();
		updateAlerts();
	};

	const setAlerts = (a, alerts) => {
		if (!Array.isArray(alerts)) {
			alerts = [alerts];
		}

		for (let i = 0; i < alerts.length; i++) {
			const alert = a.get(alerts[i].id);

			if (!alert && alerts[i].resolved === false) {
				a.set(alerts[i].id, alerts[i]);
				continue;
			}

			if (alert) {
				if (alerts[i].resolved === true) {
					a.delete(alerts[i].id);
				} else {
					a.set(alerts[i].id, {
						...alert,
						level: alerts[i].level,
						message: alerts[i].message,
					});
				}
			}
		}

		return a;
	};

	const updateRows = () => {
		const rows = Array.from($cores, ([, value]) => value).map((core) => {
			const row = {
				_key: core.id,
				_collapse: '',
				name: core.name,
				name_formatted: (
					<Link
						variant="primary"
						href={`http://restreamer.datarhei.com/?address=${core.domain_public}`}
						sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
						color="inherit"
						target="_blank"
						style={{ width: '100%' }}
					>
						{core.name} <OpenInNewIcon sx={{ ml: 0.5 }} fontSize="inherit" />
					</Link>
				),
				uptime: core.uptime_seconds,
				uptime_formatted: (
					<Typography variant="body2" gutterBottom>
						{core.uptime_seconds < 0 ? <Trans>n/a</Trans> : <Duration seconds={core.uptime_seconds} variant="other" />}
					</Typography>
				),
				last_update: core.last_update,
				last_update_formatted: (
					<Typography variant="body2" gutterBottom>
						{core.last_update < 0 ? (
							<Trans>unknown</Trans>
						) : (
							<React.Fragment>
								{moment.unix(core.last_update).format('YYYY-MM-DD HH:mm:ss')} ({moment.unix(core.last_update).fromNow()})
							</React.Fragment>
						)}
					</Typography>
				),
				alerts: core.alerts.size,
				details: (
					<IconButton variant="text" color="secondary" onClick={handleCoreDetails(core.id)}>
						<SettingsIcon />
					</IconButton>
				),
				color: moment().unix() - core.last_update > 60 ? '#AB4244' : '',
			};

			/*
			if (core.about.state === 'ERROR') {
				const { error, cause } = service.CoreAPIError(core.about.error, core.about.cause);
				row.state = `${error} (${cause})`;
			}
			*/

			return row;
		});

		setRows(rows);
	};

	const updateAlerts = () => {
		const alerts = [];

		for (const core of $cores.values()) {
			for (const a of core.alerts.values()) {
				alerts.push(a);
			}
		}

		alerts.sort((a, b) => {
			return a.date.isBefore(b.date) ? 1 : a.date.isAfter(b.date) ? -1 : 0;
		});

		setCoreAlerts(alerts);
	};

	const handleMonitorChange = (what) => async (event) => {
		const value = event.target.value;

		const monitor = {
			...$monitor,
		};

		if (what === 'timerange') {
			monitor.timerange = parseInt(value);
		} else if (what === 'interval') {
			monitor.interval = parseInt(value);
		}

		S.Set('timerange', monitor.timerange);
		S.Set('interval', monitor.interval);

		monitor.npoints = Math.floor(monitor.timerange / monitor.interval);

		setMonitor({
			...$monitor,
			...monitor,
		});

		setMonitorSkipUpdate(true);

		const coreids = Array.from($cores, ([, value]) => value.id);
		await initializeCore(coreids, $cores, monitor.timerange, monitor.interval);
		updateAllSparks();

		setMonitorSkipUpdate(false);
	};

	const handleHelp = () => {};

	const hasError = (field) => {
		if (field in $error) {
			return $error[field];
		}

		return null;
	};

	const isValidCore = (core) => {
		if (core.name.length === 0) {
			return false;
		}

		if (core.domain_public.length === 0) {
			return false;
		}

		if (core.uri_path_api.length === 0) {
			return false;
		}

		if (core.uri_path_disk.length === 0) {
			return false;
		}

		if (core.uri_path_memfs.length === 0) {
			return false;
		}

		return true;
	};

	if ($ready === false) {
		return null;
	}

	const allowRead = $group.rights.coreRead;
	const allowWrite = $group.rights.coreWrite;

	//if (!allowRead) {
	//	return null;
	//}

	const cells = [
		{
			id: 'name',
			align: 'left',
			disablePadding: true,
			sortable: true,
			label: 'Name',
			field: 'name_formatted',
		},
		{
			id: 'uptime',
			align: 'right',
			disablePadding: true,
			sortable: false,
			label: 'Uptime',
			field: 'uptime_formatted',
		},
		{
			id: 'last_update',
			align: 'right',
			disablePadding: true,
			sortable: false,
			label: 'Last Update',
			field: 'last_update_formatted',
		},
		{
			id: 'alerts',
			align: 'right',
			disablePadding: true,
			sortable: true,
			label: 'Alerts',
			field: 'alerts',
		},
		{
			id: 'details',
			align: 'right',
			disablePadding: true,
			sortable: false,
			label: 'Details',
			field: 'details',
		},
	];

	return (
		<React.Fragment>
			<Page
				breadcrumb={
					<React.Fragment>
						<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
							<Link
								underline="hover"
								sx={{ display: 'flex', alignItems: 'center' }}
								color="inherit"
								component={RouterLink}
								to={`/group/${groupid}/cores`}
							>
								<AccountTreeIcon fontSize="small" style={{ marginRight: 10 }} />
								<Typography>cores</Typography>
							</Link>
						</Breadcrumbs>
					</React.Fragment>
				}
				title={<Trans>Cores</Trans>}
				statsMenu={{
					timerange_value: $monitor.timerange,
					timerange_onchange: handleMonitorChange('timerange'),
					interval_value: $monitor.interval,
					interval_onchange: handleMonitorChange('interval'),
				}}
				onHelp={handleHelp}
				onRefresh={handleRefresh}
				onAdd={handleCoreAdd}
				onAddLabel={<Trans>Add core</Trans>}
				onAddDisabled={!allowWrite}
				headerContent={
					<Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={0}>
						<Grid item xs={12}>
							{$sparks.session.data ? (
								<React.Fragment>
									<Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
										<Grid item xs={12} md={4}>
											<PageChart
												size={3}
												title={<Trans>Viewers</Trans>}
												data={$sparks.session.data}
												min={$sparks.session.min}
												max={$sparks.session.max}
												color={$sparks.session.color}
												current={{ value: $sparks.session.current, digits: 0, unit: '' }}
											/>
										</Grid>
										<Grid item xs={12} md={4}>
											<PageChart
												size={3}
												title={<Trans>Egress</Trans>}
												data={$sparks.egress.data}
												min={$sparks.egress.min}
												max={$sparks.egress.max}
												color={$sparks.egress.color}
												current={{ value: $sparks.egress.current / 1024 / 1024, digits: 2, unit: 'Mbit/s' }}
												formatter={{ factor: 1 / 1024 / 1024 }}
											/>
										</Grid>
										<Grid item xs={12} md={4}>
											<PageChart
												size={3}
												title={<Trans>Ingress</Trans>}
												data={$sparks.ingress.data}
												min={$sparks.ingress.min}
												max={$sparks.ingress.max}
												color={$sparks.ingress.color}
												current={{ value: $sparks.ingress.current / 1024 / 1024, digits: 2, unit: 'Mbit/s' }}
												formatter={{ factor: 1 / 1024 / 1024 }}
											/>
										</Grid>
									</Grid>
									<Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={4} style={{ marginBottom: 10 }}>
										<Grid item xs={12} md={3}>
											<PageChart
												size={4}
												title={<Trans>Process running</Trans>}
												data={$sparks.running.data}
												min={$sparks.running.min}
												max={$sparks.running.max}
												color={$sparks.running.color}
												current={{ value: $sparks.running.current, digits: 0, unit: '' }}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<PageChart
												size={4}
												title={<Trans>Process finished</Trans>}
												data={$sparks.finished.data}
												min={$sparks.finished.min}
												max={$sparks.finished.max}
												color={$sparks.finished.color}
												current={{ value: $sparks.finished.current, digits: 0, unit: '' }}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<PageChart
												size={4}
												title={<Trans>Process killed</Trans>}
												data={$sparks.killed.data}
												min={$sparks.killed.min}
												max={$sparks.killed.max}
												color={$sparks.killed.color}
												current={{ value: $sparks.killed.current, digits: 0, unit: '' }}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<PageChart
												size={4}
												title={<Trans>Process failed</Trans>}
												data={$sparks.failed.data}
												min={$sparks.failed.min}
												max={$sparks.failed.max}
												color={$sparks.failed.color}
												current={{ value: $sparks.failed.current, digits: 0, unit: '' }}
											/>
										</Grid>
									</Grid>
								</React.Fragment>
							) : (
								<Stack direction="column" justifyContent="center" alignItems="center" spacing={5} style={{ margin: '0px 100px 100px 100px' }}>
									<CircularProgress color="inherit" />
								</Stack>
							)}
							<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} style={{ marginBottom: 30 }}>
								<Typography variant="h2" gutterBottom>
									<Trans>Instances</Trans>
								</Typography>
								<Button variant="outlined" size="medium" color="primary" disabled={!allowWrite} onClick={handleCoreAdd}>
									<Trans>+ New</Trans>
								</Button>
							</Stack>
						</Grid>
					</Grid>
				}
				sidebarContent={$coreAlerts.map((alert) => (
					<Alert
						what={alert.what}
						key={alert.core_name + alert.id}
						title={alert.title}
						core_name={alert.core_name}
						message={alert.message}
						level={alert.level}
						date={alert.date.format('YYYY.MM.DD HH:mm:ss')}
						variant="danger"
						link={alert.link}
					/>
				))}
			>
				<Stack direction="column" justifyContent="space-between" alignItems="flex-start" spacing={2}>
					<Table minWidth={0} cells={cells} rows={$rows} order="asc" orderBy="name" rowsPerPage={25} rowsPerPageOptions={[10, 25, 50, 100]}></Table>
					{/* <DataTable cells={cells} rows={$rows} order="asc" rowsPerPage={25} rowsPerPageOptions={[10, 25, 50, 100]} /> */}
				</Stack>
			</Page>
			<Dialog
				open={$addEditCore}
				onClose={handleCoreAddEditAbort}
				title={$core.id.length === 0 ? <Trans>Add core</Trans> : <Trans>Edit core</Trans>}
				buttonsLeft={
					<Button variant="outlined" color="secondary" onClick={handleCoreAddEditAbort}>
						<Trans>Abort</Trans>
					</Button>
				}
				buttonsRight={
					<Button variant="outlined" color="primary" disabled={!isValidCore($core) || !allowWrite} onClick={handleCoreAddEditDone}>
						<Trans>Save</Trans>
					</Button>
				}
			>
				<Grid container spacing={2}>
					{$core.id.length !== 0 && (
						<Grid item xs={12}>
							<TextField variant="outlined" fullWidth label={<Trans>ID</Trans>} value={$core.id} disabled={true} />
						</Grid>
					)}
					<Grid item xs={12}>
						<ErrorTextField
							error={hasError('name')}
							variant="outlined"
							fullWidth
							label={<Trans>Name</Trans>}
							value={$core.name}
							disabled={!allowWrite}
							onChange={handleCoreChange('name')}
						/>
					</Grid>
					<Grid item xs={12}>
						<ErrorTextField
							error={hasError('domain_public')}
							variant="outlined"
							fullWidth
							label={<Trans>Public Domain</Trans>}
							value={$core.domain_public}
							disabled={!allowWrite}
							onChange={handleCoreChange('domain_public')}
						/>
					</Grid>
					<Grid item xs={12}>
						<ErrorTextField
							error={hasError('uri_path_api')}
							variant="outlined"
							fullWidth
							label={<Trans>API Path</Trans>}
							value={$core.uri_path_api}
							disabled={!allowWrite}
							onChange={handleCoreChange('uri_path_api')}
						/>
					</Grid>
					<Grid item xs={12}>
						<ErrorTextField
							error={hasError('uri_path_disk')}
							variant="outlined"
							fullWidth
							label={<Trans>Disk Path</Trans>}
							value={$core.uri_path_disk}
							disabled={!allowWrite}
							onChange={handleCoreChange('uri_path_disk')}
						/>
					</Grid>
					<Grid item xs={12}>
						<ErrorTextField
							error={hasError('uri_path_memfs')}
							variant="outlined"
							fullWidth
							label={<Trans>MemFS Path</Trans>}
							value={$core.uri_path_memfs}
							disabled={!allowWrite}
							onChange={handleCoreChange('uri_path_memfs')}
						/>
					</Grid>
				</Grid>
			</Dialog>
		</React.Fragment>
	);
}

Cores.defaultProps = {};
