/* eslint-disable import/no-anonymous-default-export */
import palette from './palette';
import "@fontsource/roboto/latin-100.css";
import "@fontsource/roboto/latin-300.css";
import "@fontsource/roboto/latin-400.css";

const base = {
	htmlFontSize: 16,
	fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
	fontSize: 12,
	fontWeightLight: 300,
	fontWeightRegular: 400,
	fontWeightMedium: 500,
	fontWeightBold: 700,
	h1: {
		fontSize: '2rem',
		marginBottom: '.25rem',
	},
	h2: {
		fontSize: '1.8rem',
		marginTop: '.25rem',
		marginBottom: '.25rem',
	},
	h3: {
		fontSize: '1.35rem',
		marginTop: '.5em',
	},
	h4: {
		fontSize: '1.2rem',
		marginTop: '.4em',
	},
	h5: {
		fontSize: '1.1rem',
		marginBottom: '.5rem',
	},
	h6: {
		fontSize: '1rem',
		fontWeight: 500,
	},
	stattitel1: {
		fontSize: '4rem',
		fontWeight: 600,
		marginBottom: -20,
	},
	stattitel2: {
		fontSize: '2.5rem',
		fontWeight: 600,
		marginBottom: -5,
	},
	subtitle1: {
		fontSize: '1.2rem',
		marginBottom: '.5rem',
	},
	subtitle2: {
		fontSize: '1.1rem',
		fontWeight: 500,
	},
	body1: {
		fontSize: '1rem',
	},
	body2: {
		fontSize: '.9rem',
		fontWeight: 500,
	},
	button: {
		fontSize: '1rem',
	},
	caption: {
		color: palette.text.hint,
		fontStyle: 'regular',
		marginTop: '.5rem',
		display: 'block',
	},
};

export default {
	...base,
};
