import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import Views from './views';

function Switcher(props) {
	// Here the location pathname is used as key in order to force mounting a new component
	// when the "groupid" changes.
	const location = useLocation();

	return (
		<Routes>
			<Route path="/" element={<Views.Main />} />
			<Route path="/group/:groupid" element={<Views.GroupSettings key={location.pathname} />} />
			<Route path="/group/:groupid/tokens" element={<Views.GroupTokens key={location.pathname} />} />
			<Route path="/group/:groupid/cores" element={<Views.GroupCores key={location.pathname} />} />
			<Route path="/group/:groupid/cores/:coreid" element={<Views.GroupCore key={location.pathname} />} />
			<Route path="/group/:groupid/cores/:coreid/process/:processid" element={<Views.GroupCoreProcess key={location.pathname} />} />
			<Route path="/group/:groupid/alerts" element={<Views.GroupAlerts key={location.pathname} />} />
			<Route path="/group/:groupid/logs" element={<Views.GroupLogs key={location.pathname} />} />
			<Route path="/group/:groupid/tickets" element={<Views.GroupTickets key={location.pathname} />} />
			<Route path="/group/:groupid/tickets/:ticketid" element={<Views.GroupTicket key={location.pathname} />} />
			<Route path="/invitations" element={<Views.Invitations />} />
			<Route path="/invitations/:inviteid" element={<Views.Invitation key={location.pathname} />} />
			<Route path="*" render={() => <Navigate to="/" replace />} />
		</Routes>
	);
}

Switcher.defaultProps = {
	service: null,
};

export default function Router(props) {
	return <Switcher {...props} />;
}

Router.defaultProps = {
	service: null,
};
