import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import { Trans, Plural } from '@lingui/macro';

import Page from '../../misc/Page';
import Select from '../../misc/Select';
import Textarea from '../../misc/Textarea';
import { useService } from '../../contexts/Service';

function Calendar(props) {
	const [$year, setYear] = React.useState(props.year);

	const handleSelectDate = (year, month, day) => () => {
		props.onChange(year, month + 1, day);
	};

	const firstDayOfWeek = props.firstDayOfWeek;
	let now = moment().year($year);

	// Which years are represented?
	const years = [];
	for (let i = 0; i < props.logs.length; i++) {
		let y = moment(props.logs[i].timestamp).year();
		if (!years.includes(y)) {
			years.push(y);
		}
	}

	const currentYear = moment().year();
	const selectedYear = now.year();

	if (!years.includes(currentYear)) {
		years.push(currentYear);
	}

	if (!years.includes(selectedYear)) {
		years.push(selectedYear);
	}

	years.sort((a, b) => a - b);

	let daysInYear = 366;
	if (now.year() !== now.dayOfYear(366).year()) {
		daysInYear = 365;
	}

	now.set('date', 1);
	now.set('month', 0);
	now.set('year', $year);

	const weekdays = [];
	const months = [];

	for (let i = 0; i < 7; i++) {
		weekdays.push(now.day(i).format('ddd'));
	}

	for (let i = 0; i < 12; i++) {
		months.push(now.month(i).format('MMM'));
	}

	const logDays = new Map();
	for (let i = 0; i < props.logs.length; i++) {
		let timestamp = moment(props.logs[i].timestamp);
		if (timestamp.year() !== $year) {
			continue;
		}

		let d = timestamp.dayOfYear();
		let logs = logDays.get(d);
		if (!logs) {
			logs = 0;
		}
		logs++;
		logDays.set(d, logs);
	}
	let totalLogEntries = 0;
	if (logDays.size !== 0) {
		totalLogEntries = [...logDays.values()].reduce((sum, number) => sum + number);
	}

	// Create a box for every day of the selected year and color it based on the
	// number of log entries for that day.

	const days = [];
	const today = moment().dayOfYear();

	const boxSize = {
		width: 15,
		height: 15,
	};

	const boxStyle = {
		...boxSize,
		borderRadius: 1,
	};

	for (let i = 0; i < daysInYear; i++) {
		let logs = logDays.get(i + 1);
		if (!logs) {
			logs = 0;
		}

		let bgcolor = 'darkgray';
		if (logs > 0 && logs < 3) {
			bgcolor = 'palegreen';
		} else if (logs >= 3 && logs < 5) {
			bgcolor = 'lightgreen';
		} else if (logs >= 5 && logs < 8) {
			bgcolor = 'limegreen';
		} else if (logs >= 8) {
			bgcolor = 'green';
		}

		let border = '1px solid gray';
		if (currentYear === selectedYear && today === i + 1) {
			border = '2px solid red';
		}

		now.dayOfYear(i + 1);
		const date = now.format('LL');

		days.push(
			<Box key={i + 1} gridColumn="span 1">
				<Tooltip
					disableFocusListener
					title={<Plural value={logs} _0={`No log entries on ${date}`} one={`# log entry on ${date}`} other={`# log entries on ${date}`} />}
					arrow
					placement="top"
					disableInteractive
				>
					<Box onClick={handleSelectDate(now.year(), now.month(), now.date())} sx={{ ...boxStyle, bgcolor: bgcolor, border: border }}></Box>
				</Tooltip>
			</Box>
		);
	}

	// Add empty boxes in front for the part of the week that is
	// in the previous year.

	const first = now.dayOfYear(1);
	let extra = Math.abs(first.day() - firstDayOfWeek);

	for (let i = 0; i < extra; i++) {
		days.unshift(
			<Box key={`pre${i}`} gridColumn="span 1">
				<Box sx={boxSize}></Box>
			</Box>
		);
	}

	// Add empty boxes at the end for the part of the week that is
	// in the next year.

	extra = 7 - (days.length % 7);

	for (let i = 0; i < extra; i++) {
		days.push(
			<Box key={`post${i}`} gridColumn="span 1">
				<Box sx={boxSize}></Box>
			</Box>
		);
	}

	// For displaying the calendar, transpose the whole list such
	// that there's a row for each weekday. Also add the weekday
	// name as first element of the row.

	const display = [];
	for (let i = 0; i < 7; i++) {
		let weekday = weekdays[(i + firstDayOfWeek) % 7];
		display.push(
			<Box key={weekday} gridColumn="span 1">
				<Box sx={{ fontSize: '0.75em' }}>{weekday}</Box>
			</Box>
		);

		for (let j = 0; j < 53; j++) {
			display.push(days[j * 7 + i]);
		}
	}

	// The last row will contain the names of the months.

	display.push(
		<Box key="filler" gridColumn={`span 1`}>
			<Box></Box>
		</Box>
	);

	let month = 0;
	let span = 4;
	for (let i = 0; i < daysInYear; i++) {
		let m = now.dayOfYear(i + 1).month();
		if (m !== month && now.day() === firstDayOfWeek) {
			display.push(
				<Box key={months[month]} gridColumn={`span ${Math.floor(span / 7)}`}>
					<Box sx={{ fontSize: '0.75em' }}>{months[month]}</Box>
				</Box>
			);
			month = m;
			span = 1;
		} else {
			span++;
		}
	}
	display.push(
		<Box key={months[month]} gridColumn={`span ${Math.floor(span / 7)}`}>
			<Box sx={{ fontSize: '0.75em' }}>{months[month]}</Box>
		</Box>
	);

	const year = $year;

	return (
		<Paper>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<div style={{ float: 'right' }}>
						<Select
							label="Year"
							value={$year}
							fullWidth={false}
							onChange={(event) => {
								setYear(parseInt(event.target.value));
							}}
						>
							{years.map((year) => (
								<MenuItem key={year} value={year}>
									{year}
								</MenuItem>
							))}
						</Select>
					</div>
					<Typography variant="h4">
						<Plural value={totalLogEntries} _0={`No log entries in ${year}`} one={`# log entry in ${year}`} other={`# log entries in ${year}`} />
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Box display="grid" gridTemplateColumns={`repeat(${days.length / 7 + 1}, 1fr)`} gap={1}>
						{display}
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
						<Box sx={{ fontSize: '0.75em' }}>
							<Trans>Today</Trans>
						</Box>
						<Box sx={{ ...boxStyle, bgcolor: 'darkgray', border: '2px solid red' }}></Box>
						<Box sx={boxSize}></Box>
						<Box sx={{ fontSize: '0.75em' }}>
							<Trans>Less</Trans>
						</Box>
						<Box sx={{ ...boxStyle, bgcolor: 'darkgray', border: '1px solid gray' }}></Box>
						<Box sx={{ ...boxStyle, bgcolor: 'palegreen', border: '1px solid gray' }}></Box>
						<Box sx={{ ...boxStyle, bgcolor: 'lightgreen', border: '1px solid gray' }}></Box>
						<Box sx={{ ...boxStyle, bgcolor: 'limegreen', border: '1px solid gray' }}></Box>
						<Box sx={{ ...boxStyle, bgcolor: 'green', border: '1px solid gray' }}></Box>
						<Box sx={{ fontSize: '0.75em' }}>
							<Trans>More</Trans>
						</Box>
					</Stack>
				</Grid>
			</Grid>
		</Paper>
	);
}

Calendar.defaultProps = {
	logs: [],
	year: moment().year(),
	firstDayOfWeek: 1,
};

function LogEntry(props) {
	let code = '';

	if (props.data.api_query.length !== 0) {
		code = JSON.stringify(props.data.api_query[0], null, '    ');
	}

	let rows = (code.match(/\n/g) || []).length + 1;
	if (rows > 10) {
		rows = 10;
	}

	const style = {};

	const endpoint = props.data.api_endpoint.split(' ');

	switch (endpoint[0]) {
		case 'POST':
			style.bgcolor = 'rgba(73, 204, 144, 0.1)';
			style.border = '1px solid rgb(73, 204, 144)';
			style.solid = 'rgb(73, 204, 144)';
			break;
		case 'PUT':
			style.bgcolor = 'rgba(252, 161, 48, 0.1)';
			style.border = '1px solid rgb(252, 161, 48)';
			style.solid = 'rgb(252, 161, 48)';
			break;
		case 'DELETE':
			style.bgcolor = 'rgba(249, 62, 62, 0.1)';
			style.border = '1px solid rgb(249, 62, 62)';
			style.solid = 'rgb(249, 62, 62)';
			break;
		default:
			break;
	}

	return (
		<Paper sx={style}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<div style={{ float: 'right' }}>
						<Typography>
							{props.data.editor_name} &lt;{props.data.editor_email}&gt;
						</Typography>
					</div>
					<Typography>{moment(props.data.timestamp).format()}</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography>
						<span
							style={{
								padding: '5px 1em',
								marginRight: '1em',
								borderRadius: '3px',
								textAlign: 'center',
								backgroundColor: style.solid,
								fontWeight: 'bold',
							}}
						>
							{endpoint[0]}
						</span>
						{endpoint[1]}
					</Typography>
				</Grid>
				{code.length !== 0 && (
					<Grid item xs={12}>
						<Textarea title={<Trans>Query Data</Trans>} readOnly={true} allowCopy={false} rows={rows} value={code} />
					</Grid>
				)}
			</Grid>
		</Paper>
	);
}

LogEntry.defaultProps = {
	data: {},
};

export default function Logs(props) {
	const navigate = useNavigate();
	const { groupid } = useParams();
	const { service, dispatch, backdrop } = useService();
	const [$ready, setReady] = React.useState(false);
	const [$logs, setLogs] = React.useState(null);
	const [$date, setDate] = React.useState(moment().format('YYYY-M-D'));

	React.useEffect(() => {
		(async () => {
			await handleMount();
		})();

		return () => {
			handleUnmount();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleMount = async () => {
		await handleRefresh();

		dispatch('select_group', groupid);
		dispatch('set_group_topic', 'logs');

		setReady(true);
	};

	const handleUnmount = async () => {};

	const handleRefresh = async () => {
		backdrop(true);

		const group = await service.Group(groupid);
		if (group === null) {
			navigate('/');
			backdrop(false);
			return;
		}

		const logs = await service.Logs(groupid);
		if (logs === null) {
			navigate('/group/' + groupid);
			backdrop(false);
			return;
		}
		setLogs(logs);

		backdrop(false);
	};

	const handleCalendar = (year, month, day) => {
		console.log(year, month, day);

		setDate(`${year}-${month}-${day}`);
	};

	const handleHelp = () => {};

	if ($ready === false || $logs === null) {
		return null;
	}

	const logs = $logs
		.slice()
		.map((log) => {
			log.timestamp = moment(log.timestamp);
			return log;
		})
		.sort((a, b) => (a.timestamp.isAfter(b.timestamp) ? 1 : a.timestamp.isBefore(b.timestamp) ? -1 : 0));

	const filteredLogs = logs.filter((log) => log.timestamp.format('YYYY-M-D') === $date);
	const date = moment($date, 'YYYY-M-D').format('LL');

	return (
		<Page breadcrumb={<FormatListBulletedIcon />} title={<Trans>Logs</Trans>} onHelp={handleHelp} onRefresh={handleRefresh}>
			<Stack spacing={2}>
				<Calendar logs={logs} year={moment($date, 'YYYY-M-D').year()} onChange={handleCalendar} />
				<Typography variant="h4">
					<Plural value={filteredLogs.length} _0={`No log entries on ${date}`} one={`# log entry on ${date}`} other={`# log entries on ${date}`} />
				</Typography>
				<Stack spacing={1}>
					{filteredLogs.map((log) => (
						<LogEntry key={log.timestamp} data={log} />
					))}
				</Stack>
			</Stack>
		</Page>
	);
}

Logs.defaultProps = {};
