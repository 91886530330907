import React from 'react';

import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';

import ModalContent from '../ModalContent';
import Textarea from '../Textarea';

const Component = function (props) {
	const { open, title, onClose, onHelp, ...other } = props;

	return (
		<Modal open={props.open} onClose={props.onClose} className="modal">
			<ModalContent title={props.title} onClose={props.onClose} onHelp={props.onHelp}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<div className="modalBox2">
							<Textarea {...other} />
						</div>
					</Grid>
				</Grid>
			</ModalContent>
		</Modal>
	);
};

export default Component;

Component.defaultProps = {
	open: false,
	title: '',
	onClose: null,
	onHelp: null,
};
