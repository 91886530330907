import Login from './Login';
import Register from './Register';
import Network from './Network';
import Main from './Main';
import Group from './Group';
import Invitation from './Invitation';
import Invitations from './Invitations';
import Profile from './Profile';

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
	Login,
	Register,
	Network,
	Main,
	GroupCores: Group.Cores,
	GroupCore: Group.Core,
	GroupCoreProcess: Group.CoreProcess,
	GroupSettings: Group.Settings,
	GroupTokens: Group.Tokens,
	GroupTickets: Group.Tickets,
	GroupTicket: Group.Ticket,
	GroupAlerts: Group.Alerts,
	GroupLogs: Group.Logs,
	Profile,
	Invitation,
	Invitations,
};
