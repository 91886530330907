import React from 'react';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { Trans } from '@lingui/macro';

import Page from '../../misc/Page';
import Textarea from '../../misc/Textarea';
import Dialog from '../../misc/modals/Dialog';
import { useService } from '../../contexts/Service';

function defaultMessage() {
	return {
		message: '',
		process_log: '',
		core_log: '',
		status: 'open',
	};
}

function NewMessage(props) {
	const [$createMessage, setCreateMessage] = React.useState(defaultMessage());
	const [$log, setLog] = React.useState({
		open: false,
		title: '',
		what: '',
	});

	const handleClose = () => {
		props.onClose();

		setCreateMessage({
			...$createMessage,
			...defaultMessage(),
		});
	};

	const handleDone = () => {
		props.onDone($createMessage);

		setCreateMessage({
			...$createMessage,
			...defaultMessage(),
		});
	};

	const handleTicketChange = (what) => (event) => {
		const value = event.target.value;

		setCreateMessage({
			...$createMessage,
			[what]: value,
		});
	};

	const handleLogDialog = (what, title) => () => {
		if (!what) {
			setLog({
				...$log,
				open: false,
			});

			return;
		}

		if (!['process_log', 'core_log'].includes(what)) {
			return;
		}

		setLog({
			...$log,
			open: true,
			what: what,
			title: title,
			value: $createMessage[what],
		});
	};

	const handleLogChange = (event) => {
		const value = event.target.value;

		setLog({
			...$log,
			value: value,
		});
	};

	const handleLogReset = () => {
		setLog({
			...$log,
			value: '',
		});
	};

	const handleLogDialogDone = () => {
		setCreateMessage({
			...$createMessage,
			[$log.what]: $log.value,
		});

		setLog({
			...$log,
			open: false,
		});
	};

	const isValidMessage = (message) => {
		if (message.message.length === 0) {
			return false;
		}

		return true;
	};

	return (
		<React.Fragment>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Textarea
						readOnly={false}
						allowCopy={false}
						variant="outlined"
						fullWidth
						label={<Trans>Message</Trans>}
						rows={10}
						value={$createMessage.message}
						onChange={handleTicketChange('message')}
					/>
				</Grid>
				<Grid item xs={12}>
					<div style={{ float: 'right' }}>
						<Stack direction="row" spacing={1}>
							<Button variant="outlined" color="secondary" onClick={handleClose}>
								<Trans>Close Ticket</Trans>
							</Button>
							<Button variant="outlined" color="primary" disabled={!isValidMessage($createMessage)} onClick={handleDone}>
								<Trans>Comment</Trans>
							</Button>
						</Stack>
					</div>
					<Stack direction="row" spacing={1}>
						{$createMessage.process_log.length === 0 ? (
							<Button
								variant="outlined"
								color="primary"
								onClick={handleLogDialog('process_log', <Trans>Process Log</Trans>)}
								startIcon={<AddIcon />}
							>
								<Trans>Add Process Log</Trans>
							</Button>
						) : (
							<Button
								variant="outlined"
								color="primary"
								onClick={handleLogDialog('process_log', <Trans>Process Log</Trans>)}
								startIcon={<EditIcon />}
							>
								<Trans>Edit Process Log</Trans>
							</Button>
						)}
						{$createMessage.core_log.length === 0 ? (
							<Button variant="outlined" color="primary" onClick={handleLogDialog('core_log', <Trans>Core Log</Trans>)} startIcon={<AddIcon />}>
								<Trans>Add Core Log</Trans>
							</Button>
						) : (
							<Button variant="outlined" color="primary" onClick={handleLogDialog('core_log', <Trans>Core Log</Trans>)} startIcon={<EditIcon />}>
								<Trans>Edit Core Log</Trans>
							</Button>
						)}
					</Stack>
				</Grid>
			</Grid>
			<Dialog
				open={$log.open}
				onClose={handleLogDialog('')}
				title={$log.title}
				buttonsLeft={
					<Button variant="outlined" color="secondary" onClick={handleLogReset}>
						<Trans>Reset</Trans>
					</Button>
				}
				buttonsRight={
					<React.Fragment>
						<Button variant="outlined" onClick={handleLogDialogDone}>
							<Trans>Save</Trans>
						</Button>
						<Button variant="outlined" color="secondary" onClick={handleLogDialog('')}>
							<Trans>Abort</Trans>
						</Button>
					</React.Fragment>
				}
			>
				<Textarea readOnly={false} allowCopy={false} variant="outlined" fullWidth rows={20} value={$log.value} onChange={handleLogChange} />
			</Dialog>
		</React.Fragment>
	);
}

NewMessage.defaultProps = {};

function Message(props) {
	const [$log, setLog] = React.useState({
		open: false,
		title: '',
		value: '',
	});

	const handleLogDialog = (what, title) => () => {
		if (!what) {
			setLog({
				...$log,
				open: false,
			});

			return;
		}

		if (!['process_log', 'core_log'].includes(what)) {
			return;
		}

		setLog({
			...$log,
			open: true,
			what: what,
			title: title,
			value: props.message[what],
		});
	};

	const message = props.message;
	const ts = moment(message.timestamp);

	return (
		<Paper sx={{ bgcolor: message.is_supporter ? 'primary.light' : 'background.paper' }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<div style={{ float: 'right' }}>
						<Typography variant="caption">
							<Trans>
								Date: {ts.format('YYYY-MM-DD HH:mm:ss')} ({ts.fromNow()})
							</Trans>
						</Typography>
					</div>
					<Typography variant="caption">
						<Trans>
							From: {message.user.name} &lt;{message.user.email}&gt;
						</Trans>
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="body1">{message.message}</Typography>
				</Grid>
				<Grid item xs={12}>
					<Stack direction="row" spacing={1}>
						<Button
							variant="outlined"
							color="primary"
							onClick={handleLogDialog('process_log', <Trans>Process Log</Trans>)}
							disabled={!message.process_log}
						>
							<Trans>Show Process Log</Trans>
						</Button>
						<Button variant="outlined" color="primary" onClick={handleLogDialog('core_log', <Trans>Core Log</Trans>)} disabled={!message.core_log}>
							<Trans>Show Core Log</Trans>
						</Button>
					</Stack>
				</Grid>
			</Grid>
			<Dialog
				open={$log.open}
				onClose={handleLogDialog('')}
				title={$log.title}
				buttonsRight={
					<React.Fragment>
						<Button variant="outlined" color="primary" onClick={handleLogDialog('')}>
							<Trans>Close</Trans>
						</Button>
					</React.Fragment>
				}
			>
				<Textarea readOnly={true} allowCopy={true} variant="outlined" fullWidth rows={20} value={$log.value} />
			</Dialog>
		</Paper>
	);
}

export default function Ticket(props) {
	const navigate = useNavigate();
	const { groupid, ticketid } = useParams();
	const { service, dispatch, backdrop } = useService();
	const [$ready, setReady] = React.useState(false);
	const [$group, setGroup] = React.useState(null);
	const [$ticket, setTicket] = React.useState(null);

	React.useEffect(() => {
		(async () => {
			await handleMount();
		})();

		return () => {
			handleUnmount();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleMount = async () => {
		await handleRefresh();

		dispatch('select_group', groupid);
		dispatch('set_group_topic', 'tickets');

		setReady(true);
	};

	const handleUnmount = async () => {};

	const handleRefresh = async () => {
		backdrop(true);

		const group = await service.Group(groupid);
		if (group === null) {
			navigate('/');
			backdrop(false);
			return;
		}
		setGroup(group);

		const ticket = await service.Ticket(groupid, ticketid);
		if (ticket === null) {
			navigate('/group/' + groupid);
			backdrop(false);
			return;
		}
		setTicket(ticket);

		backdrop(false);
	};

	const addMessage = async (message) => {
		const err = await service.TicketAddMessage(groupid, ticketid, message);

		if (err !== null) {
			if (err.status === 'VALIDATION_ERROR' && err.where === 'body') {
				/*
				setError({
					...$error,
					[err.field]: err.message,
				});
				*/
			}

			return;
		}

		const ticket = await service.Ticket(groupid, ticketid);
		setTicket(ticket);

		const nTickets = await service.CountTickets(groupid);
		dispatch('set_badge', { name: 'tickets', value: nTickets });
	};

	const handleCloseTicket = async () => {
		backdrop(true);

		const config = {
			message: 'Closed',
			process_log: '',
			core_log: '',
			ticket_status: 'closed',
		};

		await addMessage(config);

		backdrop(false);
	};

	const handleMessageCreateDone = async (message) => {
		backdrop(true);

		const config = {
			message: message.message,
			process_log: message.process_log,
			core_log: message.core_log,
			ticket_status: message.status,
		};

		await addMessage(config);

		backdrop(false);
	};

	const handleHelp = () => {};

	if ($ready === false || $ticket === null) {
		return null;
	}

	const allowRead = $group.rights.ticketRead;
	const allowWrite = $group.rights.ticketWrite;

	if (!allowRead) {
		return null;
	}

	const messages = $ticket.messages.slice().sort((a, b) => {
		if (a.timestamp < b.timestamp) {
			return -1;
		}
		if (a.timestamp > b.timestamp) {
			return 1;
		}
		// a must be equal to b
		return 0;
	});

	let subtitle = null;

	if ($ticket.topic === 'core') {
		subtitle = (
			<Stack direction="row" alignItems="center" spacing={1}>
				<Trans>Topic: Core</Trans>
				<Link
					underline="hover"
					sx={{ display: 'flex', alignItems: 'center' }}
					color="inherit"
					component={RouterLink}
					to={`/group/${groupid}/cores/${$ticket.item_id}`}
					target="_blank"
				>
					<OpenInNewIcon sx={{ ml: 0.5 }} fontSize="inherit" />
				</Link>
			</Stack>
		);
	} else if ($ticket.topic === 'token') {
		subtitle = (
			<Stack direction="row" alignItems="center" spacing={1}>
				<Trans>Topic: Token</Trans>
				<Link
					underline="hover"
					sx={{ display: 'flex', alignItems: 'center' }}
					color="inherit"
					component={RouterLink}
					to={`/group/${groupid}/tokens`}
					target="_blank"
				>
					<OpenInNewIcon sx={{ ml: 0.5 }} fontSize="inherit" />
				</Link>
			</Stack>
		);
	} else if ($ticket.topic === 'user') {
		subtitle = (
			<Stack direction="row" alignItems="center" spacing={1}>
				<Trans>Topic: User</Trans>
				<Link
					underline="hover"
					sx={{ display: 'flex', alignItems: 'center' }}
					color="inherit"
					component={RouterLink}
					to={`/group/${groupid}`}
					target="_blank"
				>
					<OpenInNewIcon sx={{ ml: 0.5 }} fontSize="inherit" />
				</Link>
			</Stack>
		);
	} else if ($ticket.topic === 'billing') {
		subtitle = (
			<Stack direction="row" alignItems="center" spacing={1}>
				<Trans>Topic: Billing</Trans>
				<Link
					underline="hover"
					sx={{ display: 'flex', alignItems: 'center' }}
					color="inherit"
					component={RouterLink}
					to={`/group/${groupid}`}
					target="_blank"
				>
					<OpenInNewIcon sx={{ ml: 0.5 }} fontSize="inherit" />
				</Link>
			</Stack>
		);
	} else if ($ticket.topic === 'alert') {
		subtitle = (
			<Stack direction="row" alignItems="center" spacing={1}>
				<Trans>Topic: Alert</Trans>
				<Link
					underline="hover"
					sx={{ display: 'flex', alignItems: 'center' }}
					color="inherit"
					component={RouterLink}
					to={`/group/${groupid}/alerts`}
					target="_blank"
				>
					<OpenInNewIcon sx={{ ml: 0.5 }} fontSize="inherit" />
				</Link>
			</Stack>
		);
	}

	return (
		<React.Fragment>
			<Page
				breadcrumb={
					<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
						<Link
							underline="hover"
							sx={{ display: 'flex', alignItems: 'center' }}
							color="inherit"
							component={RouterLink}
							to={`/group/${groupid}/tickets`}
						>
							<MailOutlineIcon sx={{ mr: 0.5 }} fontSize="inherit" />
						</Link>
						<Trans>Ticket</Trans>
					</Breadcrumbs>
				}
				title={$ticket.title}
				subTitle={subtitle}
				onHelp={handleHelp}
				onRefresh={handleRefresh}
			>
				<Stack direction="column" spacing={1}>
					{messages.map((message) => (
						<Message key={message.timestamp} message={message} />
					))}
					{$ticket.ticket_status === 'open' && allowWrite && (
						<Paper elevation={0} sx={{ bgcolor: 'background.transparent' }}>
							<Typography>Write Message</Typography>
							<NewMessage onClose={handleCloseTicket} onDone={handleMessageCreateDone} />
						</Paper>
					)}
				</Stack>
			</Page>
		</React.Fragment>
	);
}

Ticket.defaultProps = {};
