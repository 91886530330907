import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import GroupsIcon from '@mui/icons-material/Groups';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import RadarIcon from '@mui/icons-material/Radar';
import Stack from '@mui/material/Stack';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import Typography from '@mui/material/Typography';

import { useLingui } from '@lingui/react';
import { Trans, t } from '@lingui/macro';

import TabPanel from '../../misc/TabPanel';
import Table from '../../misc/Table';
import Dialog from '../../misc/modals/Dialog';
import Checkbox from '../../misc/Checkbox';
import Page from '../../misc/Page';
import Select from '../../misc/Select';
import { useService } from '../../contexts/Service';

export default function Settings(props) {
	const navigate = useNavigate();
	const { groupid } = useParams();
	const { service, notify, dispatch, backdrop } = useService();
	const { i18n } = useLingui();
	const [$ready, setReady] = React.useState(false);
	const [$group, setGroup] = React.useState(null);
	const [$user, setUser] = React.useState(null);
	const [$users, setUsers] = React.useState([]);
	const [$tab, setTab] = React.useState('users');
	const [$invitation, setInvitation] = React.useState({
		open: false,
		email: '',
	});
	const [$invitations, setInvitations] = React.useState([]);
	const [$invitationRevoke, setInvitationRevoke] = React.useState({
		open: false,
		id: '',
	});
	const [$userDelete, setUserDelete] = React.useState({
		open: false,
		id: '',
	});
	const [$userEdit, setUserEdit] = React.useState({
		open: false,
		id: '',
		roles: [],
		myself: false,
		owner: false,
	});
	const [$userTransfer, setUserTransfer] = React.useState({
		open: false,
		users: [],
		owner: '',
	});

	const tableCellsUsers = [
		{
			id: 'icons',
			align: 'right',
			disablePadding: true,
			sortable: false,
			label: '',
			field: 'icons',
		},
		{
			id: 'name',
			align: 'left',
			disablePadding: false,
			sortable: true,
			label: i18n._(t`Name`),
			field: 'name',
		},
		{
			id: 'email',
			align: 'left',
			disablePadding: false,
			sortable: true,
			label: i18n._(t`Email`),
			field: 'email',
		},
		{
			id: 'actions',
			align: 'right',
			disablePadding: false,
			sortable: false,
			label: '',
			field: 'actions',
		},
	];

	const tableCellsInvites = [
		{
			id: 'email',
			align: 'left',
			disablePadding: false,
			sortable: true,
			label: i18n._(t`Email`),
			field: 'email',
		},
		{
			id: 'email2',
			align: 'left',
			disablePadding: false,
			sortable: true,
			label: i18n._(t`Alternative Email`),
			field: 'email2',
		},
		{
			id: 'created_at',
			align: 'right',
			disablePadding: false,
			sortable: true,
			label: i18n._(t`Created at`),
			field: 'created_at_formatted',
		},
		{
			id: 'actions',
			align: 'right',
			disablePadding: false,
			sortable: false,
			label: '',
			field: 'actions',
		},
	];

	React.useEffect(() => {
		(async () => {
			await handleMount();
		})();

		return () => {
			handleUnmount();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleMount = async () => {
		await handleRefresh();

		setUser(service.User());

		dispatch('select_group', groupid);
		dispatch('set_group_topic', 'settings');

		setReady(true);
	};

	const handleUnmount = async () => {};

	const handleRefresh = async () => {
		backdrop(true);

		const group = await service.Group(groupid);
		if (group === null) {
			navigate('/');
			backdrop(false);
			return;
		}
		setGroup(group);

		const users = await service.GroupUsers(groupid);
		setUsers(users);

		const invitations = await service.Invitations(groupid);
		setInvitations(invitations);

		backdrop(false);
	};

	const handleChangeTab = async (event, value) => {
		if ($tab === value) {
			return;
		}

		setTab(value);
		await handleRefresh();
	};

	const handleInvitationAdd = () => {
		setInvitation({
			...$invitation,
			open: true,
			email: '',
		});
	};

	const handleInvitationEmail = (event) => {
		const email = event.target.value;

		setInvitation({
			...$invitation,
			email: email,
		});
	};

	const handleInvitationAbort = () => {
		setInvitation({
			...$invitation,
			open: false,
		});
	};

	const handleInvitationDone = async () => {
		if ($invitation.email.length === 0) {
			return;
		}

		const users = $users.filter((u) => u.email === $invitation.email);
		if (users.length !== 0) {
			notify('error', i18n._(t`This user is already member of the group`));
			return;
		}

		let invitations = $invitations.filter((u) => u.user_email === $invitation.email);
		if (invitations.length !== 0) {
			service.DispatchEvent('error', 'INVITE_USER_ALREADY_INVITED');
			return;
		}

		backdrop(true);

		const res = await service.Invitation(groupid, $invitation.email);
		if (res === false) {
			backdrop(false);
			return;
		}

		invitations = $invitations.slice();
		invitations.push(res);

		setInvitations(invitations);

		setInvitation({
			...$invitation,
			open: false,
		});

		backdrop(false);
	};

	const handleInvitationRevokeDialog = (id) => () => {
		setInvitationRevoke({
			...$invitationRevoke,
			open: !$invitationRevoke.open,
			id: id,
		});
	};

	const handleInvitationRevoke = async () => {
		backdrop(true);

		const res = await service.InvitationRevoke($invitationRevoke.id);
		if (res === 'INVITE_DELETED') {
			const invitations = $invitations.filter((o) => o.id !== $invitationRevoke.id);
			setInvitations(invitations);
		}

		setInvitationRevoke({
			...$invitationRevoke,
			open: false,
			id: '',
		});

		backdrop(false);
	};

	const handleInvitationUpdateEmail = (id, email) => async () => {
		backdrop(true);

		const res = await service.InvitationUpdateEmail(id, email);
		if (res === true) {
			const invitations = await service.Invitations(groupid);
			setInvitations(invitations);

			const group = await service.Group(groupid);
			setGroup(group);
		}

		backdrop(false);
	};

	const handleInvitationResendEmail = (id, email) => async () => {
		backdrop(true);

		const res = await service.InvitationResendEmail(id, email);
		if (res === true) {
			const invitations = await service.Invitations(groupid);
			setInvitations(invitations);

			const group = await service.Group(groupid);
			setGroup(group);
		}

		backdrop(false);
	};

	const handleUserDeleteDialog = (id) => () => {
		setUserDelete({
			...$userDelete,
			open: !$userDelete.open,
			id: id,
		});
	};

	const handleUserDelete = async () => {
		backdrop(true);

		const res = await service.UserDelete(groupid, $userDelete.id);
		if (res === true) {
			if ($userDelete.id !== $user.email) {
				const users = await service.GroupUsers(groupid);
				setUsers(users);
			} else {
				await service.UpdateUser();
				navigate('/');
				backdrop(false);
				return;
			}
		}

		setUserDelete({
			...$userDelete,
			open: false,
			id: '',
		});

		backdrop(false);
	};

	const handleUserEdit = (id) => () => {
		let user = null;

		for (let i = 0; i < $users.length; i++) {
			if ($users[i].email === id) {
				user = $users[i];
				break;
			}
		}

		if (user === null) {
			return;
		}

		const knownRoles = [
			'group:admin',
			'group:read',
			'alert:read',
			'alert:write',
			'billing:read',
			'billing:write',
			'core:read',
			'core:write',
			'log:read',
			'ticket:read',
			'ticket:write',
			'token:read',
			'token:write',
		];

		const roles = user.roles.filter((role) => knownRoles.includes(role));

		setUserEdit({
			...$userEdit,
			open: true,
			id: user.email,
			roles: roles,
			myself: user.myself,
			owner: user.owner,
		});
	};

	const handleUserEditRoleChange = (role) => () => {
		let roles = $userEdit.roles;
		const i = roles.indexOf(role);
		if (i === -1) {
			roles.push(role);
		} else {
			roles.splice(i, 1);
		}

		setUserEdit({
			...$userEdit,
			roles: roles,
		});

		return;
	};

	const handleUserEditAbort = () => {
		setUserEdit({
			...$userEdit,
			open: false,
			id: '',
		});
	};

	const handleUserEditDone = async () => {
		backdrop(true);

		if (!$userEdit.roles.includes('group:admin')) {
			if (!$userEdit.roles.includes('group:read')) {
				$userEdit.roles.push('group:read');
			}
		} else if (!$userEdit.roles.includes('group:read')) {
			$userEdit.roles.push('group:read');
		}

		const res = await service.UserEdit(groupid, {
			email: $userEdit.id,
			roles: $userEdit.roles,
		});

		if (res === true) {
			const group = await service.GroupUpdate(groupid);
			setGroup(group);

			const users = await service.GroupUsers(groupid);
			setUsers(users);
		}

		setUserEdit({
			...$userEdit,
			open: false,
			id: '',
		});

		backdrop(false);
	};

	const handleUserTransferDialog = (id) => () => {
		if (id.length === 0) {
			setUserTransfer({
				...$userTransfer,
				open: false,
			});

			return;
		}

		let users = $users.filter((user) => user.roles.includes('group:admin') && user.email !== id);

		setUserTransfer({
			...$userTransfer,
			open: true,
			users: users,
			owner: 'none',
		});
	};

	const handleUserTransfer = async () => {
		if ($userTransfer.owner === 'none') {
			return;
		}

		backdrop(true);

		const res = await service.GroupOwnership(groupid, $userTransfer.owner);
		if (res === true) {
			const group = await service.GroupUpdate(groupid);
			setGroup(group);

			const users = await service.GroupUsers(groupid);
			setUsers(users);
		}

		setUserTransfer({
			...$userTransfer,
			open: false,
		});

		backdrop(false);
	};

	const handleUserTransferOwner = (event) => {
		setUserTransfer({
			...$userTransfer,
			owner: event.target.value,
		});
	};

	const handleGroupName = (event) => {
		const value = event.target.value;

		setGroup({
			...$group,
			name: value,
		});
	};

	const handleGroupEditDone = async () => {
		const config = {
			name: $group.name,
		};

		const res = await service.GroupEdit($group.id, config);
		if (res === null) {
			await service.UpdateUser();

			dispatch('set_groups', service.Groups());
		}
	};

	const handleHelp = () => {};

	if ($ready === false) {
		return null;
	}

	const allowRead = $group.rights.groupRead;
	const allowWrite = $group.rights.groupWrite;

	const allowBillingRead = $group.rights.billingRead;
	const allowBillingWrite = $group.rights.billingWrite;

	const allowAlertRead = $group.rights.alertRead;
	const allowAlertWrite = $group.rights.alertWrite;

	const tableRowsUsers = $users.map((user) => {
		let canEdit = allowWrite;
		let canDelete = allowWrite && !user.owner;

		return {
			_key: user.email,
			icons: (
				<React.Fragment>
					{user.owner && <AutoFixHighIcon fontSize="small" />}
					{user.roles.includes('group:admin') && <ManageAccountsIcon fontSize="small" />}
				</React.Fragment>
			),
			name: user.name,
			email: user.email,
			actions: (
				<React.Fragment>
					{user.owner && (
						<IconButton size="small" disabled={!canEdit} onClick={handleUserTransferDialog(user.email)}>
							<TransferWithinAStationIcon fontSize="small" />
						</IconButton>
					)}
					<IconButton size="small" disabled={!canEdit} onClick={handleUserEdit(user.email)}>
						<EditIcon fontSize="small" />
					</IconButton>
					<IconButton size="small" disabled={!canDelete} onClick={handleUserDeleteDialog(user.email)}>
						<DeleteIcon fontSize="small" />
					</IconButton>
				</React.Fragment>
			),
		};
	});

	const tableRowsInvites = $invitations.map((invite) => {
		return {
			_key: invite.user_email,
			email: invite.user_email,
			email2: invite.user_email_changed && allowWrite && (
				<React.Fragment>
					<Typography>{invite.user_email_changed}</Typography>
					<Button variant="outlined" color="primary" onClick={handleInvitationUpdateEmail(invite.id, invite.user_email_changed)}>
						<Trans>Accept</Trans>
					</Button>
				</React.Fragment>
			),
			created_at: invite.last_update,
			created_at_formatted: moment(invite.last_update).fromNow(),
			actions: (
				<React.Fragment>
					<Stack
						direction="row"
						justifyContent="flex-end"
						alignItems="center"
						spacing={2}
					>
						<Button variant="outlined" color="secondary" disabled={!allowWrite} onClick={handleInvitationResendEmail(invite.id)}>
							<Trans>Resend</Trans>
						</Button>
						<Button variant="outlined" color="secondary" disabled={!allowWrite} onClick={handleInvitationRevokeDialog(invite.id)}>
							<Trans>Revoke</Trans>
						</Button>
					</Stack>
				</React.Fragment>
			),
		};
	});

	return (
		<React.Fragment>
			<Page breadcrumb={<GroupsIcon fontSize="normal" />} title={<Trans>Group Settings</Trans>} onHelp={handleHelp} onRefresh={handleRefresh}>
				<AppBar className="appbar" position="static" elevation={0}>
					<Tabs variant="scrollable" scrollButtons={true} value={$tab} onChange={handleChangeTab} className="tabs">
						<Tab className="tab" label={<Trans>General</Trans>} value="settings" disabled={!allowRead} />
						<Tab className="tab" label={<Trans>Users</Trans>} value="users" disabled={!allowRead} />
						<Tab className="tab" label={<Trans>Invitations</Trans>} value="invitations" disabled={!allowRead} />
						<Tab className="tab" label={<Trans>Alerts</Trans>} value="alerts" disabled={!allowAlertRead} />
						<Tab className="tab" label={<Trans>Billing</Trans>} value="billing" disabled={!allowBillingRead} />
					</Tabs>
				</AppBar>
				<TabPanel value={$tab} index="users">
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Table
								order="asc"
								orderBy="name"
								cells={tableCellsUsers}
								rows={tableRowsUsers}
								rowsPerPage={10}
								rowsPerPageOptions={[10, 25, 50]}
							/>
						</Grid>
					</Grid>
				</TabPanel>
				<TabPanel value={$tab} index="alerts">
					<Grid container spacing={2}>
						<Grid item xs={12}>
							nothing
						</Grid>
					</Grid>
				</TabPanel>
				<TabPanel value={$tab} index="billing">
					<Grid container spacing={2}>
						<Grid item xs={12}>
							nothing
						</Grid>
					</Grid>
				</TabPanel>
				<TabPanel value={$tab} index="invitations">
					<Grid container spacing={2}>
						{$invitations.length !== 0 && (
							<Grid item xs={12}>
								<Table
									order="asc"
									orderBy="name"
									cells={tableCellsInvites}
									rows={tableRowsInvites}
									rowsPerPage={10}
									rowsPerPageOptions={[10, 25, 50]}
								/>
							</Grid>
						)}
						<Grid item xs={12}>
							<Button variant="outlined" color="primary" disabled={!allowWrite} onClick={handleInvitationAdd}>
								<Trans>Add Invitiation</Trans>
							</Button>
						</Grid>
					</Grid>
				</TabPanel>
				<TabPanel value={$tab} index="settings">
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								fullWidth
								label={<Trans>Group Name</Trans>}
								disabled={!allowWrite}
								value={$group.name}
								onChange={handleGroupName}
							/>
						</Grid>
						<Grid item xs={12} align="right">
							<Button variant="outlined" color="primary" disabled={!allowWrite || $group.name.length === 0} onClick={handleGroupEditDone}>
								<Trans>Save</Trans>
							</Button>
						</Grid>
					</Grid>
				</TabPanel>
			</Page>
			<Dialog
				open={$invitation.open}
				onClose={handleInvitationAbort}
				title={<Trans>Add invite</Trans>}
				buttonsLeft={
					<Button variant="outlined" color="secondary" onClick={handleInvitationAbort}>
						<Trans>Abort</Trans>
					</Button>
				}
				buttonsRight={
					<Button variant="outlined" color="primary" disabled={!allowWrite || $invitation.email.length === 0} onClick={handleInvitationDone}>
						<Trans>Send Invite</Trans>
					</Button>
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							fullWidth
							label={<Trans>Email Address</Trans>}
							value={$invitation.email}
							disabled={!allowWrite}
							onChange={handleInvitationEmail}
						/>
					</Grid>
				</Grid>
			</Dialog>
			<Dialog
				open={$invitationRevoke.open}
				onClose={handleInvitationRevokeDialog('')}
				title={<Trans>Do you want to delete this invitation?</Trans>}
				buttonsLeft={
					<Button variant="outlined" onClick={handleInvitationRevokeDialog('')}>
						<Trans>Abort</Trans>
					</Button>
				}
				buttonsRight={
					<Button variant="outlined" color="secondary" disabled={!allowWrite} onClick={handleInvitationRevoke}>
						<Trans>Delete</Trans>
					</Button>
				}
			>
				<Typography>
					<Trans>Deleting an invitiation cannot be reversed. The invitee will not be able anymore to join this group.</Trans>
				</Typography>
			</Dialog>
			<Dialog
				open={$userEdit.open}
				onClose={handleUserEditAbort}
				title={<Trans>Edit User</Trans>}
				buttonsLeft={
					<Button variant="outlined" color="secondary" onClick={handleUserEditAbort}>
						<Trans>Abort</Trans>
					</Button>
				}
				buttonsRight={
					<Button variant="outlined" color="primary" disabled={!allowWrite || $userEdit.owner} onClick={handleUserEditDone}>
						<Trans>Save</Trans>
					</Button>
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography>
							<Trans>Service</Trans>
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Checkbox
							label={<Trans>Group Administrator</Trans>}
							checked={$userEdit.roles.includes('group:admin')}
							onChange={handleUserEditRoleChange('group:admin')}
							disabled={!allowWrite || $userEdit.owner}
						/>
					</Grid>
					<Grid item xs={6}>
						<Checkbox
							label={<Trans>Alert Read</Trans>}
							checked={$userEdit.roles.includes('alert:read')}
							onChange={handleUserEditRoleChange('alert:read')}
							disabled={!allowWrite || $userEdit.roles.includes('group:admin') || $userEdit.roles.includes('alert:write')}
						/>
					</Grid>
					<Grid item xs={6}>
						<Checkbox
							label={<Trans>Alert Write</Trans>}
							checked={$userEdit.roles.includes('alert:write')}
							onChange={handleUserEditRoleChange('alert:write')}
							disabled={!allowWrite || $userEdit.roles.includes('group:admin')}
						/>
					</Grid>
					<Grid item xs={6}>
						<Checkbox
							label={<Trans>Billing Read</Trans>}
							checked={$userEdit.roles.includes('billing:read')}
							onChange={handleUserEditRoleChange('billing:read')}
							disabled={!allowWrite || $userEdit.roles.includes('group:admin') || $userEdit.roles.includes('billing:write')}
						/>
					</Grid>
					<Grid item xs={6}>
						<Checkbox
							label={<Trans>Billing Write</Trans>}
							checked={$userEdit.roles.includes('billing:write')}
							onChange={handleUserEditRoleChange('billing:write')}
							disabled={!allowWrite || $userEdit.roles.includes('group:admin')}
						/>
					</Grid>
					<Grid item xs={6}>
						<Checkbox
							label={<Trans>Core Read</Trans>}
							checked={$userEdit.roles.includes('core:read')}
							onChange={handleUserEditRoleChange('core:read')}
							disabled={!allowWrite || $userEdit.roles.includes('group:admin') || $userEdit.roles.includes('core:write')}
						/>
					</Grid>
					<Grid item xs={6}>
						<Checkbox
							label={<Trans>Core Write</Trans>}
							checked={$userEdit.roles.includes('core:write')}
							onChange={handleUserEditRoleChange('core:write')}
							disabled={!allowWrite || $userEdit.roles.includes('group:admin')}
						/>
					</Grid>
					<Grid item xs={6}>
						<Checkbox
							label={<Trans>Ticket Read</Trans>}
							checked={$userEdit.roles.includes('ticket:read')}
							onChange={handleUserEditRoleChange('ticket:read')}
							disabled={!allowWrite || $userEdit.roles.includes('group:admin') || $userEdit.roles.includes('ticket:write')}
						/>
					</Grid>
					<Grid item xs={6}>
						<Checkbox
							label={<Trans>Ticket Write</Trans>}
							checked={$userEdit.roles.includes('ticket:write')}
							onChange={handleUserEditRoleChange('ticket:write')}
							disabled={!allowWrite || $userEdit.roles.includes('group:admin')}
						/>
					</Grid>
					<Grid item xs={6}>
						<Checkbox
							label={<Trans>Token Read</Trans>}
							checked={$userEdit.roles.includes('token:read')}
							onChange={handleUserEditRoleChange('token:read')}
							disabled={!allowWrite || $userEdit.roles.includes('group:admin') || $userEdit.roles.includes('token:write')}
						/>
					</Grid>
					<Grid item xs={6}>
						<Checkbox
							label={<Trans>Token Write</Trans>}
							checked={$userEdit.roles.includes('token:write')}
							onChange={handleUserEditRoleChange('token:write')}
							disabled={!allowWrite || $userEdit.roles.includes('group:admin')}
						/>
					</Grid>
					<Grid item xs={12}>
						<Checkbox
							label={<Trans>Log Read</Trans>}
							checked={$userEdit.roles.includes('log:read')}
							onChange={handleUserEditRoleChange('log:read')}
							disabled={!allowWrite || $userEdit.roles.includes('group:admin')}
						/>
					</Grid>
				</Grid>
			</Dialog>
			<Dialog
				open={$userTransfer.open}
				onClose={handleUserTransferDialog('')}
				title={<Trans>Transfer ownership</Trans>}
				buttonsLeft={
					<Button variant="outlined" onClick={handleUserTransferDialog('')}>
						<Trans>Abort</Trans>
					</Button>
				}
				buttonsRight={
					<Button
						variant="outlined"
						color="secondary"
						disabled={!allowWrite || $userTransfer.users.length === 0 || $userTransfer.owner === 'none'}
						onClick={handleUserTransfer}
					>
						<Trans>Transfer</Trans>
					</Button>
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography>
							<Trans>Transfer the ownership of this group to another group administrator.</Trans>
						</Typography>
					</Grid>
					<Grid item xs={12}>
						{$userTransfer.users.length !== 0 ? (
							<Select label={<Trans>Group Administrator</Trans>} value={$userTransfer.owner} onChange={handleUserTransferOwner}>
								<MenuItem disabled={true} value="none">
									<Trans>Select new owner</Trans>
								</MenuItem>
								{$userTransfer.users.map((user) => (
									<MenuItem key={user.email} value={user.email}>
										{user.name}
									</MenuItem>
								))}
							</Select>
						) : (
							<Typography>
								<Trans>The ownership can only be transferred to another group administrator.</Trans>
							</Typography>
						)}
					</Grid>
				</Grid>
			</Dialog>
			<Dialog
				open={$userDelete.open}
				onClose={handleUserDeleteDialog('')}
				title={
					$userDelete.id === $user.email ? (
						<Trans>Do you want to delete yourself from this group?</Trans>
					) : (
						<Trans>Do you want to delete this user?</Trans>
					)
				}
				buttonsLeft={
					<Button variant="outlined" onClick={handleUserDeleteDialog('')}>
						<Trans>Abort</Trans>
					</Button>
				}
				buttonsRight={
					<Button variant="outlined" color="secondary" disabled={!allowWrite} onClick={handleUserDelete}>
						<Trans>Delete</Trans>
					</Button>
				}
			>
				<Typography>
					{$userDelete.id === $user.email ? (
						<Trans>Deleting yourself from this group cannot be reversed. You will not be able to access this group anymore.</Trans>
					) : (
						<Trans>Deleting this user from this group cannot be reversed. The user will not be able to access this group anymore.</Trans>
					)}
				</Typography>
			</Dialog>
		</React.Fragment>
	);
}

Settings.defaultProps = {};
