import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import { Trans } from '@lingui/macro';

import Paper from '../misc/Paper';
import PaperHeader from '../misc/PaperHeader';
import { useService } from '../contexts/Service';

export default function Invitations(props) {
	const navigate = useNavigate();
	const { service, dispatch, backdrop } = useService();
	const { inviteid } = useParams();
	const [$ready, setReady] = React.useState(false);
	const [$state, setState] = React.useState({
		engaged: false,
		status: '',
		message: '',
	});

	React.useEffect(() => {
		(async () => {
			await handleMount();
		})();

		return () => {
			handleUnmount();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleMount = async () => {
		setReady(true);
	};

	const handleUnmount = async () => {};

	const handleDecline = async () => {
		backdrop(true);

		const res = await service.InvitationRevoke(inviteid);

		setState({
			...$state,
			engaged: true,
			status: res,
			message: props.service.StatusString(res),
		});

		backdrop(false);
	};

	const handleAccept = async () => {
		backdrop(true);

		const res = await service.InvitationVerify(inviteid);

		const state = {
			engaged: true,
			status: res.status,
			message: service.StatusString(res.status),
		};

		if (res.status === 'INVITE_ACCEPTED') {
			await service.UpdateUser();
			dispatch('set_groups', service.Groups());

			state.group_id = res.detail.group_id;
			state.group_name = res.detail.group_name;
		}

		setState({
			...$state,
			...state,
		});

		backdrop(false);
	};

	const handleHelp = () => {};

	if ($ready === false) {
		return null;
	}

	let view = null;

	if ($state.engaged === false) {
		view = (
			<React.Fragment>
				<Grid item xs={6}>
					<Button variant="outlined" color="secondary" fullWidth onClick={handleDecline}>
						<ThumbDownIcon fontSize="small" />
						<Trans>Decline</Trans>
					</Button>
				</Grid>
				<Grid item xs={6}>
					<Button variant="outlined" color="primary" fullWidth onClick={handleAccept}>
						<ThumbUpIcon fontSize="small" />
						<Trans>Accept</Trans>
					</Button>
				</Grid>
			</React.Fragment>
		);
	} else {
		if ('INVITE_ACCEPTED' === $state.status) {
			view = (
				<React.Fragment>
					<Grid item xs={12}>
						{$state.message}
					</Grid>
					<Grid item xs={12} align="center">
						<Button variant="outlined" color="primary" fullWidth onClick={() => navigate('/group/' + $state.group_id)}>
							<Trans>Change to group "{$state.group_name}"</Trans>
						</Button>
					</Grid>
				</React.Fragment>
			);
		} else {
			view = (
				<React.Fragment>
					<Grid item xs={12}>
						{$state.message}
					</Grid>
				</React.Fragment>
			);
		}
	}

	return (
		<React.Fragment>
			<Paper xs={12} md={10}>
				<PaperHeader title={<Trans>Invitation</Trans>} onHelp={handleHelp} />
				<Grid container spacing={2}>
					{view}
				</Grid>
			</Paper>
		</React.Fragment>
	);
}

Invitations.defaultProps = {
	service: null,
};
