import React from 'react';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

export default function Component(props) {
	return (
		<FormControl variant={props.variant} fullWidth={props.fullWidth} size={props.size}>
			<InputLabel>{props.label}</InputLabel>
			<Select
				value={props.value}
				onChange={props.onChange}
				disabled={props.disabled}
				label={props.label}
				startAdornment={props.startAdornment}
			>
				{props.children}
			</Select>
			{props.description && <FormHelperText>{props.description}</FormHelperText>}
		</FormControl>
	);
}

Component.defaultProps = {
	variant: 'outlined',
	fullWidth: true,
	label: '',
	value: '',
	disabled: false,
	size: null,
	description: null,
	startAdornment: null,
	onChange: function (event) {},
};
