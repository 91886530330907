const range = '^0.0.0';

class NoAuth {
	_address = '';

	_about = null;

	constructor(_tokenFn) {
		return;
	}

	async Login(address) {
		this._address = address;

		const res = await this._call(address, {
			expect: 'json',
		});

		if (res.err !== null) {
			return res.err.status;
		}

		const about = {
			app: '',
			id: '',
			auths: [],
			version: {},
			...res.val,
		};

		about.version = {
			number: '0.0.0',
			...about.version,
		};

		if (about.id.length === 0) {
			return 'AUTHORIZATION_FAILED';
		}

		this._about = about;

		return 'OK';
	}

	About() {
		return JSON.parse(JSON.stringify(this._about));
	}

	async Call(path, options) {
		let res = this._call(this._address + path, options);
		if (res.err !== null) {
			return res;
		}

		return res;
	}

	async _call(url, options = {}) {
		options = {
			method: 'GET',
			expect: 'any',
			headers: {},
			token: '',
			...options,
		};

		let token = '';

		if (options.token.length !== 0) {
			token = options.token;
		}

		if (token.length !== 0) {
			options.headers.Authorization = 'Bearer ' + token;
		}

		const res = {
			err: null,
			val: null,
		};

		let response = null;

		try {
			response = await fetch(url, options);
		} catch (err) {
			res.err = {
				code: -1,
				status: 'NETWORK_ERROR',
				message: err.message,
			};

			return res;
		}

		const contentType = response.headers.get('Content-Type');
		let isJSON = false;

		if (contentType != null) {
			isJSON = contentType.indexOf('application/json') !== -1;
		}

		if (response.ok === false) {
			res.err = {
				code: response.status,
				status: 'UNKNOWN_ERROR',
				message: response.statusText,
			};

			if (isJSON === true) {
				const body = await response.json();
				res.err.message = body;
			} else {
				const body = await response.text();
				if (body.length > 0) {
					res.err.message = body;
				}
			}

			return res;
		}

		if (isJSON === true) {
			res.val = await response.json();
		} else {
			res.val = await response.text();
		}

		if (options.expect === 'json') {
			if (isJSON === false) {
				res.val = null;
				res.err = {
					code: -2,
					status: 'UNEXPECTED_RESPONSE_TYPE',
					message: `The response is not JSON as expected (${contentType})`,
				};
			}
		}

		return res;
	}
}

export { range, NoAuth as New };
