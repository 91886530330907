import * as AUTH from './auth';
import * as NOAUTH from './noauth';
import * as CORE_15_0_0 from './core-15.0.0';
import * as CORE_16_0_0 from './core-16.0.0';

import SemverValidRange from 'semver/ranges/valid';
import SemverSatisfies from 'semver/functions/satisfies';
import SemverGt from 'semver/functions/gt';
import SemverMinVersion from 'semver/ranges/min-version';

class Registry {
	_auths;
	_highestAuthRange;
	_highestAuthVersion;

	_cores;
	_highestCoreRange;
	_highestCoreVersion;

	_token;

	constructor() {
		this._auths = new Map();
		this._highestAuthRange = '^0.0.0';
		this._highestAuthVersion = SemverMinVersion(this._highestAuthRange).version;

		this._cores = new Map();
		this._highestCoreRange = '^0.0.0';
		this._highestCoreVersion = SemverMinVersion(this._highestCoreRange).version;

		this._token = () => {
			return '';
		};
	}

	RegisterAuth(auth) {
		if (!SemverValidRange(auth.range)) {
			return;
		}

		this._auths.set(auth.range, auth);

		const currentHighestVersion = SemverMinVersion(this._highestAuthRange);
		const currentVersion = SemverMinVersion(auth.range);

		if (SemverGt(currentVersion, currentHighestVersion)) {
			this._highestAuthRange = auth.range;
			this._highestAuthVersion = currentVersion.version;
		}
	}

	Register(core) {
		if (!SemverValidRange(core.range)) {
			return;
		}

		this._cores.set(core.range, core);

		const currentHighestVersion = SemverMinVersion(this._highestCoreRange);
		const currentVersion = SemverMinVersion(core.range);

		if (SemverGt(currentVersion, currentHighestVersion)) {
			this._highestCoreRange = core.range;
			this._highestCoreVersion = currentVersion.version;
		}
	}

	GetLatestVersion() {
		return this.GetVersion(SemverMinVersion(this._highestCoreRange));
	}

	GetVersion(version) {
		const bestCoreVersion = this._findBestVersion(version, this._cores);
		const core = this._cores.get(bestCoreVersion.key);

		let callFn = (_path, _options) => {
			return {
				val: null,
				err: {
					code: -3,
					status: 'UNKNOWN_ADDRESS',
					message: `Unknown core address`,
				},
			};
		};

		const impl = new core.New(callFn);

		return impl;
	}

	// find a matching API implementation
	async Get(wantid, address) {
		try {
			new URL(address);
		} catch (e) {
			throw new Error('CONNECTION_FAILED', { cause: 'INVALID_ADDRESS' });
		}

		// remove all trailing slashes
		address = address.replace(/\/+$/, '');

		// check what we have
		let [about, probeerr] = await this._probe(address);
		if (probeerr !== null) {
			throw new Error('CONNECTION_FAILED', { cause: probeerr });
		}

		// we expect a version number
		if (about.version.number.length === 0 || about.version.number === '0.0.0') {
			throw new Error('CONNECTION_FAILED', { cause: 'INVALID_API_VERSION' });
		}

		let version = about.version.number;

		let auth = null;

		if (about.id.length === 0) {
			// if the ID is not available, we have to authorize ourselves
			const bestAuthVersion = this._findBestVersion(version, this._auths);
			auth = this._auths.get(bestAuthVersion.key);
		} else {
			// if the ID is available, there's no auth required
			auth = this._auths.get('^0.0.0');
		}

		if (!auth) {
			throw new Error('CONNECTION_FAILED', { cause: 'UNSUPPORTED_API_VERSION', version: version });
		}

		// login to the API
		const authImpl = new auth.New(this._token);
		const res = await authImpl.Login(address);
		if (res !== 'OK') {
			// in case login didn't work out, we get the core implementation
			// based on the known version number, but with a dummy call function.
			const bestCoreVersion = this._findBestVersion(version, this._cores);
			const core = this._cores.get(bestCoreVersion.key);
			if (!core) {
				throw new Error('CONNECTION_FAILED', { cause: 'UNSUPPORTED_API_VERSION', version: version });
			}

			throw new Error('CONNECTION_FAILED', { cause: 'AUTHORIZATION_FAILED', version: version });
		}

		about = authImpl.About();

		// get the best implementation for the actual core version
		const bestCoreVersion = this._findBestVersion(about.version.number, this._cores);
		const core = this._cores.get(bestCoreVersion.key);
		if (!core) {
			throw new Error('CONNECTION_FAILED', { cause: 'UNSUPPORTED_API_VERSION', version: version });
		}

		// if the IDs don't match, return the core with a dummy call function
		if (about.id !== wantid) {
			throw new Error('CONNECTION_FAILED', { cause: `INVALID_ID`, version: about.version.number });
		}

		// use the call function from the auth implementation
		let callFn = async (path, options) => {
			return await authImpl.Call(path, options);
		};

		const impl = new core.New(callFn);

		return impl;
	}

	SetTokenCallback(fn) {
		if (typeof fn !== 'function') {
			return;
		}

		this._token = fn;
	}

	_findBestVersion(version, map) {
		const bestVersion = {
			key: '0.0.0',
			min: '0.0.0',
		};

		// find the API implementation that satisfies the version at hand
		// e.g. we have the implementations for ^8.4.0 and ^8.5.0 and
		// the API returns 8.5.3, then ^8.5.0 should be chosen, even though
		// 8.5.3 satisfies both ^8.4.0 and ^8.5.0. of all matching ranges
		// then one with the highest minVersion has to win.

		for (const range of map.keys()) {
			if (SemverSatisfies(version, range)) {
				const min = SemverMinVersion(range);
				if (SemverGt(min, bestVersion.min)) {
					bestVersion.key = range;
					bestVersion.min = min.version;
				}
			}
		}

		return bestVersion;
	}

	Ranges() {
		return Array.from(this._cores.keys());
	}

	async _probe(address) {
		const res = await this._call(address);
		if (res.err !== null) {
			const location = new URL(address);
			if (res.err.status === 'NETWORK_ERROR') {
				if (window.location.protocol === 'https:' && location.protocol === 'http:') {
					res.err.status = 'MIXED_CONTENT';
				}
			}

			return [null, res.err.status];
		}

		const about = {
			app: '',
			id: '',
			auths: [],
			version: {},
			...res.val,
		};

		about.version = {
			number: '0.0.0',
			...about.version,
		};

		if (about.app !== 'datarhei-core') {
			return [null, 'INVALID_API_RESPONSE'];
		}

		if (about.auths.length !== 0) {
			const auths = about.auths.filter((a) => a.startsWith('auth0'));
			if (auths.length === 0) {
				return [null, 'AUTH0_UNSUPPORTED'];
			}
		}

		return [about, null];
	}

	async _call(url, options = {}) {
		options = {
			method: 'GET',
			expect: 'any',
			headers: {},
			...options,
		};

		const res = {
			err: null,
			val: null,
		};

		let response = null;

		try {
			response = await fetch(url, options);
		} catch (err) {
			res.err = {
				code: -1,
				status: 'NETWORK_ERROR',
				message: err.message,
			};

			return res;
		}

		const contentType = response.headers.get('Content-Type');
		let isJSON = false;

		if (contentType != null) {
			isJSON = contentType.indexOf('application/json') !== -1;
		}

		if (response.ok === false) {
			res.err = {
				code: response.status,
				status: 'UNKNOWN_ERROR',
				message: response.statusText,
			};

			if (isJSON === true) {
				const body = await response.json();
				res.err.message = body;
			} else {
				const body = await response.text();
				if (body.length > 0) {
					res.err.message = body;
				}
			}

			return res;
		}

		if (isJSON === true) {
			res.val = await response.json();
		} else {
			res.val = await response.text();
		}

		if (options.expect === 'json') {
			if (isJSON === false) {
				res.val = null;
				res.err = {
					code: -2,
					status: 'UNEXPECTED_RESPONSE_TYPE',
					message: `The response is not JSON as expected (${contentType})`,
				};
			}
		}

		return res;
	}
}

const registry = new Registry();

registry.RegisterAuth(AUTH);
registry.RegisterAuth(NOAUTH);

registry.Register(CORE_15_0_0);
registry.Register(CORE_16_0_0);

export default registry;
