import React from 'react';

import { makeStyles } from '@mui/styles';
import { Trans } from '@lingui/macro';

import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

import PageChart from './PageChart';

const useStyles = makeStyles((theme) => ({
	pageChart: {
		paddingBottom: -20,
	},
	chip: {
		fontSize: '1rem',
		padding: '0px 0px 1px 0px',
		fontWeight: 400,
		borderRadius: 5,
		backgroundColor: '#333333',
		height: 35,
		color: '#ffffff',
	},
}));

export default function ProcessCollabs(props) {
	const classes = useStyles();

	return (
		<React.Fragment>
			{props.details && props.details.type && (
				<Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
					{props.details.type === 'video' && props.metrics.fps && (
						<Grid item xs={12} md={2} className={classes.pageChart}>
							<PageChart
								size={5}
								title={<Trans>FPS</Trans>}
								data={props.metrics.fps.data}
								min={props.metrics.fps.min}
								max={props.metrics.fps.max}
								color={props.metrics.fps.color}
								current={{ value: props.metrics.fps.current, digits: 1, unit: '' }}
							/>
						</Grid>
					)}
					{props.metrics.bitrate && (
						<Grid item xs={12} md={2} className={classes.pageChart}>
							<PageChart
								size={5}
								title={<Trans>Bitrate</Trans>}
								data={props.metrics.bitrate.data}
								min={props.metrics.bitrate.min}
								max={props.metrics.bitrate.max}
								color={props.metrics.bitrate.color}
								current={{ value: props.metrics.bitrate.current / 1024, digits: 0, unit: 'Kbit/s' }}
								formatter={{ factor: 1 / 1024 }}
							/>
						</Grid>
					)}
					{props.details.type === 'video' && props.metrics.quality && (
						<Grid item xs={12} md={2} className={classes.pageChart}>
							<PageChart
								size={5}
								title={<Trans>Quality</Trans>}
								data={props.metrics.quality.data}
								min={props.metrics.quality.min}
								max={props.metrics.quality.max}
								color={props.metrics.quality.color}
								current={{ value: props.metrics.quality.current, digits: 2, unit: '' }}
							/>
						</Grid>
					)}
					<Grid item xs={12} md={6}>
						<Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
							<Grid item>
								<Chip
									className={classes.chip}
									label={
										<React.Fragment>
											<strong>
												<Trans>Codec</Trans>:
											</strong>{' '}
											{props.details.codec}
										</React.Fragment>
									}
								/>
							</Grid>
							<Grid item>
								<Chip
									className={classes.chip}
									label={
										<React.Fragment>
											<strong>
												<Trans>Coder</Trans>:
											</strong>{' '}
											{props.details.coder}
										</React.Fragment>
									}
								/>
							</Grid>
							<Grid item>
								{props.details.type === 'video' && (
									<Chip
										className={classes.chip}
										label={
											<React.Fragment>
												<strong>
													<Trans>Pixel format</Trans>:
												</strong>{' '}
												{props.details.pix_fmt}
											</React.Fragment>
										}
									/>
								)}
								{props.details.type === 'audio' && (
									<Chip
										className={classes.chip}
										label={
											<React.Fragment>
												<strong>
													<Trans>Layout</Trans>:
												</strong>{' '}
												{props.details.layout}
											</React.Fragment>
										}
									/>
								)}
							</Grid>
							<Grid item>
								{props.details.type === 'video' && (
									<Chip
										className={classes.chip}
										label={
											<React.Fragment>
												<strong>
													<Trans>Resolution</Trans>:
												</strong>{' '}
												{props.details.width}x{props.details.height}
											</React.Fragment>
										}
									/>
								)}
								{props.details.type === 'audio' && (
									<Chip
										className={classes.chip}
										label={
											<React.Fragment>
												<strong>
													<Trans>Sampling Hz</Trans>:
												</strong>{' '}
												{props.details.sampling_hz}
											</React.Fragment>
										}
									/>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</React.Fragment>
	);
}

ProcessCollabs.propTypes = {
	details: null,
	metrics: null,
};
