import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Typography from '@mui/material/Typography';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { useLingui } from '@lingui/react';
import { Trans, t } from '@lingui/macro';

import Textarea from '../../misc/Textarea';
import Dialog from '../../misc/modals/Dialog';
import Table from '../../misc/Table';
import Page from '../../misc/Page';
import Select from '../../misc/Select';
import { useService } from '../../contexts/Service';

function Topic(props) {
	const { service, backdrop } = useService();
	const [$item, setItem] = React.useState(props.item);
	const [$cores, setCores] = React.useState(null);
	const [$tokens, setTokens] = React.useState(null);
	const [$users, setUsers] = React.useState(null);

	const handleTopicChange = async (event) => {
		const topic = event.target.value;

		if (topic === 'core' && $cores === null) {
			backdrop(true);

			const cores = await service.Cores(props.groupid);
			setCores(cores);

			backdrop(false);
		} else if (topic === 'token' && $tokens === null) {
			backdrop(true);

			const tokens = await service.Tokens(props.groupid);
			setTokens(tokens);

			backdrop(false);
		} else if (topic === 'user' && $users === null) {
			backdrop(true);

			const users = await service.GroupUsers(props.groupid);
			setUsers(users);

			backdrop(false);
		}

		const item = {
			...$item,
			topic: topic,
			id: 'none',
		};

		setItem(item);

		props.onChange({
			...item,
			id: '',
		});
	};

	const handleItemChange = (event) => {
		const value = event.target.value;
		const item = {
			...$item,
			id: value,
		};

		setItem(item);

		props.onChange({
			...item,
			id: '',
		});
	};

	return (
		<Stack direction="row" spacing={1}>
			<Select label="Topic" value={$item.topic} fullWidth={false} onChange={handleTopicChange}>
				<MenuItem value="general">
					<Trans>General Issue</Trans>
				</MenuItem>
				<MenuItem value="core">Core Issue</MenuItem>
				<MenuItem value="token">Token Issue</MenuItem>
				<MenuItem value="user">User Issue</MenuItem>
				<MenuItem value="billing">Billing Issue</MenuItem>
				<MenuItem value="alert">Alert Issue</MenuItem>
			</Select>
			{$item.topic === 'core' && (
				<Select label="Core" value={$item.id} fullWidth={false} onChange={handleItemChange}>
					<MenuItem disabled={true} value="none">
						<Trans>Select a core</Trans>
					</MenuItem>
					{$cores.map((core) => (
						<MenuItem key={core.id} value={core.id}>
							{core.name}
						</MenuItem>
					))}
				</Select>
			)}
			{$item.topic === 'token' && (
				<Select label="Token" value={$item.id} fullWidth={false} onChange={handleItemChange}>
					<MenuItem disabled={true} value="none">
						<Trans>Select a token</Trans>
					</MenuItem>
					{$tokens.map((token) => (
						<MenuItem key={token.id} value={token.id}>
							{token.name}
						</MenuItem>
					))}
				</Select>
			)}
			{$item.topic === 'user' && (
				<Select label="User" value={$item.id} fullWidth={false} onChange={handleItemChange}>
					<MenuItem disabled={true} value="none">
						<Trans>Select a user</Trans>
					</MenuItem>
					{$users.map((user) => (
						<MenuItem key={user.email} value={user.email}>
							{user.name} &lt;{user.email}&gt;
						</MenuItem>
					))}
				</Select>
			)}
		</Stack>
	);
}

Topic.defaultProps = {
	item: {
		topic: 'general',
		id: 'none',
	},
	onChange: () => {},
	groupid: '',
};

function defaultTicket() {
	return {
		title: '',
		message: '',
		process_log: '',
		core_log: '',
		item: {
			topic: 'general',
			id: '',
		},
		panic: false,
	};
}

function NewTicket(props) {
	const [$createTicket, setCreateTicket] = React.useState(defaultTicket());
	const [$log, setLog] = React.useState({
		open: false,
		title: '',
		what: '',
	});

	const handleClose = () => {
		props.onClose();

		setCreateTicket({
			...$createTicket,
			...defaultTicket(),
		});
	};

	const handleDone = async () => {
		const ticket = {
			...$createTicket,
		};

		const res = await props.onDone(ticket);

		if (res === true) {
			setCreateTicket({
				...$createTicket,
				...defaultTicket(),
			});
		}
	};

	const handleTicketChange = (what) => (event) => {
		const value = event.target.value;

		setCreateTicket({
			...$createTicket,
			[what]: value,
		});
	};

	const handleLogDialog = (what, title) => () => {
		if (!what) {
			setLog({
				...$log,
				open: false,
			});

			return;
		}

		if (!['process_log', 'core_log'].includes(what)) {
			return;
		}

		setLog({
			...$log,
			open: true,
			what: what,
			title: title,
			value: $createTicket[what],
		});
	};

	const handleLogChange = (event) => {
		const value = event.target.value;

		setLog({
			...$log,
			value: value,
		});
	};

	const handleLogReset = () => {
		setLog({
			...$log,
			value: '',
		});
	};

	const handleLogDialogDone = () => {
		setCreateTicket({
			...$createTicket,
			[$log.what]: $log.value,
		});

		setLog({
			...$log,
			open: false,
		});
	};

	const handleItemChange = (item) => {
		setCreateTicket({
			...$createTicket,
			item: item,
		});
	};

	const handlePanicChange = () => {
		setCreateTicket({
			...$createTicket,
			panic: !$createTicket.panic,
		});
	};

	const isValidTicket = (ticket) => {
		if (ticket.title.length === 0) {
			return false;
		}

		if (ticket.message.length === 0) {
			return false;
		}

		return true;
	};

	return (
		<React.Fragment>
			<Dialog
				open={props.open}
				size="large"
				onClose={handleClose}
				title={<Trans>Create ticket</Trans>}
				buttonsLeft={
					<Button variant="outlined" color="secondary" onClick={handleClose}>
						<Trans>Abort</Trans>
					</Button>
				}
				buttonsRight={
					<Stack direction="row" spacing={1}>
						<FormControlLabel
							disabled={!props.allowPanic}
							control={<Switch checked={$createTicket.panic} onChange={handlePanicChange} color="secondary" />}
							label={<Trans>PANIC MODE</Trans>}
						/>
						<Button variant="outlined" color="primary" disabled={!isValidTicket($createTicket)} onClick={handleDone}>
							<Trans>Create</Trans>
						</Button>
					</Stack>
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							fullWidth
							label={<Trans>Subject</Trans>}
							value={$createTicket.title}
							onChange={handleTicketChange('title')}
						/>
					</Grid>
					<Grid item xs={12}>
						<Topic groupid={props.groupid} value={$createTicket.item} onChange={handleItemChange} />
					</Grid>
					<Grid item xs={12}>
						<Textarea
							readOnly={false}
							allowCopy={false}
							variant="outlined"
							fullWidth
							label={<Trans>Message</Trans>}
							rows={10}
							value={$createTicket.message}
							onChange={handleTicketChange('message')}
						/>
					</Grid>
					<Grid item xs={12}>
						<Stack direction="row" spacing={2}>
							{$createTicket.process_log.length === 0 ? (
								<Button
									variant="outlined"
									color="primary"
									onClick={handleLogDialog('process_log', <Trans>Process Log</Trans>)}
									startIcon={<AddIcon />}
								>
									<Trans>Add Process Log</Trans>
								</Button>
							) : (
								<Button
									variant="outlined"
									color="primary"
									onClick={handleLogDialog('process_log', <Trans>Process Log</Trans>)}
									startIcon={<EditIcon />}
								>
									<Trans>Edit Process Log</Trans>
								</Button>
							)}
							{$createTicket.core_log.length === 0 ? (
								<Button
									variant="outlined"
									color="primary"
									onClick={handleLogDialog('core_log', <Trans>Core Log</Trans>)}
									startIcon={<AddIcon />}
								>
									<Trans>Add Core Log</Trans>
								</Button>
							) : (
								<Button
									variant="outlined"
									color="primary"
									onClick={handleLogDialog('core_log', <Trans>Core Log</Trans>)}
									startIcon={<EditIcon />}
								>
									<Trans>Edit Core Log</Trans>
								</Button>
							)}
						</Stack>
					</Grid>
				</Grid>
			</Dialog>
			<Dialog
				open={$log.open}
				onClose={handleLogDialog('')}
				title={$log.title}
				buttonsLeft={
					<Button variant="outlined" color="secondary" onClick={handleLogReset}>
						<Trans>Reset</Trans>
					</Button>
				}
				buttonsRight={
					<Stack direction="row" spacing={1}>
						<Button variant="outlined" color="secondary" onClick={handleLogDialog('')}>
							<Trans>Abort</Trans>
						</Button>
						<Button variant="outlined" onClick={handleLogDialogDone}>
							<Trans>Save</Trans>
						</Button>
					</Stack>
				}
			>
				<Textarea readOnly={false} allowCopy={false} variant="outlined" fullWidth rows={20} value={$log.value} onChange={handleLogChange} />
			</Dialog>
		</React.Fragment>
	);
}

NewTicket.defaultProps = {
	open: false,
	allowPanic: false,
	onClose: () => {},
	onDone: () => {},
	groupid: 'none',
};

function ErrorTextArea(props) {
	let { error, ...other } = props;

	return (
		<React.Fragment>
			<Textarea color="secondary" {...other} />
			{error !== null && (
				<Typography color="red" variant="caption">
					{error}
				</Typography>
			)}
		</React.Fragment>
	);
}

function ErrorTextField(props) {
	let { error, ...other } = props;

	return (
		<React.Fragment>
			<TextField color="secondary" {...other} />
			{error !== null && (
				<Typography color="red" variant="caption">
					{error}
				</Typography>
			)}
		</React.Fragment>
	);
}

export default function Tickets(props) {
	const navigate = useNavigate();
	const { groupid } = useParams();
	const { service, dispatch, backdrop } = useService();
	const { i18n } = useLingui();
	const [$ready, setReady] = React.useState(false);
	const [$error, setError] = React.useState({});
	const [$tickets, setTickets] = React.useState(null);
	const [$createTicket, setCreateTicket] = React.useState(false);
	const [$group, setGroup] = React.useState(null);

	const tableCells = [
		{
			id: 'title',
			align: 'left',
			disablePadding: false,
			sortable: true,
			label: i18n._(t`Title`),
			field: 'title',
		},
		{
			id: 'timestamp_create',
			align: 'right',
			disablePadding: false,
			sortable: true,
			label: i18n._(t`Created`),
			field: 'timestamp_create_formatted',
		},
		{
			id: 'timestamp_last',
			align: 'right',
			disablePadding: false,
			sortable: true,
			label: i18n._(t`Last Message`),
			field: 'timestamp_last_formatted',
		},
		{
			id: 'messages',
			align: 'right',
			disablePadding: false,
			sortable: true,
			label: i18n._(t`Messages`),
			field: 'messages',
		},
		{
			id: 'topic',
			align: 'right',
			disablePadding: false,
			sortable: false,
			label: i18n._(t`Topic`),
			field: 'topic',
		},
		{
			id: 'status',
			align: 'right',
			disablePadding: false,
			sortable: false,
			label: i18n._(t`Status`),
			field: 'status',
		},
		{
			id: 'actions',
			align: 'right',
			disablePadding: false,
			sortable: false,
			label: '',
			field: 'actions',
		},
	];

	React.useEffect(() => {
		(async () => {
			await handleMount();
		})();

		return () => {
			handleUnmount();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleMount = async () => {
		await handleRefresh();

		dispatch('select_group', groupid);
		dispatch('set_group_topic', 'tickets');

		setReady(true);
	};

	const handleUnmount = async () => {};

	const handleRefresh = async () => {
		backdrop(true);

		const group = await service.Group(groupid);
		if (group === null) {
			navigate('/');
			backdrop(false);
			return;
		}
		setGroup(group);

		const tickets = await service.Tickets(groupid);
		setTickets(tickets);

		backdrop(false);
	};

	const handleTicketOpen = (id) => () => {
		navigate('/group/' + groupid + '/tickets/' + id);
	};

	const handleTicketCreate = () => {
		setError({});
		setCreateTicket(true);
	};

	const handleTicketCreateAbort = () => {
		setCreateTicket(false);
	};

	const handleTicketCreateDone = async (ticket) => {
		backdrop(true);

		setError({});

		const config = {
			title: ticket.title,
			message: ticket.message,
			process_log: ticket.process_log,
			core_log: ticket.core_log,
			topic: ticket.item.topic,
			item_id: ticket.item.id.length === 0 ? null : ticket.item.id,
			panic: false,
			follower: [service.User().email],
		};

		const err = await service.TicketCreate(groupid, config);
		if (err !== null) {
			if (err.status === 'VALIDATION_ERROR' && err.where === 'body') {
				setError({
					...$error,
					[err.field]: err.message,
				});
			}

			backdrop(false);

			return false;
		}

		setCreateTicket(false);

		let tickets = await service.Tickets(groupid);
		setTickets(tickets);

		const nTickets = await service.CountTickets(groupid, tickets);
		dispatch('set_badge', { name: 'tickets', value: nTickets });

		backdrop(false);

		return true;
	};

	const handleHelp = () => {};

	const hasError = (field) => {
		if (field in $error) {
			return $error[field];
		}

		return null;
	};

	if ($ready === false) {
		return null;
	}

	const allowRead = $group.rights.ticketRead;
	const allowWrite = $group.rights.ticketWrite;
	const allowPanic = $group.rights.ticketPanic;

	let tableRows = [];

	if (allowRead) {
		tableRows = $tickets.map((ticket) => {
			return {
				_key: ticket.id,
				title: ticket.title,
				timestamp_create: moment(ticket.messages[0].timestamp).format('YYYY-MM-DD HH:mm:ss'),
				timestamp_create_formatted: moment(ticket.messages[0].timestamp).format('YYYY-MM-DD HH:mm:ss'),
				timestamp_last: moment(ticket.messages[ticket.messages.length - 1].timestamp).format('YYYY-MM-DD HH:mm:ss'),
				timestamp_last_formatted: moment(ticket.messages[ticket.messages.length - 1].timestamp).fromNow(),
				messages: ticket.messages.length,
				topic: ticket.topic ? ticket.topic : '-',
				status: ticket.ticket_status,
				actions: (
					<React.Fragment>
						<IconButton size="small" disabled={!allowRead} onClick={handleTicketOpen(ticket.id)}>
							<VisibilityIcon fontSize="small" />
						</IconButton>
					</React.Fragment>
				),
			};
		});
	}

	return (
		<React.Fragment>
			<Page
				breadcrumb={<MailOutlineIcon />}
				title={<Trans>Support Tickets</Trans>}
				onHelp={handleHelp}
				onRefresh={handleRefresh}
				onAdd={handleTicketCreate}
				onAddLabel={<Trans>Create</Trans>}
				onAddDisabled={!allowWrite}
			>
				{$tickets.length !== 0 ? (
					<Table order="asc" orderBy="timestamp_create" cells={tableCells} rows={tableRows} rowsPerPage={10} rowsPerPageOptions={[10, 25, 50]} />
				) : (
					<Button variant="outlined" color="primary" disabled={!allowWrite} onClick={handleTicketCreate}>
						<Trans>Create Ticket</Trans>
					</Button>
				)}
			</Page>
			<NewTicket open={$createTicket} allowPanic={allowPanic} onClose={handleTicketCreateAbort} onDone={handleTicketCreateDone} groupid={groupid} />
		</React.Fragment>
	);
}

Tickets.defaultProps = {};
