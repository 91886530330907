class Base {
	constructor(callFn) {
		this._about = null;
		this._callFn = callFn;
	}

	async About() {
		return {};
	}

	async ID() {
		return '';
	}

	async Version() {
		return '';
	}

	async Monitor() {
		return null;
	}

	async ProcessMonitor(id) {
		return null;
	}

	async ProcessReport(id) {
		return null;
	}

	async Settings(style, id, name, secret, tenant) {
		return '';
	}
}

export default Base;
