import * as React from 'react';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import ArticleIcon from '@mui/icons-material/Article';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import Backdrop from '@mui/material/Backdrop';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';

import { Trans } from '@lingui/macro';

import Duration from '../../../misc/Duration';
import Page from '../../../misc/Page';
import PageChart from '../../../misc/PageChart';
import Textarea from '../../../misc/Textarea';
import Alert from '../../../misc/Alert';
import useInterval from '../../../hooks/useInterval';
import { useService } from '../../../contexts/Service';
import Dialog from '../../../misc/modals/Dialog';
import Table from '../../../misc/Table';
import Paper from '../../../misc/Paper';
import PaperHeader from '../../../misc/PaperHeader';
import PaperContent from '../../../misc/PaperContent';
import PaperFooter from '../../../misc/PaperFooter';
import Select from '../../../misc/Select';
import ProcessCollapse from '../../../misc/ProcessCollapse';

import * as M from '../../../utils/metrics';
import * as S from '../../../utils/storage';

export default function CoreProcess(props) {
	const navigate = useNavigate();
	const { service, dispatch, backdrop } = useService();
	const { groupid, coreid, processid } = useParams();
	const [$ready, setReady] = React.useState(false);
	const [$core, setCore] = React.useState(null);
	const [$coreAPI, setCoreAPI] = React.useState(null);
	const [$coreAPIError, setCoreAPIError] = React.useState(null);
	const [$coreAbout, setCoreAbout] = React.useState({
		id: '',
	});
	const [$coreProcess, setCoreProcess] = React.useState(null);
	const [$coreAlerts, setCoreAlerts] = React.useState(new Map());
	const [$coreReport, setCoreReport] = React.useState({
		prelude: [],
		log: [],
		history: [],
	});
	const [$coreReportDialog, setCoreReportDialog] = React.useState({
		history: 'latest',
		data: 'log',
		open: false,
	});
	const [$sparks, setSparks] = React.useState({
		cpu: { min: 0, max: 100, data: [], color: '#ffffff', current: 0, limit: 0, usage: 0 },
		mem: { min: 0, max: 100, data: [], color: '#ffffff', current: 0, limit: 0, usage: 0 },
		fps: { min: 0, max: 0, data: [], color: '#ffffff', current: 0, limit: 0, usage: 0 },
		bitrate: { min: 0, max: 0, data: [], color: '#ffffff', current: 0, limit: 0, usage: 0 },
		quality: { min: -1, max: 100, data: [], color: '#ffffff', current: 0, limit: 0, usage: 0 },
		speed: { min: 0, max: 0, data: [], color: '#ffffff', current: 0, limit: 0, usage: 0 },
		drop: { min: 0, max: 0, _data: [], color: '#ffffff', current: 0, limit: 0, usage: 0 },
		dup: { min: 0, max: 0, _data: [], color: '#ffffff', current: 0, limit: 0, usage: 0 },
		io: {},
	});
	const [$monitor, setMonitor] = React.useState({
		timerange: S.Get('timerange', 5 * 60),
		interval: S.Get('interval', 5),
		npoints: 0,
	});
	const [$monitorSkipUpdate, setMonitorSkipUpdate] = React.useState(true);
	const [$ffCmd, setFfCmd] = React.useState({
		open: false,
		data: [],
	});

	useInterval(async () => {
		if ($coreAPI === null) {
			return;
		}

		if ($monitorSkipUpdate === true) {
			return;
		}

		await updateMonitor();
	}, $monitor.interval * 1000);

	React.useEffect(() => {
		(async () => {
			await handleMount();
		})();

		return () => {
			handleUnmount();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleMount = async () => {
		setReady(false);

		backdrop(true);

		const core = await handleRefresh();

		dispatch('select_group', groupid);
		dispatch('set_group_topic', 'cores');

		if (core === null) {
			backdrop(false);
			return;
		}

		// test if we can directly access the core
		const [coreAPI, err, cause] = await service.CoreAPI(coreid, core.domain_public + core.uri_path.api);
		if (err !== null) {
			setCoreAPIError(service.CoreAPIError(err, cause));
		} else {
			setCoreAPIError(null);
		}

		setCoreAPI(coreAPI);

		const npoints = Math.floor($monitor.timerange / $monitor.interval);

		setMonitor({
			...$monitor,
			npoints: npoints,
		});

		if (err === null) {
			const monitor = await coreAPI.ProcessMonitor(processid, $monitor.timerange, $monitor.interval);
			if (monitor !== null) {
				setCoreAbout({
					...$coreAbout,
					...monitor.about,
					ncpu: monitor.resources.cpu_ncores,
				});

				setCoreProcess(monitor.process);

				updateSparks($sparks, monitor.resources, npoints);

				updateAlerts(monitor.resources);

				setMonitorSkipUpdate(false);
			} else {
				setCoreAPIError({ error: 'Failed to fetch monitor data', cause: 'Either the core is not reachable or the process does not exists' });

				const about = await coreAPI.About();
				setCoreAbout({
					...$coreAbout,
					...about,
				});
			}
		}

		backdrop(false);

		setReady(true);
	};

	const handleUnmount = async () => {};

	const handleRefresh = async () => {
		backdrop(true);

		const group = await service.Group(groupid);
		if (group === null) {
			navigate('/');
			backdrop(false);
			return null;
		}

		const core = await service.Core(groupid, coreid);
		if (core === null) {
			navigate('/group/' + groupid);
			backdrop(false);
			return null;
		}

		setCore(core);

		backdrop(false);

		return core;
	};

	const updateMonitor = async () => {
		// get the current process data
		const monitor = await $coreAPI.ProcessMonitor(processid, 0);
		if ($monitorSkipUpdate === true) {
			return;
		}

		if (monitor === null) {
			setAlerts({
				resolved: false,
				id: 'CONNECTION_FAILED',
				title: 'Connection failed',
				core_name: $coreAbout.name,
				level: 'error',
				message: `Fetching monitoring data failed`,
				date: moment(),
			});

			updateSparks($sparks, null, $monitor.npoints);

			updateAlerts(null);

			return;
		}

		setAlerts({
			resolved: true,
			id: 'CONNECTION_FAILED',
		});

		setCoreProcess(monitor.process);

		updateSparks($sparks, monitor.resources, $monitor.npoints);

		updateAlerts(monitor.resources);
	};

	const updateSparks = (sparks, resources, npoints, reset) => {
		if (reset) {
			sparks = {
				...sparks,
				cpu: { ...sparks.cpu, data: M.addSample([], [[0, null]], npoints), current: 0, usage: 0 },
				mem: { ...sparks.mem, data: M.addSample([], [[0, null]], npoints), current: 0, usage: 0 },
				fps: { ...sparks.fps, data: M.addSample([], [[0, null]], npoints), current: 0, usage: 0 },
				bitrate: { ...sparks.bitrate, data: M.addSample([], [[0, null]], npoints), current: 0, usage: 0 },
				quality: { ...sparks.quality, data: M.addSample([], [[0, null]], npoints), current: 0, usage: 0 },
				speed: { ...sparks.speed, data: M.addSample([], [[0, null]], npoints), current: 0, usage: 0 },
				drop: { ...sparks.drop, _data: M.addSample([], [[0, null]], npoints), current: 0, usage: 0 },
				dup: { ...sparks.dup, _data: M.addSample([], [[0, null]], npoints), current: 0, usage: 0 },
			};

			for (let id in sparks.io) {
				for (let name in sparks.io[id]) {
					sparks.io[id][name] = { ...sparks.io[id][name], data: M.addSample([], [[0, null]], npoints), current: 0, usage: 0 };
				}
			}
		}

		if (resources === null) {
			const now = moment().unix();
			sparks = {
				...sparks,
				cpu: { ...sparks.cpu, data: M.addSample(sparks.cpu.data, [[now, null]], npoints), current: 0, usage: 0 },
				mem: { ...sparks.mem, data: M.addSample(sparks.mem.data, [[now, null]], npoints), current: 0, usage: 0 },
				fps: { ...sparks.fps, data: M.addSample(sparks.fps.data, [[now, null]], npoints), current: 0, usage: 0 },
				bitrate: { ...sparks.bitrate, data: M.addSample(sparks.bitrate.data, [[now, null]], npoints), current: 0, usage: 0 },
				quality: { ...sparks.quality, data: M.addSample(sparks.quality.data, [[now, null]], npoints), current: 0, usage: 0 },
				speed: { ...sparks.speed, data: M.addSample(sparks.speed.data, [[now, null]], npoints), current: 0, usage: 0 },
				drop: { ...sparks.drop, _data: M.addSample(sparks.drop._data, [[now, null]], npoints), current: 0, usage: 0 },
				dup: { ...sparks.dup, _data: M.addSample(sparks.dup._data, [[now, null]], npoints), current: 0, usage: 0 },
			};

			for (let id in sparks.io) {
				for (let name in sparks.io[id]) {
					sparks.io[id][name] = { ...sparks.io[id][name], data: M.addSample(sparks.io[id][name].data, [[now, null]], npoints), current: 0, usage: 0 };
				}
			}
		} else {
			sparks = {
				...sparks,
				cpu: {
					...sparks.cpu,
					data: M.addSample(sparks.cpu.data, resources.cpu_data, npoints),
					min: 0,
					max: 100,
					current: resources.cpu_current,
					limit: resources.cpu_limit,
					usage: resources.cpu_usage,
					color: resources.cpu_limit !== 0 ? getColor(resources.cpu_current) : '#39B54A',
				},
				mem: {
					...sparks.mem,
					data: M.addSample(sparks.mem.data, resources.mem_data, npoints),
					min: 0,
					max: 100,
					current: resources.mem_current,
					limit: resources.mem_limit,
					usage: resources.mem_usage,
					color: resources.mem_limit !== 0 ? getColor(resources.mem_usage) : '#39B54A',
				},
				fps: {
					...sparks.fps,
					data: M.addSample(sparks.fps.data, resources.fps_data, npoints),
					min: 0,
					current: resources.fps_current,
					limit: resources.fps_limit,
					usage: resources.fps_usage,
					color: resources.fps_limit !== 0 ? getColor(resources.fps_current) : '#39B54A',
				},
				bitrate: {
					...sparks.bitrate,
					data: M.addSample(sparks.bitrate.data, resources.bitrate_data, npoints),
					min: 0,
					current: resources.bitrate_current,
					limit: resources.bitrate_limit,
					usage: resources.bitrate_usage,
					color: resources.bitrate_limit !== 0 ? getColor(resources.bitrate_current) : '#39B54A',
				},
				quality: {
					...sparks.quality,
					data: M.addSample(sparks.quality.data, resources.quality_data, npoints),
					min: -1,
					current: resources.quality_current,
					limit: resources.quality_limit,
					usage: resources.quality_usage,
					color: resources.quality_limit !== 0 ? getColor(resources.quality_current) : '#39B54A',
				},
				speed: {
					...sparks.speed,
					data: M.addSample(sparks.speed.data, resources.speed_data, npoints),
					min: 0,
					current: resources.speed_current,
					limit: resources.speed_limit,
					usage: resources.speed_usage,
					color: resources.speed_limit !== 0 ? getColor(resources.speed_current) : '#39B54A',
				},
				drop: {
					...sparks.drop,
					_data: M.addSample(sparks.drop._data, resources.drop_data, npoints),
					min: 0,
					current: resources.drop_current,
					limit: resources.drop_limit,
					usage: resources.drop_usage,
					color: resources.drop_limit !== 0 ? getColor(resources.drop_current) : '#39B54A',
				},
				dup: {
					...sparks.dup,
					_data: M.addSample(sparks.dup._data, resources.dup_data, npoints),
					min: 0,
					current: resources.dup_current,
					limit: resources.dup_limit,
					usage: resources.dup_usage,
					color: resources.dup_limit !== 0 ? getColor(resources.dup_current) : '#39B54A',
				},
			};

			for (let id in resources.io) {
				if (!(id in sparks.io)) {
					sparks.io[id] = {};

					for (let name in resources.io[id]) {
						sparks.io[id][name] = {
							data: M.addSample([], [[0, null]], npoints),
							color: '#ffffff',
							current: 0,
							limit: 0,
							usage: 0,
						};
					}
				}

				for (let name in resources.io[id]) {
					sparks.io[id][name] = {
						...sparks.io[id][name],
						data: M.addSample(sparks.io[id][name].data, resources.io[id][name].data, npoints),
						current: resources.io[id][name].current,
						limit: resources.io[id][name].limit,
						usage: resources.io[id][name].usage,
						color: '#39B54A',
					};
				}
			}
		}

		const max = (data, limit) => {
			let max = limit;

			if (!limit) {
				max = M.max(data);
			}

			return max * 1.1;
		};

		const min = (data) => {
			return M.min(data) * 0.9;
		};

		sparks.cpu.max = max(sparks.cpu.data, 0);
		sparks.cpu.min = min(sparks.cpu.data);

		sparks.mem.max = max(sparks.mem.data, 0);
		sparks.mem.min = min(sparks.mem.data);

		sparks.fps.max = max(sparks.fps.data, sparks.fps.limit);
		sparks.fps.min = min(sparks.fps.data);

		sparks.bitrate.max = max(sparks.bitrate.data, sparks.bitrate.limit);
		sparks.bitrate.min = min(sparks.bitrate.data);

		sparks.quality.max = max(sparks.quality.data, sparks.quality.limit);
		sparks.quality.min = min(sparks.quality.data);

		sparks.speed.max = max(sparks.speed.data, sparks.speed.limit);
		sparks.speed.min = min(sparks.speed.data);

		sparks.drop.data = M.diffValues(sparks.drop._data);
		sparks.drop.current = M.sum(sparks.drop.data);
		sparks.drop.usage = sparks.drop.current;
		sparks.drop.max = max(sparks.drop.data, sparks.drop.limit);
		sparks.drop.min = min(sparks.drop.data);
		sparks.drop.color = sparks.drop.current !== 0 ? '#D84848' : '#39B54A';

		sparks.dup.data = M.diffValues(sparks.dup._data);
		sparks.dup.current = M.sum(sparks.dup.data);
		sparks.dup.usage = sparks.dup.current;
		sparks.dup.max = max(sparks.dup.data, sparks.dup.limit);
		sparks.dup.min = max(sparks.dup.data);
		sparks.dup.color = sparks.dup.current !== 0 ? '#D84848' : '#39B54A';

		for (let id in sparks.io) {
			for (let name in sparks.io[id]) {
				console.log(id, name);
				sparks.io[id][name].max = max(sparks.io[id][name].data, sparks.io[id][name].limit);
				sparks.io[id][name].min = min(sparks.io[id][name].data);
			}
		}

		setSparks(sparks);
	};

	const updateAlerts = (data) => {
		const alerts = [];

		if (data === null) {
			setAlerts(alerts);

			return;
		}

		setAlerts(alerts);
	};

	const setAlerts = (alerts) => {
		if (!Array.isArray(alerts)) {
			alerts = [alerts];
		}

		const a = $coreAlerts;

		for (let i = 0; i < alerts.length; i++) {
			const alert = a.get(alerts[i].id);

			if (!alert && alerts[i].resolved === false) {
				a.set(alerts[i].id, alerts[i]);
				continue;
			}

			if (alert) {
				if (alerts[i].resolved === true) {
					a.delete(alerts[i].id);
				} else {
					a.set(alerts[i].id, {
						...alert,
						level: alerts[i].level,
						message: alerts[i].message,
					});
				}
			}
		}

		setCoreAlerts(new Map(a));
	};

	const handleMountAbort = () => {
		navigate('/group/' + groupid + '/cores/' + coreid);
		return;
	};

	const handleMonitorChange = (what) => async (event) => {
		const value = event.target.value;

		const monitor = {
			...$monitor,
		};

		if (what === 'timerange') {
			monitor.timerange = parseInt(value);
		} else if (what === 'interval') {
			monitor.interval = parseInt(value);
		}

		S.Set('timerange', monitor.timerange);
		S.Set('interval', monitor.interval);

		monitor.npoints = Math.floor(monitor.timerange / monitor.interval);

		setMonitor({
			...$monitor,
			...monitor,
		});

		setMonitorSkipUpdate(true);

		const data = await $coreAPI.ProcessMonitor(processid, monitor.timerange, monitor.interval);
		if (data !== null) {
			updateSparks($sparks, data.resources, monitor.npoints, true);
		}

		setMonitorSkipUpdate(false);
	};

	const getColor = (percent) => {
		const colors = [
			{
				to: 25,
				color: '#39B54A',
			},
			{
				from: 25,
				to: 50,
				color: '#39B54A',
			},
			{
				from: 50,
				to: 75,
				color: '#39B54A',
			},
			{
				from: 75,
				color: '#D84848',
			},
		];

		for (let i = 0; i < colors.length; i++) {
			let range = {
				from: -Infinity,
				to: Infinity,
				color: '#ffffff',
				...colors[i],
			};

			if (percent > range.from && percent <= range.to) {
				return range.color;
			}
		}

		return '#ffffff';
	};

	const handleReportDialog = async () => {
		if ($coreReportDialog.open === false) {
			const report = await $coreAPI.ProcessReport(processid);
			setCoreReport(report);
		}

		/*
		// update the log history if the current log is different from the
		// previous time
		let report = $coreReport;
		if ($coreReport && monitor.report.created_at !== $coreReport.created_at) {
			report = await $coreAPI.ProcessReport(processid);

			setCoreReport(report);
		}
		*/

		setCoreReportDialog({
			...$coreReportDialog,
			open: !$coreReportDialog.open,
		});
	};

	const handleReportDialogSettings = (what) => (event) => {
		const value = event.target.value;

		if (what === 'history') {
			setCoreReportDialog({
				...$coreReportDialog,
				history: value,
			});
		} else if (what === 'data') {
			setCoreReportDialog({
				...$coreReportDialog,
				data: value,
			});
		}
	};

	const handleFfCmdDialog = () => {
		setFfCmd({
			...$ffCmd,
			open: !$ffCmd.open,
		});
	};

	const handleHelp = () => {};

	if ($ready === false) {
		return (
			<Backdrop open={true}>
				<Grid xs={1} md={4}></Grid>
				<Grid xs={10} md={4}>
					<Paper xs={12}>
						<PaperHeader title={<Trans>Connecting</Trans>} />
						<PaperContent>
							<React.Fragment>
								{$core !== null && (
									<Typography variant="body1">
										<Trans>Connecting to the process at {$core.domain_public} ...</Trans>
									</Typography>
								)}
								<LinearProgress sx={{ mt: '1em' }} />
							</React.Fragment>
						</PaperContent>
						<PaperFooter
							buttonsRight={
								<Button variant="outlined" color="primary" onClick={handleMountAbort}>
									<Trans>Abort</Trans>
								</Button>
							}
						/>
					</Paper>
				</Grid>
				<Grid xs={1} md={4}></Grid>
			</Backdrop>
		);
	}

	if ($coreAPIError !== null) {
		return (
			<Page
				breadcrumb={
					<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
						<Link
							underline="hover"
							sx={{ display: 'flex', alignItems: 'center' }}
							color="inherit"
							component={RouterLink}
							to={`/group/${groupid}/cores`}
						>
							<AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
						</Link>
						<Trans>{coreid}</Trans>
					</Breadcrumbs>
				}
				title={$coreAPIError.error}
				subTitle={$coreAPIError.cause}
				onHelp={handleHelp}
				onRefresh={handleMount}
			></Page>
		);
	}

	let reportData = $coreReport;
	if ($coreReportDialog.history !== 'latest') {
		for (let h of $coreReport.history) {
			if (h.created_at === $coreReportDialog.history) {
				reportData = h;
				break;
			}
		}
	}

	const fileCells = [
		{
			id: 'stream',
			align: 'left',
			disablePadding: true,
			sortable: true,
			label: 'Stream',
			field: 'stream',
		},
		{
			id: 'type',
			align: 'left',
			disablePadding: true,
			sortable: true,
			label: 'Type',
			field: 'type',
		},
		{
			id: 'codec',
			align: 'left',
			disablePadding: true,
			sortable: true,
			label: 'Codec',
			field: 'codec',
		},
		{
			id: 'address',
			align: 'left',
			disablePadding: true,
			sortable: true,
			label: 'Address',
			field: 'address',
		},
	];

	const fileRows = [];

	if ($coreProcess.progress) {
		for (let s of $coreProcess.progress.input) {
			const key = `input_${s.index}:${s.stream}`;
			fileRows.push({
				_key: key,
				_collapse: (
					<ProcessCollapse
						details={{
							codec: s.codec,
							coder: s.coder,
							height: s.height,
							pix_fmt: s.pixfmt,
							type: s.type,
							width: s.width,
							layout: s.layout,
							sampling_hz: s.sampling,
						}}
						metrics={{
							fps: $sparks.io[key]?.fps,
							bitrate: $sparks.io[key]?.bitrate,
						}}
					/>
				),
				stream: `Input ${s.index}:${s.stream}`,
				type: s.type,
				codec: s.codec,
				address: s.address,
			});
		}

		for (let s of $coreProcess.progress.output) {
			const key = `output_${s.index}:${s.stream}`;
			fileRows.push({
				_key: key,
				_collapse: (
					<ProcessCollapse
						details={{
							codec: s.codec,
							coder: s.coder,
							height: s.height,
							pix_fmt: s.pixfmt,
							type: s.type,
							width: s.width,
							layout: s.layout,
							sampling_hz: s.sampling,
						}}
						metrics={{
							fps: $sparks.io[key]?.fps,
							bitrate: $sparks.io[key]?.bitrate,
							quality: $sparks.io[key]?.quality,
						}}
					/>
				),
				stream: `Output ${s.index}:${s.stream}`,
				type: s.type,
				codec: s.codec,
				address: s.address,
			});
		}
	}

	return (
		<React.Fragment>
			<Page
				breadcrumb={
					<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
						<Link
							underline="hover"
							sx={{ display: 'flex', alignItems: 'center' }}
							color="inherit"
							component={RouterLink}
							to={`/group/${groupid}/cores`}
						>
							<AccountTreeIcon fontSize="small" style={{ marginRight: 10 }} />
							<Typography>cores</Typography>
						</Link>
						<Link
							underline="hover"
							sx={{ display: 'flex', alignItems: 'center' }}
							color="inherit"
							component={RouterLink}
							to={`/group/${groupid}/cores/${coreid}`}
						>
							<Typography>{coreid}</Typography>
						</Link>
						<Typography>{processid}</Typography>
					</Breadcrumbs>
				}
				title={$coreAbout.name}
				subTitle={
					<React.Fragment>
						<Chip
							label={
								<React.Fragment>
									<strong>
										<Trans>State</Trans>:
									</strong>{' '}
									{$coreProcess.state}
								</React.Fragment>
							}
							style={{
								fontSize: '1rem',
								padding: '0px 0px 1px 0px',
								fontWeight: 400,
								borderRadius: 5,
								backgroundColor: '#333333',
								height: 35,
								color: '#ffffff',
							}}
						/>
						<Chip
							label={
								<React.Fragment>
									<strong>
										<Trans>Uptime</Trans>:
									</strong>{' '}
									<Duration seconds={$coreProcess.state === 'running' ? $coreProcess.runtime : 0} variant="other" />
								</React.Fragment>
							}
							style={{
								fontSize: '1rem',
								padding: '0px 0px 1px 0px',
								fontWeight: 400,
								borderRadius: 5,
								backgroundColor: '#333333',
								height: 35,
								color: '#ffffff',
							}}
						/>
					</React.Fragment>
				}
				statsMenu={{
					timerange_value: $monitor.timerange,
					timerange_onchange: handleMonitorChange('timerange'),
					interval_value: $monitor.interval,
					interval_onchange: handleMonitorChange('interval'),
				}}
				onLog={handleReportDialog}
				onFfCmd={handleFfCmdDialog}
				onHelp={handleHelp}
				onRefresh={handleMount}
				headerContent={
					<Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={0}>
						<Grid item xs={12}>
							{$sparks.cpu.data ? (
								<React.Fragment>
									<Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={4} style={{ marginBottom: 30 }}>
										<Grid item xs={12} md={3}>
											<PageChart
												size={4}
												title={<Trans>CPU</Trans>}
												data={$sparks.cpu.data}
												min={$sparks.cpu.min}
												max={$sparks.cpu.max}
												color={$sparks.cpu.color}
												current={{ value: $sparks.cpu.usage, digits: 2, unit: '%' }}
												limit={{ value: $coreAbout.ncpu, digits: 2, unit: 'cores' }}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<PageChart
												size={4}
												title={<Trans>Memory</Trans>}
												data={$sparks.mem.data}
												min={$sparks.mem.min}
												max={$sparks.mem.max}
												color={$sparks.mem.color}
												current={{ value: $sparks.mem.usage, digits: 2, unit: '%' }}
												limit={{ value: $sparks.mem.limit / 1024 / 1024 / 1024, digits: 2, unit: 'GB' }}
												formatter={{ factor: 1 / 1024 / 1024, unit: 'MB' }}
											/>
										</Grid>
									</Grid>
									<Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={4} style={{ marginBottom: 10 }}>
										<Grid item xs={12} md={2}>
											<PageChart
												size={5}
												title={<Trans>FPS</Trans>}
												data={$sparks.fps.data}
												min={$sparks.fps.min}
												max={$sparks.fps.max}
												color={$sparks.fps.color}
												current={{ value: $sparks.fps.current, digits: 1, unit: '' }}
											/>
										</Grid>
										<Grid item xs={12} md={2}>
											<PageChart
												size={5}
												title={<Trans>Bitrate</Trans>}
												data={$sparks.bitrate.data}
												min={$sparks.bitrate.min}
												max={$sparks.bitrate.max}
												color={$sparks.bitrate.color}
												current={{ value: $sparks.bitrate.current / 1024, digits: 0, unit: 'Kbit/s' }}
												formatter={{ factor: 1 / 1024 }}
											/>
										</Grid>
										<Grid item xs={12} md={2}>
											<PageChart
												size={5}
												title={<Trans>Quality</Trans>}
												data={$sparks.quality.data}
												min={$sparks.quality.min}
												max={$sparks.quality.max}
												color={$sparks.quality.color}
												current={{ value: $sparks.quality.current, digits: 2, unit: '' }}
											/>
										</Grid>
										<Grid item xs={12} md={2}>
											<PageChart
												size={5}
												title={<Trans>Speed</Trans>}
												data={$sparks.speed.data}
												min={$sparks.speed.min}
												max={$sparks.speed.max}
												color={$sparks.speed.color}
												current={{ value: $sparks.speed.current, digits: 2, unit: '' }}
											/>
										</Grid>
										<Grid item xs={12} md={2}>
											<PageChart
												size={5}
												title={<Trans>Drop.</Trans>}
												data={$sparks.drop.data}
												min={$sparks.drop.min}
												max={$sparks.drop.max}
												color={$sparks.drop.color}
												current={{ value: $sparks.drop.current, digits: 0, unit: 'frames' }}
											/>
										</Grid>
										<Grid item xs={12} md={2}>
											<PageChart
												size={5}
												title={<Trans>Dup.</Trans>}
												data={$sparks.dup.data}
												min={$sparks.dup.min}
												max={$sparks.dup.max}
												color={$sparks.dup.color}
												current={{ value: $sparks.dup.current, digits: 0, unit: 'frames' }}
											/>
										</Grid>
									</Grid>
								</React.Fragment>
							) : (
								<Stack direction="column" justifyContent="center" alignItems="center" spacing={5} style={{ margin: '0px 100px 100px 100px' }}>
									<CircularProgress color="inherit" />
								</Stack>
							)}
							<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} style={{ marginBottom: 20 }}>
								<Typography variant="h2" gutterBottom>
									<Trans>Process</Trans>
								</Typography>
							</Stack>
						</Grid>
					</Grid>
				}
				sidebarContent={Array.from($coreAlerts.values())
					.sort((a, b) => {
						return a.date.isBefore(b.date) ? 1 : a.date.isAfter(b.date) ? -1 : 0;
					})
					.map((alert) => (
						<Alert
							key={alert.id}
							title={alert.title}
							core_name={alert.core_name}
							message={alert.message}
							level={alert.level}
							date={alert.date.format('YYYY.MM.DD HH:mm:ss')}
							variant="danger"
						/>
					))}
			>
				<Stack direction="column" justifyContent="space-between" alignItems="flex-start" spacing={2}>
					<React.Fragment>
						<Table rows={fileRows} cells={fileCells} order="asc" orderBy="id" rowsPerPage={25} rowsPerPageOptions={[10, 25, 50, 100]}></Table>
					</React.Fragment>
				</Stack>
			</Page>
			<Dialog
				open={$coreReportDialog.open}
				onClose={handleReportDialog}
				title={
					<React.Fragment>
						<ArticleIcon style={{ marginBottom: -5, marginRight: 10 }} />
						<Trans>Logging</Trans>
					</React.Fragment>
				}
				size="large"
				footer="false"
			>
				<Grid container spacing={4}>
					<Grid item xs={12}>
						<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2} style={{ marginTop: 15 }}>
							<Select
								label={<Trans>History</Trans>}
								description={null}
								fullWidth={true}
								size="small"
								value={$coreReportDialog.history}
								onChange={handleReportDialogSettings('history')}
							>
								<MenuItem value="latest">latest</MenuItem>
								{$coreReport.history
									.sort((a, b) => (a.created_at > b.created_at ? -1 : a.created_at < b.created_at ? 1 : 0))
									.map((h) => (
										<MenuItem key={h.created_at} value={h.created_at}>
											{h.created_at}
										</MenuItem>
									))}
							</Select>
							<Select
								label={<Trans>File</Trans>}
								description={null}
								fullWidth={true}
								size="small"
								value={$coreReportDialog.data}
								onChange={handleReportDialogSettings('data')}
							>
								<MenuItem value="prelude">Prelude</MenuItem>
								<MenuItem value="log">Logging</MenuItem>
							</Select>
						</Stack>
					</Grid>
					<Grid item xs={12}>
						{$coreReportDialog.data === 'prelude' && (
							<React.Fragment>
								<Typography variant="h4" style={{ marginTop: 0 }}>
									<Trans>Prelude</Trans>
								</Typography>
								<div
									style={{
										padding: '0px 20px 0px 20px',
										backgroundColor: '#333',
										border: '1px solid rgba(0, 0, 0, .35)',
										borderRadius: 5,
										marginTop: 15,
									}}
								>
									<Textarea value={reportData.prelude.join('\n')} scrollTo="bottom" rows={25} />
								</div>
							</React.Fragment>
						)}
						{$coreReportDialog.data === 'log' && (
							<React.Fragment>
								<Typography variant="h4" style={{ marginTop: 0 }}>
									<Trans>Logfile</Trans>
								</Typography>
								<div
									style={{
										padding: '0px 20px 0px 20px',
										backgroundColor: '#333',
										border: '1px solid rgba(0, 0, 0, .35)',
										borderRadius: 5,
										marginTop: 15,
									}}
								>
									<Textarea value={reportData.log.map((l) => l.timestamp + ' ' + l.data).join('\n')} scrollTo="bottom" rows={25} />
								</div>
							</React.Fragment>
						)}
					</Grid>
				</Grid>
			</Dialog>
			<Dialog
				open={$ffCmd.open}
				onClose={handleFfCmdDialog}
				title={
					<React.Fragment>
						<IntegrationInstructionsIcon style={{ marginBottom: -5, marginRight: 10 }} />
						<Trans>FFmpeg Command</Trans>
					</React.Fragment>
				}
				size="large"
				footer="false"
			>
				<Textarea value={'ffmpeg ' + $coreProcess.command.join(' ')} scrollTo="top" whiteSpace="pre-wrap" rows={5} />
			</Dialog>
		</React.Fragment>
	);
}
