import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';

import { Trans } from '@lingui/macro';

import TabPanel from '../../../misc/TabPanel';
import Textarea from '../../../misc/Textarea';
import Select from '../../../misc/Select';

import * as Storage from '../../../utils/storage';
import * as Auth0 from '../../../utils/auth0';

import { useService } from '../../../contexts/Service';

function Users(props) {
	const { value, users } = props;

	const handleClick = async (email) => {
		const i = value.indexOf(email);
		if (i === -1) {
			value.push(email);
		} else {
			value.splice(i, 1);
		}

		await props.onChange(value);
	};

	return (
		<React.Fragment>
			{users.map((user) => (
				<User key={user.email} name={user.name} selected={value.includes(user.email)} value={user.email} onClick={handleClick} />
			))}
		</React.Fragment>
	);
}

Users.defaultProps = {
	value: [],
	users: [],
	onChange: () => {},
};

function User(props) {
	const handleClick = () => {
		props.onClick(props.value);
	};

	return (
		<Chip
			variant={props.selected ? 'filled' : 'outlined'}
			label={props.name}
			onClick={handleClick}
			onDelete={() => {}}
			deleteIcon={props.selected ? <DoneIcon /> : <ClearIcon />}
			color={props.selected ? 'success' : 'default'}
			avatar={<Avatar>{props.name.toUpperCase().charAt(0)}</Avatar>}
			sx={{ mr: 1 }}
		/>
	);
}

User.defaultProps = {
	label: '',
	selected: false,
	onClick: () => {},
	value: '',
};

export default function Settings(props) {
	const { service } = useService();
	const navigate = useNavigate();
	const [$ready, setReady] = React.useState(false);
	const [$tab, setTab] = React.useState('.env');
	const [$useAuth0, setUseAuth0] = React.useState(false);
	const [$token, setToken] = React.useState(null);
	const [$coreUsers, setCoreUsers] = React.useState([]);
	const [$users, setUsers] = React.useState([]);
	const [$coreAPI, setCoreAPI] = React.useState(props.coreAPI);
	const [$tokens, setTokens] = React.useState([]);

	React.useEffect(() => {
		(async () => {
			await handleMount();
		})();

		return () => {
			handleUnmount();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleMount = async () => {
		let tokenId = Storage.Get('token');

		const tokens = await service.Tokens(props.groupid);
		setTokens(tokens);

		let token = null;
		if (tokenId) {
			for (let i = 0; i < tokens.length; i++) {
				if (tokens[i].id === tokenId) {
					token = tokens[i];
					break;
				}
			}
		}

		if (!token) {
			if (tokens.length !== 0) {
				token = tokens[0];
			}
		}

		Storage.Set('token', token ? token.id : '');
		setToken(token);

		const users = await service.GroupUsers(props.groupid);
		setUsers(users);

		const coreUsers = await service.CoreUser(props.groupid, props.core.id);
		setCoreUsers(coreUsers);

		if (coreUsers.length !== 0) {
			setUseAuth0(true);
		}

		setReady(true);
	};

	const handleUnmount = async () => {};

	const handleTokenChange = (event) => {
		const tokenId = event.target.value;

		let token = null;
		for (let i = 0; i < $tokens.length; i++) {
			if ($tokens[i].id === tokenId) {
				token = $tokens[i];
				break;
			}
		}

		if (!token) {
			if ($tokens.length !== 0) {
				token = $tokens[0];
			}
		}

		Storage.Set('token', token ? token.id : '');
		setToken(token);
	};

	const handleChangeTab = (event, value) => {
		setTab(value);
	};

	const handleCoreUserChange = async (value) => {
		const users = [];

		for (let i = 0; i < value.length; i++) {
			for (let j = 0; j < $users.length; j++) {
				if ($users[j].email === value[i]) {
					users.push({ ...$users[j] });
				}
			}
		}

		let config = $coreUsers.map((user) => {
			return { email: user.email };
		});

		await service.CoreUserDelete(props.groupid, props.core.id, config);

		config = users.map((user) => {
			return { email: user.email, roles: ['core:admin'] };
		});

		await service.CoreUserEdit(props.groupid, props.core.id, config);

		if (users.length !== 0) {
			setUseAuth0(true);
		} else {
			setUseAuth0(false);
		}

		setCoreUsers(users);
	};

	const handleCoreConfig = () => {};

	if (!$ready) {
		return (
			<Grid container spacing={2}>
				<Grid item xs={12} align="center">
					<CircularProgress color="secondary" />
				</Grid>
			</Grid>
		);
	}

	let settings = '';
	let tenant = null;

	if ($useAuth0) {
		const config = Auth0.getDefaultConfig();

		tenant = {
			domain: config.domain,
			clientid: config.client_id,
			audience: config.audience,
			users: $coreUsers.map((user) => user.oauth_identity),
		};
	}

	if ($tokens.length !== 0 && $coreAPI) {
		settings = props.coreAPI.Settings($tab, props.core.id, props.core.name, $token.secret, tenant);
	}

	return (
		<Grid container spacing={2}>
			{$tokens.length === 0 ? (
				<React.Fragment>
					<Grid item xs={12}>
						<Typography>
							<Trans>You first have to create a token.</Trans>
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Button
							variant="outlined"
							color="primary"
							onClick={() => {
								navigate('/group/' + props.groupid + '/tokens');
							}}
						>
							<Trans>Create token</Trans>
						</Button>
					</Grid>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Grid item xs={12}>
						<Select label="Token" value={$token.id} fullWidth={false} onChange={handleTokenChange}>
							{$tokens
								.sort((a, b) => (a.expiry_date < b.expiry_date ? 1 : a.expiry_date > b.expiry_date ? -1 : 0))
								.map((token) => {
									const d = moment(token.expiry_date);
									return (
										<MenuItem key={token.id} value={token.id}>
											<ListItemText
												primary={token.name}
												secondary={`expires ${d.format('YYYY-MM-DD HH:mm:ss')} (${d.fromNow()})`}
											></ListItemText>
										</MenuItem>
									);
								})}
						</Select>
					</Grid>
					{false && (
						<React.Fragment>
							<Grid item xs={12}>
								<Trans>Select the users to access to the core</Trans>
							</Grid>
							<Grid item xs={12}>
								<Users value={$coreUsers.map((user) => user.email)} users={$users} onChange={handleCoreUserChange} />
							</Grid>
						</React.Fragment>
					)}
					<Grid item xs={12}>
						<AppBar className="appbar" position="static" elevation={0}>
							<Tabs variant="scrollable" scrollButtons={true} value={$tab} onChange={handleChangeTab} className="tabs">
								<Tab className="tab" label={<Trans>.env</Trans>} value=".env" />
								<Tab className="tab" label={<Trans>Docker</Trans>} value="docker" />
								<Tab className="tab" label={<Trans>CLI</Trans>} value="cli" />
								<Tab className="tab" label={<Trans>Config</Trans>} value="config" />
							</Tabs>
						</AppBar>
						<TabPanel value={$tab} index=".env">
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Textarea readOnly={true} allowCopy={true} allowClickToCopy={true} rows={10} value={settings} />
								</Grid>
							</Grid>
						</TabPanel>
						<TabPanel value={$tab} index="docker">
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Textarea readOnly={true} allowCopy={true} allowClickToCopy={true} rows={10} value={settings} />
								</Grid>
							</Grid>
						</TabPanel>
						<TabPanel value={$tab} index="cli">
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Textarea readOnly={true} allowCopy={true} allowClickToCopy={true} rows={10} value={settings} />
								</Grid>
							</Grid>
						</TabPanel>
						<TabPanel value={$tab} index="config">
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Textarea readOnly={true} allowCopy={true} allowClickToCopy={true} rows={10} value={settings} />
								</Grid>
							</Grid>
						</TabPanel>
					</Grid>
					<Grid item xs={12}>
						<Button variant="outlined" color="primary" disabled={props.disabled || true} onClick={handleCoreConfig}>
							<Trans>Update config</Trans>
						</Button>
					</Grid>
				</React.Fragment>
			)}
		</Grid>
	);
}

Settings.defaultProps = {
	disabled: false,
	groupid: '',
	core: {},
	coreAPI: null,
};
