/* eslint-disable import/no-anonymous-default-export */
import base from './base';

export default {
	html: {
		width: '100%',
		height: '100%',
	},
	body: {
		background: 'rgb(66,66,66)',
	},
	code: {
		fontFamily: 'soure-code-pro, monospace',
	},
	textarea: {
		width: '100%',
		backgroundColor: base.palette.background.modalbox,
		fontFamily: 'monospace',
		fontSize: '.95rem',
		whiteSpace: 'pre',
		overflow: 'auto',
		color: base.palette.text.primary,
		border: '0',
		resize: 'none',
		wordWrap: 'inherit',
		wordBreak: 'inherit',
		overflowWrap: 'normal',
	},
	'*:focus': {
		outline: 'none',
	},
	'::-webkit-scrollbar:vertical': {
		width: 6,
	},
	'::-webkit-scrollbar:horizontal': {
		height: 6,
	},
	'::-webkit-scrollbar-thumb': {
		borderRadius: 4,
		color: 'rgba(66, 61, 63, .9)',
		backgroundColor: 'rgba(66, 61, 63, .9)',
	},
	'::-webkit-scrollbar-track': {
		backgroundColor: 'transparent',
		borderRadius: 8,
	},
	'::-webkit-scrollbar-corner': {
		background: 'transparent',
	},
	// firefox
	'@-moz-document url-prefix(http://),url-prefix(https://)': {
		scrollbar: {
			MozAppearance: 'none !important',
			background: 'rgba(66, 61, 63, .9) !important',
		},
		'thumb,scrollbarbutton': {
			MozAppearance: 'none !important',
			backgroundColor: 'transparent !important',
		},
		'thumb:hover,scrollbarbutton:hover': {
			MozAppearance: 'none !important',
			backgroundColor: 'transparent !important',
		},
		scrollbarbutton: {
			display: 'none !important',
		},
		'scrollbar[orient="vertical"]': {
			minWidth: '6px !important',
		},
	},

	// Extend Textarea
	// Todo: Textarea > Textfield+Multiline?
	// padding: '1.3em 1em 1em 1em'
	'.textarea': {
		backgroundColor: base.palette.background.modalbox,
		borderRadius: 4,
		padding: '0em 1em 0em 1em',
	},

	// Extend MuiButton
	'.probeButton': {
		height: '56px!important',
	},
};
