import React, { useContext } from 'react';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { makeStyles } from '@mui/styles';

import { useLingui } from '@lingui/react';
import { t } from '@lingui/macro';

import NotifyContext from '../contexts/Notify';
import TextareaModal from './modals/Textarea';

const useStyles = makeStyles((theme) => ({
	button: {
		float: 'right',
		marginRight: '.5em',
		marginTop: '-2em',
		padding: '0.5em',
		backgroundColor: theme.palette.background.footer1,
		'&:hover': {
			backgroundColor: theme.palette.background.footer2,
		},
		bottom: '-2.5em',
	},
}));

export default function Component(props) {
	const classes = useStyles();
	const { i18n } = useLingui();

	const [$modal, setModal] = React.useState(false);

	const notify = useContext(NotifyContext);
	const textAreaRef = React.createRef();

	React.useEffect(() => {
		scrollTo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCopy = async () => {
		let success = false;

		if (!navigator.clipboard) {
			textAreaRef.current.select();

			try {
				success = document.execCommand('copy');
			} catch (err) {}

			textAreaRef.current.setSelectionRange(0, 0);
		} else {
			success = await writeText(navigator.clipboard.writeText(textAreaRef.current.value));
		}

		if (success === true) {
			notify.Dispatch('success', i18n._(t`Data copied to clipboard`));
			props.onCopy();
		} else {
			notify.Dispatch('error', i18n._(t`Error while copying data to clipboard`));
		}
	};

	const handleClick = async () => {
		if (props.allowClickToCopy === true) {
			await handleCopy();
		}
	};

	const handleModal = () => {
		setModal(!$modal);
	};

	const writeText = (promise) => {
		return promise
			.then(() => true)
			.catch((err) => {
				console.warn(err);
				return false;
			});
	};

	const scrollTo = () => {
		if (props.scrollTo === 'bottom') {
			textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
		}

		return;
	};

	let allowCopy = props.allowCopy;
	if (props.value.length === 0 || props.disabled === true) {
		allowCopy = false;
	}

	let allowModal = props.allowModal;
	if (props.value.length === 0 || props.disabled === true) {
		allowModal = false;
	}

	let textAreaDivSytle = {};
	let textAreaStyle = {
		whiteSpace: props.whiteSpace,
		backgroundColor: '#333',
	};
	if (props.rows === 1) {
		textAreaStyle = {
			whiteSpace: props.whiteSpace,
			backgroundColor: '#333',
			marginBottom: '1.2em',
			marginTop: '1em',
		};
	} else {
		textAreaDivSytle = {
			paddingBottom: '.8em',
			paddingTop: '1em',
		};
	}

	return (
		<React.Fragment>
			<div style={textAreaDivSytle}>
				{allowCopy && (
					<IconButton size="small" onClick={handleCopy} className={classes.button}>
						<FileCopyIcon fontSize="small" />
					</IconButton>
				)}
				{allowModal && (
					<IconButton size="small" onClick={handleModal} className={classes.button}>
						<OpenInNewIcon fontSize="small" />
					</IconButton>
				)}
				<textarea
					style={textAreaStyle}
					ref={textAreaRef}
					rows={props.rows}
					value={props.value}
					readOnly={props.readOnly}
					disabled={props.disabled}
					onChange={props.onChange}
					onClick={handleClick}
				/>
			</div>
			<TextareaModal
				open={$modal}
				onClose={handleModal}
				title={props.title}
				onHelp={props.onHelp}
				rows={props.rows}
				value={props.value}
				readOnly={props.readOnly}
				disabled={props.disabled}
				onChange={props.onChange}
				scrollTo={props.scrollTo}
			/>
		</React.Fragment>
	);
}

Component.defaultProps = {
	title: '',
	rows: 20,
	value: '',
	readOnly: true,
	allowCopy: true,
	allowClickToCopy: false,
	allowModal: false,
	disabled: false,
	scrollTo: 'top',
	onChange: function (value) {},
	onCopy: function () {},
	onHelp: null,
	whiteSpace: 'pre',
};
