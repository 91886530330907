import React from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';

import { Trans } from '@lingui/macro';

import universe from '../assets/images/universe-4609408.jpg';
import Logo from '../misc/Logo';
import { useService } from '../contexts/Service';
import * as auth0 from '../utils/auth0';

const useStyles = makeStyles((theme) => ({
	leftSide: {
		background: `#333 url(${universe}) no-repeat`,
		backgroundSize: 'cover',
		position: 'relative',
		borderRight: '2px solid #333',
		zIndex: -1,
		height: '100%',
		'&:before': {
			content: '" "',
			display: 'block',
			position: 'absolute',
			backgroundColor: 'rgba(35,35,35,.55)',
			height: '100%',
			width: '100%',
			zIndex: -1,
		},
	},
}));

const config = {
	domain: 'livespotting.eu.auth0.com',
	client_id: 'gDkSAebvGTB9bLsSQMwPCJHxX5zYSOnX',
	audience: 'https://datarhei.com/core',
	redirect_uri: window.location.origin,
};

export default function Login(props) {
	const classes = useStyles();
	const [$isAuthenticated, setIsAuthenticated] = React.useState(false);
	const { backdrop } = useService();

	React.useEffect(() => {
		(async () => {
			await auth0.init();
			const isAuthenticated = await auth0.isAuthenticated();

			setIsAuthenticated(isAuthenticated);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleAuth0Login = async () => {
		backdrop(true);

		auth0.setConfig(config);

		await auth0.init();
		const isAuthenticated = await auth0.isAuthenticated();

		if (isAuthenticated === false) {
			const redirect = await auth0.login({
				hash: window.location.hash.substring(1),
			});

			if (redirect === true) {
				backdrop(false);
				return;
			}
		} else {
			setIsAuthenticated(true);
		}

		backdrop(false);
	};

	return (
		<Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
			<Grid item xs={12} sm={7} md={8} align="center" display={{ xs: 'none', sm: 'block' }} className={classes.leftSide}>
				<Stack direction="column" justifyContent="space-between" alignItems="center" spacing={2} style={{ height: '100vh' }}>
					<Stack
						direction="row"
						justifyContent="flex-start"
						alignItems="flex-start"
						spacing={2}
						style={{ paddingTop: 30, paddingLeft: 40, paddingRight: 40, width: '100%' }}
					>
						<div style={{ width: 40, marginBottom: -5 }}>
							<Logo />
						</div>
						<Typography variant="h1">
							datarhei <strong style={{}}>Cloud</strong>
						</Typography>
					</Stack>
					<Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
						<Stack direction="column" justifyContent="start-left" alignItems="start-left" spacing={0}>
							<Stack direction="column" justifyContent="start-left" alignItems="start-left" spacing={2} style={{ marginBottom: 10 }}>
								<Typography variant="h2" textAlign="left" style={{ fontWeight: 400 }}>
									<Trans>Graphical interface and APIs</Trans>
									<br />
									<Trans>to access...</Trans>
								</Typography>
							</Stack>
							<Stack direction="row" justifyContent="start-left" alignItems="start-left" spacing={2}>
								<CheckIcon />
								<Typography variant="body">
									<Trans>all your instances</Trans>
								</Typography>
							</Stack>
							<Stack direction="row" justifyContent="start-left" alignItems="start-left" spacing={2}>
								<CheckIcon />
								<Typography variant="body">
									<Trans>instance and process statistics</Trans>
								</Typography>
							</Stack>
							<Stack direction="row" justifyContent="start-left" alignItems="start-left" spacing={2}>
								<CheckIcon />
								<Typography variant="body">
									<Trans>viewer sessions and bandwith data</Trans>
								</Typography>
							</Stack>
							<Stack direction="row" justifyContent="start-left" alignItems="start-left" spacing={2}>
								<CheckIcon />
								<Typography variant="body">
									<Trans>process flows</Trans>
								</Typography>
							</Stack>
							<Stack direction="row" justifyContent="start-left" alignItems="start-left" spacing={2}>
								<CheckIcon />
								<Typography variant="body">
									<Trans>alerts for failers and overloads</Trans>
								</Typography>
							</Stack>
							<Stack direction="row" justifyContent="start-left" alignItems="start-left" spacing={2} style={{ marginTop: 20 }}>
								<AddIcon />
								<Typography variant="body">
									<Trans>Additional security via Auth0 and Single Sign-on</Trans>
								</Typography>
							</Stack>
						</Stack>
					</Stack>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						style={{ paddingBottom: 30, paddingLeft: 40, paddingRight: 40, width: '100%' }}
					>
						<Typography variant="h3" style={{ marginTop: 0 }}>
							<Trans>Easy setup</Trans>
						</Typography>

						<Typography variant="h3">
							<Trans>Free quota</Trans>
						</Typography>

						<Typography variant="h3">
							<Trans>No credit card required</Trans>
						</Typography>
					</Stack>
				</Stack>
			</Grid>
			<Grid item xs={12} sm={5} md={4} align="center">
				<Stack direction="column" justifyContent="space-between" alignItems="center" spacing={2}>
					{$isAuthenticated === false ? (
						<React.Fragment>
							<Typography variant="h1" textAlign="center" style={{ fontSize: '2rem', fontWeight: 400 }}>
								<Trans>Welcome!</Trans>
							</Typography>
							<Button variant="outlined" color="primary" onClick={handleAuth0Login}>
								<Trans>Login / Sign Up</Trans>
							</Button>
							<Typography variant="body" textAlign="left">
								<Trans>Bla Auth0 bla</Trans>
							</Typography>
						</React.Fragment>
					) : (
						<React.Fragment>
							<Typography>
								<Trans>Connecting ...</Trans>
							</Typography>
							<Button variant="outlined" color="secondary" onClick={() => window.location.reload()}>
								<Trans>Retry</Trans>
							</Button>
						</React.Fragment>
					)}
				</Stack>
			</Grid>
		</Grid>
	);
}

Login.defaultProps = {
	onAuth0: function () {},
};
