import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import GroupsIcon from '@mui/icons-material/Groups';

import { Trans } from '@lingui/macro';

import Page from '../misc/Page';
import { useService } from '../contexts/Service';

export default function Main(props) {
	const navigate = useNavigate();
	const { service, dispatch, backdrop } = useService();
	const [$ready, setReady] = React.useState(false);
	const [$groupName, setGroupName] = React.useState('');

	React.useEffect(() => {
		(async () => {
			await handleMount();
		})();

		return () => {
			handleUnmount();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleMount = async () => {
		service.UpdateUser();

		const groups = service.Groups();

		for (let i = 0; i < groups.length; i++) {
			if (groups[i].owner === true) {
				navigate('/group/' + groups[i].id + '/cores');
				return;
			}
		}

		if (groups.length !== 0) {
			navigate('/group/' + groups[0].id + '/cores');
			return;
		}

		setReady(true);
	};

	const handleUnmount = async () => {};

	const handleGroupName = (event) => {
		const value = event.target.value;

		setGroupName(value);
	};

	const handleAddGroupDone = async () => {
		backdrop(true);

		const group = await service.GroupAdd($groupName);
		if (group !== null) {
			await service.UpdateUser();

			dispatch('set_groups', service.Groups());
			navigate('/group/' + group.id);

			return;
		}

		backdrop(false);
	};

	const handleHelp = () => {};

	if ($ready === false) {
		return null;
	}

	return (
		<React.Fragment>
			<Page supTitle={<GroupsIcon fontSize="normal" />} title={<Trans>Create Group</Trans>} onHelp={handleHelp}>
				<Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
					<Grid item xs={3} align="center">
						<Grid container spacing={3}>
							<Grid item xs={12} align="center">
								<Typography>
									<Trans>Enter a group name:</Trans>
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<TextField variant="outlined" fullWidth label={<Trans>Group Name</Trans>} value={$groupName} onChange={handleGroupName} />
							</Grid>
							<Grid item xs={12}>
								<Button variant="outlined" fullWidth color="primary" disabled={$groupName.length === 0} onClick={handleAddGroupDone}>
									<Trans>Create Group</Trans>
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Page>
		</React.Fragment>
	);
}

Main.defaultProps = {
	service: null,
};
