import React from 'react';

import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import HelpIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { Trans } from '@lingui/macro';

const useStyles = makeStyles((theme) => ({
	modalHeader: {
		marginBottom: '.7em',
		'& button': {
			float: 'right',
			marginLeft: '.5em',
		},
	},
	modalFooter: {
		marginTop: '1.2em',
		minHeight: '38px',
		'& button': {
			marginRight: '.5em',
		},
		'& .right': {
			float: 'right',
			marginRight: '0',
			marginLeft: '.5em',
		},
	},
	modalPaper: {
		padding: '1em 1.5em 1.3em 1.5em',
		width: '95%',
		maxWidth: 980,
		maxHeight: '95%',
		overflow: 'scroll',
		backgroundColor: theme.palette.background.modal,
		color: theme.palette.text.primary,
	},
}));

const Component = React.forwardRef((props, ref) => {
	const classes = useStyles();

	return (
		<Paper className={classes.modalPaper} elevation={0} tabIndex={-1} ref={ref}>
			<Grid container spacing={0}>
				<Grid item xs={12} className={classes.modalHeader}>
					<Typography variant="button">{props.title}</Typography>
					{typeof props.onClose === 'function' && (
						<IconButton color="inherit" size="small" onClick={props.onClose}>
							<CloseIcon fontSize="small" />
						</IconButton>
					)}
					{typeof props.onHelp === 'function' && (
						<IconButton color="inherit" size="small" onClick={props.onHelp}>
							<HelpIcon fontSize="small" />
						</IconButton>
					)}
				</Grid>
			</Grid>
			{props.children}
			<Grid container spacing={0}>
				<Grid item xs={12} className={classes.modalFooter}>
					<Button variant="outlined" onClick={props.onClose}>
						<Trans>Close</Trans>
					</Button>
				</Grid>
			</Grid>
		</Paper>
	);
});

export default Component;

Component.defaultProps = {
	title: '',
	onClose: null,
	onHelp: null,
};
