import * as React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { Trans } from '@lingui/macro';

import TableHead from './TableHead';

/*

The cells is an array of objects describing each cell:

const cells = [
  {
    id: 'name',
    align: 'left',
    disablePadding: true,
    sortable: true,
    label: 'Name',
    field: 'name',
  },
  {
    id: 'temperature',
    align: 'left',
    disablePadding: true,
    sortable: false,
    label: 'Temperature',
    field: 'temperature',
  },
  {
    id: 'expiry_date',
    align: 'left',
    disablePadding: true,
    sortable: true,
    label: 'Date',
    field: 'expiry_date_formatted',
  },
];

const rows = [
    {
        _key: 'foobar',
		_collapse: '',
        name: 'foobar',
        temperature: 24,
        expiry_date: '2021-09-25',
        expiry_date_formatted: '25.9.2021',
    }
];

*/

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	height: 50,
	borderBottom: '2px solid #666666',
	'&:nth-of-type(odd)': {
	  backgroundColor: 'rgba(255,255,255,.025)',
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		borderBottom: 0,
	},
	'& > *': {
		 borderBottom: 'unset'
	},
}));

const StyledTableRowCollapse = styled(TableRow)(({ theme }) => ({
	borderBottom: '1px solid #666666',
	'&:nth-of-type(odd)': {
	  backgroundColor: '#333',
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		borderBottom: 0,
	},
	'& > *': {
		 borderBottom: 'unset'
	},
}));

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}

	if (b[orderBy] > a[orderBy]) {
		return 1;
	}

	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function Row(props) {
	const [$open, setOpen] = React.useState(false);

	return (
		<React.Fragment>
			<StyledTableRow hover tabIndex={-1} sx={{ background: props.color ? `${props.color}!important` : 'unset', borderBottom: props.color ? `2px solid ${props.color}` : '2px solid #666666', borderTop: props.color ? `3px solid transparent` : null, '& > *': { borderBottom: 'unset' } }}>
				<TableCell
					key="firstCell"
					align="left"
					padding="normal"
					style={{
						borderBottom: '1px solid rgba(0,0,0,.35)',
						width: 40,
						padding: 0,
					}}
				/>
				{props.row._collapse && (
					<TableCell key="collabse" style={{padding: 0, margin: 0}}>
						<IconButton size="small" onClick={() => setOpen(!$open)} aria-label="expand row">
							{$open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					</TableCell>
				)}
				{props.cells.map((cell) => {
					return (
						<TableCell
							padding={cell.disablePadding ? 'none' : 'normal'}
							align={cell.align}
							key={cell.field}
						>
							{props.row[cell.field]}
						</TableCell>
					);
				})}
				<TableCell
					key="lastCell"
					align="left"
					padding="normal"
					style={{
						borderBottom: '1px solid rgba(0,0,0,.35)',
						width: 40,
						padding: 0,
					}}
				/>
			</StyledTableRow>
			{props.row._collapse && (
				<StyledTableRowCollapse tabIndex={-1} sx={{ background: 'rgba(45,45,45,1)', borderBottom: '0px solid #000', '& > *': { borderBottom: 'unset' } }}>
					<TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: $open ? '1px solid #666' : 'unset' }} colSpan={props.cells.length + 3}>
						<Collapse in={$open} timeout="auto" unmountOnExit style={{padding: '20px 30px 20px 30px'}}>
							{props.row._collapse}
						</Collapse>
					</TableCell>
				</StyledTableRowCollapse>
			)}
		</React.Fragment>
	);
}

Row.defaultProps = {
	cells: [],
	row: null,
	color: null
};

Row.propTypes = {
	cells: PropTypes.array.isRequired,
	row: PropTypes.object.isRequired,
};

export default function EnhancedTable(props) {
	const [$order, setOrder] = React.useState(props.order);
	const [$orderBy, setOrderBy] = React.useState(props.orderBy);
	const [$page, setPage] = React.useState(0);
	const [$rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage);

	const handleRequestSort = (event, property) => {
		const isAsc = $orderBy === property && $order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	let collapse = false;

	if (props.rows.length !== 0) {
		if (props.rows[0]._collapse) {
			collapse = true;
		}
	}

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = $page > 0 ? Math.max(0, (1 + $page) * $rowsPerPage - props.rows.length) : 0;

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="flex-start"
			spacing={0}
			style={{borderRight: '1px solid rgba(0,0,0,.55)', width: '100%', marginBottom: 20, paddingBottom: 30}}
		>
			<Grid container spacing={0}>
				<TableContainer>
					<Table size={props.dense ? 'small' : 'medium'} aria-label="collapsible table">
						<TableHead order={$order} orderBy={$orderBy} onRequestSort={handleRequestSort} cells={props.cells} collapse={collapse} />
						<TableBody>
							{props.rows
								.slice()
								.sort(getComparator($order, $orderBy))
								.slice($page * $rowsPerPage, $page * $rowsPerPage + $rowsPerPage)
								.map((row) => {
									return <Row color={row.color} key={row._key} cells={props.cells} row={row} />;
								})}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: (props.dense ? 33 : 53) * emptyRows,
										border: '2px solid #fff',
									}}
								>
									<TableCell
										colSpan={props.cells.length + (collapse ? 1 : 0)}
										style={{'MuiTableCell-root': {
											border: '2px solid #fff',
										}}}
									/>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				{/* <TablePagination
					rowsPerPageOptions={props.rowsPerPageOptions}
					component="div"
					count={props.rows.length}
					rowsPerPage={$rowsPerPage}
					page={$page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage={<Trans>Rows per page:</Trans>}
				/> */}
			</Grid>
		</Stack>
	);
}

EnhancedTable.defaultProps = {
	order: 'asc',
	orderBy: '',
	cells: [],
	rows: [],
	rowsPerPage: 5,
	dense: true,
};

EnhancedTable.propTypes = {
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	cells: PropTypes.array.isRequired,
	rows: PropTypes.array.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
	rowsPerPageOptions: PropTypes.array.isRequired,
	dense: PropTypes.bool,
};
