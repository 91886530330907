import * as React from 'react';
import TextField from '@mui/material/TextField';
import Adapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';

export default function DatePicker(props) {
	props = {
		...props,
		renderInput: (params) => <TextField {...params} />,
	};
	return (
		<LocalizationProvider dateAdapter={Adapter}>
			<DateTimePicker {...props} />
		</LocalizationProvider>
	);
}

DatePicker.defaultProps = {
	value: '',
	onChange: function (value) {},
};
