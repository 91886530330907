import * as React from 'react';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Backdrop from '@mui/material/Backdrop';
import LinearProgress from '@mui/material/LinearProgress';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CircularProgress from '@mui/material/CircularProgress';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ArticleIcon from '@mui/icons-material/Article';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { Trans } from '@lingui/macro';

import Duration from '../../../misc/Duration';
import Page from '../../../misc/Page';
import PageChart from '../../../misc/PageChart';
import Paper from '../../../misc/Paper';
import PaperHeader from '../../../misc/PaperHeader';
import PaperContent from '../../../misc/PaperContent';
import PaperFooter from '../../../misc/PaperFooter';
import Textarea from '../../../misc/Textarea';
import TextField from '../../../misc/TextField';
import Dialog from '../../../misc/modals/Dialog';
import Settings from './Settings';
import Alert from '../../../misc/Alert';
import Table from '../../../misc/Table';

import useInterval from '../../../hooks/useInterval';
import { useService } from '../../../contexts/Service';

import * as M from '../../../utils/metrics';
import * as S from '../../../utils/storage';

export default function Core(props) {
	const dataProviderService = React.useRef();
	const dataProviderCore = React.useRef();
	const dataProvider = React.useRef(new M.NullDataProvider());
	const navigate = useNavigate();
	const { service, dispatch, backdrop } = useService();
	const { groupid, coreid } = useParams();
	const [$ready, setReady] = React.useState(false);
	const [$group, setGroup] = React.useState(null);
	const [$core, setCore] = React.useState(null);
	const [$coreAPI, setCoreAPI] = React.useState(null);
	const [$coreAPIError, setCoreAPIError] = React.useState(null);
	const [$coreAbout, setCoreAbout] = React.useState({
		id: '',
		name: '',
		uptime_seconds: -1,
		version: '',
		ncpu: 1,
	});
	const [$coreProcesses, setCoreProcesses] = React.useState([]);
	const [$coreSettings, setCoreSettings] = React.useState(false);
	const [$coreLog, setCoreLog] = React.useState({
		open: false,
		data: [],
	});
	const [$coreFs, setCoreFs] = React.useState({
		open: false,
		data: [],
	});
	const [$coreAlerts, setCoreAlerts] = React.useState(new Map());
	const [$coreDelete, setCoreDelete] = React.useState({
		open: false,
		id: '',
	});
	const [$sparks, setSparks] = React.useState({
		// min: minimal value for the graph
		// max: maximal values for the graph
		// data: array of data points
		// color: color for the graph
		// current: the last known actual value
		// limit: an upper limit for the actual value, 0 for no limit
		// usage: percentage from current and limit if limit > 0, current value if limit == 0
		cpu: { min: 0, max: 100, data: [], color: '#ffffff', current: 0, limit: 0, usage: 0 },
		mem: { min: 0, max: 100, data: [], color: '#ffffff', current: 0, limit: 0, usage: 0 },
		egress: { min: 0, max: 100, data: [], color: '#ffffff', current: 0, limit: 0, usage: 0 },
		ingress: { min: 0, max: 100, data: [], color: '#ffffff', current: 0, limit: 0, usage: 0 },
		diskfs: { min: 0, max: 100, data: [], color: '#ffffff', current: 0, limit: 0, usage: 0 },
		memfs: { min: 0, max: 100, data: [], color: '#ffffff', current: 0, limit: 0, usage: 0 },
		session: { min: 0, max: 0, data: [], color: '#ffffff', current: 0, limit: 0, usage: 0 },
		running: { min: 0, max: 0, data: [], color: '#ffffff', current: 0, limit: 0, usage: 0 },
		finished: { min: 0, max: 0, data: [], _data: [], color: '#ffffff', current: 0, limit: 0, usage: 0 },
		killed: { min: 0, max: 0, data: [], _data: [], color: '#ffffff', current: 0, limit: 0, usage: 0 },
		failed: { min: 0, max: 0, data: [], _data: [], color: '#ffffff', current: 0, limit: 0, usage: 0 },
	});
	const [$monitor, setMonitor] = React.useState({
		timerange: S.Get('timerange', 10 * 60),
		interval: S.Get('interval', 30),
		npoints: 0,
	});
	const [$monitorSkipUpdate, setMonitorSkipUpdate] = React.useState(true);

	useInterval(async () => {
		if ($ready === false) {
			return;
		}

		if ($monitorSkipUpdate === true) {
			return;
		}

		await updateMonitor();
	}, $monitor.interval * 1000);

	React.useEffect(() => {
		(async () => {
			await handleMount();
		})();

		return () => {
			handleUnmount();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleMount = async () => {
		backdrop(true);

		const core = await handleRefresh();

		dispatch('select_group', groupid);
		dispatch('set_group_topic', 'cores');

		if (core === null) {
			backdrop(false);
			return;
		}

		dataProviderService.current = new M.ServiceDataProvider(async (ids, then, now, interval) => {
			return await service.CoreMonitor(
				groupid,
				ids,
				then,
				now,
				[
					'version',
					'sys_cpu_ncores',
					'sys_cpu_used',
					'sys_mem_used_bytes',
					'sys_mem_total_bytes',
					'sys_disk_used_bytes',
					'sys_disk_total_bytes',
					'sys_viewer_used',
					'fs_mem_used_bytes',
					'fs_mem_limit_bytes',
					'fs_disk_used_bytes',
					'fs_disk_limit_bytes',
					'net_tx_used_kbit',
					'processes_running',
					'processes_finished',
					'processes_killed',
					'processes_failed',
					'uptime_seconds',
				],
				interval
			);
		});

		dataProvider.current = dataProviderService.current;

		setTimeout(async () => {
			// test if we can directly access the core
			const [coreAPI, err, cause] = await service.CoreAPI(coreid, core.domain_public + core.uri_path.api);
			if (err !== null) {
				setCoreAPIError(service.CoreAPIError(err, cause));
			} else {
				setCoreAPIError(null);

				dataProviderCore.current = new M.CoreDataProvider(async (timerange, interval) => {
					return await coreAPI.Monitor(timerange, interval);
				});
			}

			setCoreAPI(coreAPI);
		}, 100);

		const npoints = Math.floor($monitor.timerange / $monitor.interval);

		setMonitor({
			...$monitor,
			npoints: npoints,
		});

		let monitor = await dataProvider.current.CoreRange([coreid], $monitor.timerange, $monitor.interval);
		if (monitor !== null) {
			monitor = monitor[coreid];

			setCoreAbout({
				...$coreAbout,
				id: core.id,
				name: core.name,
				uptime_seconds: monitor.uptime,
				version: monitor.version,
				ncpu: M.current(monitor.metrics.system.cpu_ncores),
			});

			setCoreFs({
				...$coreFs,
				data: monitor.log,
			});

			setCoreProcesses(monitor.processes);

			updateSparks($sparks, monitor.metrics, npoints);

			//updateAlerts(monitor.resources);

			setMonitorSkipUpdate(false);
		}

		backdrop(false);

		setReady(true);
	};

	const handleUnmount = async () => {};

	const handleMountAbort = () => {
		navigate('/group/' + groupid + '/cores');
		return;
	};

	const handleRefresh = async () => {
		backdrop(true);

		const group = await service.Group(groupid);
		if (group === null) {
			navigate('/');
			backdrop(false);
			return null;
		}
		setGroup(group);

		const core = await service.Core(groupid, coreid);
		if (core === null) {
			navigate('/group/' + groupid);
			backdrop(false);
			return null;
		}
		setCore(core);

		backdrop(false);

		return core;
	};

	const updateSparks = (sparks, metrics, npoints, reset) => {
		if (reset) {
			sparks = {
				...sparks,
				cpu: { ...sparks.cpu, data: M.addSample([], [[0, null]], npoints), current: 0, usage: 0 },
				mem: { ...sparks.mem, data: M.addSample([], [[0, null]], npoints), current: 0, usage: 0 },
				diskfs: { ...sparks.diskfs, data: M.addSample([], [[0, null]], npoints), current: 0, usage: 0 },
				memfs: { ...sparks.memfs, data: M.addSample([], [[0, null]], npoints), current: 0, usage: 0 },
				session: { ...sparks.session, data: M.addSample([], [[0, null]], npoints), current: 0, usage: 0 },
				egress: { ...sparks.egress, data: M.addSample([], [[0, null]], npoints), current: 0, usage: 0 },
				ingress: { ...sparks.ingress, data: M.addSample([], [[0, null]], npoints), current: 0, usage: 0 },
				running: { ...sparks.running, data: M.addSample([], [[0, null]], npoints), current: 0, usage: 0 },
				finished: { ...sparks.finished, _data: M.addSample([], [[0, null]], npoints), current: 0, usage: 0 },
				killed: { ...sparks.killed, _data: M.addSample([], [[0, null]], npoints), current: 0, usage: 0 },
				failed: { ...sparks.failed, _data: M.addSample([], [[0, null]], npoints), current: 0, usage: 0 },
			};
		}

		const max = (data, limit) => {
			let max = limit;

			if (!limit) {
				max = M.max(data);
			}

			return max * 1.1;
		};

		const min = (data) => {
			return M.min(data) * 0.9;
		};

		sparks.cpu.data = M.addSample(sparks.cpu.data, metrics.system.cpu_used, npoints);
		sparks.cpu.min = 0;
		sparks.cpu.max = 100;
		sparks.cpu.current = M.current(sparks.cpu.data);
		sparks.cpu.limit = 100;
		sparks.cpu.usage = sparks.cpu.current;
		sparks.cpu.color = sparks.cpu.limit !== 0 ? getColor(sparks.cpu.usage) : '#39B54A';

		let data = M.usage(metrics.system.mem_total_bytes, metrics.system.mem_used_bytes);
		sparks.mem.data = M.addSample(sparks.mem.data, data, npoints);
		sparks.mem.min = 0;
		sparks.mem.max = 100;
		sparks.mem.current = M.current(sparks.mem.data);
		sparks.mem.limit = M.max(metrics.system.mem_total_bytes);
		sparks.mem.usage = sparks.mem.current;
		sparks.mem.color = sparks.mem.limit !== 0 ? getColor(sparks.mem.usage) : '#39B54A';

		sparks.diskfs.limit = M.max(metrics.core.disk_limit_bytes);
		if (sparks.diskfs.limit === 0) {
			metrics.core.disk_used_bytes = metrics.system.disk_used_bytes;
			metrics.core.disk_limit_bytes = metrics.system.disk_total_bytes;
		}
		data = M.usage(metrics.system.disk_total_bytes, metrics.system.disk_used_bytes);
		sparks.diskfs.data = M.addSample(sparks.diskfs.data, data, npoints);
		sparks.diskfs.min = 0;
		sparks.diskfs.max = 100;
		sparks.diskfs.current = M.current(metrics.core.disk_used_bytes);
		sparks.diskfs.limit = M.max(metrics.core.disk_limit_bytes);
		sparks.diskfs.usage = M.current(sparks.diskfs.data);
		sparks.diskfs.color = sparks.diskfs.limit !== 0 ? getColor(sparks.diskfs.usage) : '#39B54A';

		sparks.memfs.limit = M.max(metrics.core.mem_limit_bytes);
		if (sparks.memfs.limit === 0) {
			metrics.core.mem_used_bytes = metrics.system.mem_used_bytes;
			metrics.core.mem_limit_bytes = metrics.system.mem_total_bytes;
		}
		data = M.usage(metrics.system.mem_total_bytes, metrics.system.mem_used_bytes);
		sparks.memfs.data = M.addSample(sparks.memfs.data, data, npoints);
		sparks.memfs.min = 0;
		sparks.memfs.max = 100;
		sparks.memfs.current = M.current(metrics.core.mem_used_bytes);
		sparks.memfs.limit = M.max(metrics.core.mem_limit_bytes);
		sparks.memfs.usage = M.current(sparks.memfs.data);
		sparks.memfs.color = sparks.memfs.limit !== 0 ? getColor(sparks.memfs.usage) : '#39B54A';

		sparks.session.data = M.addSample(sparks.session.data, metrics.system.viewer_used, npoints);
		sparks.session.current = M.current(sparks.session.data);
		sparks.session.limit = M.max(metrics.system.viewer_limit);
		sparks.session.usage = sparks.session.limit ? (sparks.session.current / sparks.session.limit) * 100 : sparks.session.current;
		sparks.session.color = sparks.session.limit !== 0 ? getColor(sparks.session.usage) : '#39B54A';
		sparks.session.max = max(sparks.session.data, sparks.session.limit);
		sparks.session.min = min(sparks.session.data);

		sparks.egress.data = M.addSample(sparks.egress.data, metrics.core.net_tx_used_kbit, npoints);
		sparks.egress.current = M.current(sparks.egress.data);
		sparks.egress.limit = M.max(metrics.core.net_tx_limit_kbit);
		sparks.egress.usage = sparks.egress.limit ? (sparks.egress.current / sparks.egress.limit) * 100 : sparks.egress.current;
		sparks.egress.color = sparks.egress.limit !== 0 ? getColor(sparks.egress.usage) : '#39B54A';
		sparks.egress.max = max(sparks.egress.data, sparks.egress.limit);
		sparks.egress.min = min(sparks.egress.data);

		sparks.ingress.data = M.addSample(sparks.ingress.data, metrics.core.net_rx_used_kbit, npoints);
		sparks.ingress.current = M.current(sparks.ingress.data);
		sparks.ingress.limit = M.max(metrics.core.net_rx_limit_kbit);
		sparks.ingress.usage = sparks.ingress.limit ? (sparks.ingress.current / sparks.ingress.limit) * 100 : sparks.ingress.current;
		sparks.ingress.color = sparks.ingress.limit !== 0 ? getColor(sparks.ingress.usage) : '#39B54A';
		sparks.ingress.max = max(sparks.ingress.data, sparks.ingress.limit);
		sparks.ingress.min = min(sparks.ingress.data);

		sparks.running.data = M.addSample(sparks.running.data, metrics.process.running, npoints);
		sparks.running.current = M.current(sparks.running.data);
		sparks.running.limit = 0;
		sparks.running.usage = sparks.running.current;
		sparks.running.color = '#39B54A';
		sparks.running.max = max(sparks.running.data, sparks.running.limit);
		sparks.running.min = min(sparks.running.data);

		sparks.finished._data = M.addSample(sparks.finished._data, metrics.process.finished, npoints);
		sparks.finished.data = M.diffValues(sparks.finished._data);
		sparks.finished.current = M.sum(sparks.finished.data);
		sparks.finished.usage = sparks.finished.current;
		sparks.finished.max = max(sparks.finished.data, sparks.finished.limit);
		sparks.finished.min = min(sparks.finished.data);
		sparks.finished.color = '#39B54A';

		sparks.killed._data = M.addSample(sparks.killed._data, metrics.process.killed, npoints);
		sparks.killed.data = M.diffValues(sparks.killed._data);
		sparks.killed.current = M.sum(sparks.killed.data);
		sparks.killed.usage = sparks.killed.current;
		sparks.killed.max = max(sparks.killed.data, sparks.killed.limit);
		sparks.killed.min = min(sparks.killed.data);
		sparks.killed.color = sparks.killed.current !== 0 ? '#D84848' : '#39B54A';

		sparks.failed._data = M.addSample(sparks.failed._data, metrics.process.failed, npoints);
		sparks.failed.data = M.diffValues(sparks.failed._data);
		sparks.failed.current = M.sum(sparks.failed.data);
		sparks.failed.usage = sparks.failed.current;
		sparks.failed.max = max(sparks.failed.data, sparks.failed.limit);
		sparks.failed.min = min(sparks.failed.data);
		sparks.failed.color = sparks.failed.current !== 0 ? '#D84848' : '#39B54A';

		setSparks(sparks);
	};

	const updateMonitor = async () => {
		if ($monitorSkipUpdate === true) {
			return;
		}

		let monitor = await dataProvider.current.CoreCurrent([coreid]);
		if (monitor === null) {
			setAlerts({
				resolved: false,
				id: 'CONNECTION_FAILED',
				title: 'Connection failed',
				core_name: $coreAbout.name,
				level: 'error',
				message: `Fetching monitoring data failed`,
				date: moment(),
			});

			updateSparks($sparks, null, $monitor.npoints);

			updateAlerts(null);

			return;
		}

		monitor = monitor[coreid];

		setAlerts({
			resolved: true,
			id: 'CONNECTION_FAILED',
		});

		setCoreAbout({
			...$coreAbout,
			uptime_seconds: monitor.uptime,
			version: monitor.version,
			ncpu: M.current(monitor.metrics.system.cpu_ncores),
		});

		setCoreFs({
			...$coreFs,
			data: monitor.log,
		});

		setCoreProcesses(monitor.processes);

		updateSparks($sparks, monitor.metrics, $monitor.npoints);

		//updateAlerts(monitor.resources);
	};

	const updateAlerts = (data) => {
		const alerts = [];

		if (data === null) {
			alerts.push(
				{
					resolved: true,
					id: 'HIGH_CPU',
				},
				{
					resolved: true,
					id: 'HIGH_MEM',
				},
				{
					resolved: true,
					id: 'HIGH_MEMFS',
				},
				{
					resolved: true,
					id: 'HIGH_DISKFS',
				}
			);
		} else {
			if (data.system.cpu_usage > 75) {
				alerts.push({
					what: 'CORE',
					resolved: false,
					id: 'HIGH_CPU',
					title: `${data.system.cpu_usage.toFixed(0)}% CPU usage`,
					core_name: $coreAbout.name,
					level: 'HIGH',
					message: `${data.system.cpu_usage.toFixed(0)}%`,
					date: moment(),
				});
			} else {
				alerts.push({
					resolved: true,
					id: 'HIGH_CPU',
				});
			}

			if (data.system.mem_usage > 75) {
				alerts.push({
					what: 'CORE',
					resolved: false,
					id: 'HIGH_MEM',
					title: `${data.system.mem_usage.toFixed(0)}% System Memory usage`,
					core_name: $coreAbout.name,
					level: 'HIGH',
					message: `${data.system.mem_usage.toFixed(0)}%`,
					date: moment(),
				});
			} else {
				alerts.push({
					resolved: true,
					id: 'HIGH_MEM',
				});
			}

			if (data.core.memfs_usage > 75) {
				alerts.push({
					what: 'CORE',
					resolved: false,
					id: 'HIGH_MEMFS',
					title: `${data.core.memfs_usage.toFixed(0)}% In-MemoryFS usage`,
					core_name: $coreAbout.name,
					level: 'HIGH',
					message: `${data.core.memfs_usage.toFixed(0)}%`,
					date: moment(),
				});
			} else {
				alerts.push({
					resolved: true,
					id: 'HIGH_MEMFS',
				});
			}

			if (data.core.diskfs_usage > 75) {
				alerts.push({
					what: 'CORE',
					resolved: false,
					id: 'HIGH_DISKFS',
					title: `${data.core.diskfs_usage.toFixed(0)}% DiskFS usage`,
					core_name: $coreAbout.name,
					level: 'HIGH',
					message: `Core "${$coreAbout.name}" has a hard disk utilization of >= 75%.`,
					link: '/group/' + groupid + '/cores' + $coreAbout.id,
					date: moment(),
				});
			} else {
				alerts.push({
					resolved: true,
					id: 'HIGH_DISKFS',
				});
			}
		}

		setAlerts(alerts);
	};

	const setAlerts = (alerts) => {
		if (!Array.isArray(alerts)) {
			alerts = [alerts];
		}

		const a = $coreAlerts;

		for (let i = 0; i < alerts.length; i++) {
			const alert = a.get(alerts[i].id);

			if (!alert && alerts[i].resolved === false) {
				a.set(alerts[i].id, alerts[i]);
				continue;
			}

			if (alert) {
				if (alerts[i].resolved === true) {
					a.delete(alerts[i].id);
				} else {
					a.set(alerts[i].id, {
						...alert,
						level: alerts[i].level,
						message: alerts[i].message,
					});
				}
			}
		}

		setCoreAlerts(new Map(a));
	};

	const handleCoreSettings = async () => {
		setCore({
			...$core,
			id: coreid,
			name: $core.name,
			domain_public: $core.domain_public,
			uri_path_api: $core.uri_path.api,
			uri_path_disk: $core.uri_path.disk,
			uri_path_memfs: $core.uri_path.memfs,
		});

		setCoreSettings(true);
	};

	const handleCoreSettingsAbort = () => {
		setCoreSettings(false);
	};

	const handleCoreLogDialog = async () => {
		if ($coreLog.open === false) {
			const data = {}; //await $coreAPI.CoreReport();

			setCoreLog({
				...$coreLog,
				data: data,
				open: true,
			});
		} else {
			setCoreLog({
				...$coreLog,
				open: false,
			});
		}
	};

	const handleCoreFsDialog = () => {
		setCoreFs({
			...$coreFs,
			open: !$coreFs.open,
		});
	};

	const handleCoreDeleteDialog = () => {
		setCoreDelete({
			...$coreDelete,
			open: !$coreDelete.open,
			name: '',
			id: coreid,
		});
	};

	const handleCoreDeleteName = (event) => {
		setCoreDelete({
			...$coreDelete,
			name: event.target.value,
		});
	};

	const handleCoreDelete = async () => {
		backdrop(true);

		const res = await service.CoreDelete(groupid, $coreDelete.id);

		if (res === true) {
			navigate('/group/' + groupid + '/cores');
			return;
		}

		setCoreDelete({
			...$coreDelete,
			open: false,
			id: '',
		});

		backdrop(false);
	};

	const handleProcessDetail = (id) => () => {
		navigate('/group/' + groupid + '/cores/' + coreid + '/process/' + id);
	};

	const handleMonitorChange = (what) => async (event) => {
		const value = event.target.value;

		const monitor = {
			...$monitor,
		};

		if (what === 'timerange') {
			monitor.timerange = parseInt(value);
		} else if (what === 'interval') {
			monitor.interval = parseInt(value);
		}

		S.Set('timerange', monitor.timerange);
		S.Set('interval', monitor.interval);

		monitor.npoints = Math.floor(monitor.timerange / monitor.interval);

		setMonitor({
			...$monitor,
			...monitor,
		});

		setMonitorSkipUpdate(true);

		let data = await dataProvider.current.CoreRange([coreid], monitor.timerange, monitor.interval);
		if (data !== null) {
			data = data[coreid];
			updateSparks($sparks, data.metrics, monitor.npoints, true);
		}

		setMonitorSkipUpdate(false);
	};

	const toggleDataSource = async () => {
		setMonitorSkipUpdate(true);

		if (dataProvider.current.Type() === 'service') {
			if (dataProviderCore.current !== null) {
				dataProvider.current = dataProviderCore.current;
			}
		} else {
			dataProvider.current = dataProviderService.current;
		}

		let data = await dataProvider.current.CoreRange([coreid], $monitor.timerange, $monitor.interval);
		if (data !== null) {
			data = data[coreid];

			setCoreAbout({
				...$coreAbout,
				uptime_seconds: data.uptime,
				version: data.version,
				ncpu: M.current(data.metrics.system.cpu_ncores),
			});

			updateSparks($sparks, data.metrics, $monitor.npoints, true);
		}

		setMonitorSkipUpdate(false);
	};

	const handleHelp = () => {};

	const getColor = (percent) => {
		const colors = [
			{
				to: 25,
				color: '#39B54A',
			},
			{
				from: 25,
				to: 50,
				color: '#39B54A',
			},
			{
				from: 50,
				to: 75,
				color: '#39B54A',
			},
			{
				from: 75,
				color: '#D84848',
			},
		];

		for (let i = 0; i < colors.length; i++) {
			let range = {
				from: -Infinity,
				to: Infinity,
				color: '#ffffff',
				...colors[i],
			};

			if (percent > range.from && percent <= range.to) {
				return range.color;
			}
		}

		return '#ffffff';
	};

	if ($ready === false) {
		return null;
		/*
		return (
			<Backdrop open={true}>
				<Grid xs={1} md={4}></Grid>
				<Grid xs={10} md={4}>
					<Paper xs={12}>
						<PaperHeader title={<Trans>Connecting</Trans>} />
						<PaperContent>
							<React.Fragment>
								{$core !== null && (
									<Typography variant="body1">
										<Trans>Connecting to the core at {$core.domain_public} ...</Trans>
									</Typography>
								)}
								<LinearProgress sx={{ mt: '1em' }} />
							</React.Fragment>
						</PaperContent>
						<PaperFooter
							buttonsRight={
								<Button variant="outlined" color="primary" onClick={handleMountAbort}>
									<Trans>Abort</Trans>
								</Button>
							}
						/>
					</Paper>
				</Grid>
				<Grid xs={1} md={4}></Grid>
			</Backdrop>
		);
		*/
	}

	const allowWrite = $group.rights.coreWrite;
	/*
	if ($coreAPIError !== null) {
		return (
			<React.Fragment>
				<Page
					breadcrumb={
						<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
							<Link
								underline="hover"
								sx={{ display: 'flex', alignItems: 'center' }}
								color="inherit"
								component={RouterLink}
								to={`/group/${groupid}/cores`}
							>
								<Typography>cores</Typography>
							</Link>
							<Trans>{coreid}</Trans>
						</Breadcrumbs>
					}
					title={$coreAPIError.error}
					subTitle={$coreAPIError.cause}
					onEdit={handleCoreSettings}
					onDelete={handleCoreDeleteDialog}
					onHelp={handleHelp}
					onRefresh={handleMount}
				></Page>
				<Dialog
					open={$coreDelete.open}
					onClose={handleCoreDeleteDialog}
					title={
						<React.Fragment>
							<DeleteForeverIcon style={{ marginBottom: -5, marginRight: 10 }} />
							<Trans>Do you want to delete this core?</Trans>
						</React.Fragment>
					}
					buttonsLeft={
						<Button variant="outlined" color="primary" onClick={handleCoreDeleteDialog}>
							<Trans>Abort</Trans>
						</Button>
					}
					buttonsRight={
						<Button variant="outlined" color="secondary" disabled={!allowWrite || $coreDelete.name !== $core.name} onClick={handleCoreDelete}>
							<Trans>Delete</Trans>
						</Button>
					}
				>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography>
								<Trans>Deleting a core cannot be reversed. The core will not be able to send monitoring data anymore.</Trans>
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography>
								<Trans>Please enter the name of the core in the field below.</Trans>
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								fullWidth
								label={<Trans>Name</Trans>}
								value={$coreDelete.name}
								disabled={!allowWrite}
								onChange={handleCoreDeleteName}
							/>
						</Grid>
					</Grid>
				</Dialog>
				<Dialog
					open={$coreSettings}
					onClose={handleCoreSettingsAbort}
					title={<Trans>Core settings</Trans>}
					size="large"
					buttonsRight={
						<Button variant="outlined" color="primary" onClick={handleCoreSettingsAbort}>
							<Trans>Got it</Trans>
						</Button>
					}
				>
					<Settings disabled={!allowWrite} groupid={groupid} core={$core} coreAPI={$coreAPI} />
				</Dialog>
			</React.Fragment>
		);
	}
*/
	const processCells = [
		{
			id: 'id',
			align: 'left',
			disablePadding: true,
			sortable: true,
			label: 'ID',
			field: 'id',
		},
		{
			id: 'order',
			align: 'right',
			disablePadding: true,
			sortable: true,
			label: 'Order',
			field: 'order',
		},
		{
			id: 'status',
			align: 'right',
			disablePadding: true,
			sortable: true,
			label: 'Status',
			field: 'status',
		},
		{
			id: 'details',
			align: 'right',
			disablePadding: true,
			sortable: false,
			label: 'Details',
			field: 'details',
		},
	];

	const filesystemCells = [
		{
			id: 'name',
			align: 'left',
			disablePadding: true,
			sortable: true,
			label: 'Name',
			field: 'name',
		},
		{
			id: 'size',
			align: 'right',
			disablePadding: true,
			sortable: true,
			label: 'Size',
			field: 'size',
		},
		{
			id: 'created',
			align: 'right',
			disablePadding: true,
			sortable: true,
			label: 'Created',
			field: 'created',
		},
	];

	const processRows = $coreProcesses.map((process) => {
		return {
			_key: process.id,
			_collapse: '',
			id: process.id,
			order: process.order,
			status: process.status,
			details: (
				<IconButton variant="text" color="secondary" onClick={handleProcessDetail(process.id)}>
					<SettingsIcon />
				</IconButton>
			),
			color: process.status === 'failed' || process.status === 'killed' ? '#AB4244' : null,
		};
	});

	const filesystemRows = $coreProcesses.map((process) => {
		return {
			name: '',
			size: '',
			created: '',
		};
	});

	return (
		<React.Fragment>
			<Page
				breadcrumb={
					<React.Fragment>
						<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
							<Link
								underline="hover"
								sx={{ display: 'flex', alignItems: 'center' }}
								color="inherit"
								component={RouterLink}
								to={`/group/${groupid}/cores`}
							>
								<AccountTreeIcon fontSize="small" style={{ marginRight: 10 }} />
								<Typography>cores</Typography>
							</Link>
							<span>{coreid}</span>
						</Breadcrumbs>
					</React.Fragment>
				}
				title={$coreAbout.name}
				subTitle={
					<React.Fragment>
						<Chip
							label={<React.Fragment>Version: {$coreAbout.version}</React.Fragment>}
							style={{
								fontSize: '1rem',
								padding: '0px 0px 1px 0px',
								fontWeight: 400,
								borderRadius: 5,
								backgroundColor: '#333',
								height: 35,
								color: '#ffffff',
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
							}}
						/>
						<Chip
							label={
								<React.Fragment>
									<Trans>Uptime</Trans>: <Duration seconds={$coreAbout.uptime_seconds} variant="other" />
								</React.Fragment>
							}
							style={{
								fontSize: '1rem',
								padding: '0px 0px 1px 0px',
								fontWeight: 400,
								borderRadius: 5,
								backgroundColor: '#333',
								height: 35,
								color: '#ffffff',
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
							}}
						/>
						{$coreAPI !== null ? (
							<React.Fragment>
								{$coreAPIError !== null ? (
									<Chip
										label={
											<React.Fragment>
												<Trans>Live Core Data not available ({$coreAPIError.cause})</Trans>
											</React.Fragment>
										}
										style={{
											fontSize: '1rem',
											padding: '0px 0px 1px 0px',
											fontWeight: 400,
											borderRadius: 5,
											backgroundColor: '#900',
											height: 35,
											color: '#ffffff',
											overflow: 'hidden',
											whiteSpace: 'nowrap',
											textOverflow: 'ellipsis',
										}}
									/>
								) : (
									<Chip
										label={
											<React.Fragment>
												{dataProvider.current.Type() === 'service' && <Trans>Enable Live Core Data</Trans>}
												{dataProvider.current.Type() === 'core' && <Trans>Disable Live Core Data</Trans>}
											</React.Fragment>
										}
										style={{
											fontSize: '1rem',
											padding: '0px 0px 1px 0px',
											fontWeight: 400,
											borderRadius: 5,
											backgroundColor: dataProvider.current.Type() === 'core' ? '#900' : '#090',
											height: 35,
											color: '#ffffff',
											overflow: 'hidden',
											whiteSpace: 'nowrap',
											textOverflow: 'ellipsis',
										}}
										onClick={toggleDataSource}
									/>
								)}
							</React.Fragment>
						) : (
							<Chip
								label={
									<React.Fragment>
										<Trans>Checking Live Core Data ...</Trans>
									</React.Fragment>
								}
								style={{
									fontSize: '1rem',
									padding: '0px 0px 1px 0px',
									fontWeight: 400,
									borderRadius: 5,
									backgroundColor: '#333',
									height: 35,
									color: '#ffffff',
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
								}}
							/>
						)}
					</React.Fragment>
				}
				statsMenu={{
					timerange_value: $monitor.timerange,
					timerange_onchange: handleMonitorChange('timerange'),
					interval_value: $monitor.interval,
					interval_onchange: handleMonitorChange('interval'),
				}}
				onEdit={handleCoreSettings}
				onEditDisabled={dataProvider.current.Type() === 'service'}
				onLog={handleCoreLogDialog}
				onLogDisabled={dataProvider.current.Type() === 'service'}
				onFs={handleCoreFsDialog}
				onFsDisabled={dataProvider.current.Type() === 'service'}
				onHelp={handleHelp}
				onRefresh={handleMount}
				onDelete={handleCoreDeleteDialog}
				headerContent={
					<Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={0}>
						<Grid item xs={12}>
							{$sparks.session.data ? (
								<React.Fragment>
									<Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={4} style={{ marginBottom: 30 }}>
										<Grid item xs={12} md={4}>
											<PageChart
												size={3}
												title={<Trans>Viewer</Trans>}
												data={$sparks.session.data}
												min={$sparks.session.min}
												max={$sparks.session.max}
												color={$sparks.session.color}
												current={{ value: $sparks.session.current, digits: 0, unit: '' }}
												usage={{ value: $sparks.session.limit ? $sparks.session.usage : 'NAN', digits: 2, unit: '%' }}
												limit={{ value: $sparks.session.limit, digits: 0, unit: '' }}
											/>
										</Grid>
										<Grid item xs={12} md={4}>
											<PageChart
												size={3}
												title={<Trans>Egress</Trans>}
												data={$sparks.egress.data}
												min={$sparks.egress.min}
												max={$sparks.egress.max}
												color={$sparks.egress.color}
												current={{ value: $sparks.egress.current / 1024 / 1024, digits: 2, unit: 'Mbit/s' }}
												usage={{ value: $sparks.egress.limit ? $sparks.egress.usage : 'NAN', digits: 2, unit: '%' }}
												limit={{ value: $sparks.egress.limit / 1024 / 1024, digits: 0, unit: 'Mbit/s' }}
												formatter={{ factor: 1 / 1024 / 1024 }}
											/>
										</Grid>
										<Grid item xs={12} md={4}>
											<PageChart
												size={3}
												title={<Trans>Ingress</Trans>}
												data={$sparks.ingress.data}
												min={$sparks.ingress.min}
												max={$sparks.ingress.max}
												color={$sparks.ingress.color}
												current={{ value: $sparks.ingress.current / 1024 / 1024, digits: 2, unit: 'Mbit/s' }}
												usage={{
													value: $sparks.ingress.limit ? $sparks.ingress.usage : 'NAN',
													digits: 2,
													unit: '%',
												}}
												limit={{ value: $sparks.ingress.limit / 1024 / 1024, digits: 0, unit: 'Mbit/s' }}
												formatter={{ factor: 1 / 1024 / 1024 }}
											/>
										</Grid>
									</Grid>
									<Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={4} style={{ marginBottom: 30 }}>
										<Grid item xs={12} md={3}>
											<PageChart
												size={4}
												title={<Trans>System CPU</Trans>}
												data={$sparks.cpu.data}
												min={$sparks.cpu.min}
												max={$sparks.cpu.max}
												color={$sparks.cpu.color}
												cores={{ value: $coreAbout.ncpu, digits: 2, unit: '' }}
												arch={{ value: $coreAbout.version, digits: 0, unit: '%' }}
												current={{ value: $sparks.cpu.current, digits: 2, unit: '%' }}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<PageChart
												size={4}
												title={<Trans>System Memory</Trans>}
												data={$sparks.mem.data}
												min={$sparks.mem.min}
												max={$sparks.mem.max}
												color={$sparks.mem.color}
												current={{ value: $sparks.mem.usage, digits: 2, unit: '%' }}
												usage={{ value: $sparks.mem.current / 1024 / 1024 / 1024, digits: 3, unit: 'GB' }}
												limit={{ value: $sparks.mem.limit / 1024 / 1024 / 1024, digits: 3, unit: 'GB' }}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<PageChart
												size={4}
												title={<Trans>In-Memory Filesystem</Trans>}
												data={$sparks.memfs.data}
												min={$sparks.memfs.min}
												max={$sparks.memfs.max}
												color={$sparks.memfs.color}
												current={{ value: $sparks.memfs.usage, digits: 2, unit: '%' }}
												usage={{ value: $sparks.memfs.current / 1024 / 1024 / 1024, digits: 3, unit: 'GB' }}
												limit={{ value: $sparks.memfs.limit / 1024 / 1024 / 1024, digits: 3, unit: 'GB' }}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<PageChart
												size={4}
												title={<Trans>Disk Filesystem</Trans>}
												data={$sparks.diskfs.data}
												min={$sparks.diskfs.min}
												max={$sparks.diskfs.max}
												color={$sparks.diskfs.color}
												current={{ value: $sparks.diskfs.usage, digits: 2, unit: '%' }}
												usage={{ value: $sparks.diskfs.current / 1024 / 1024 / 1024, digits: 3, unit: 'GB' }}
												limit={{ value: $sparks.diskfs.limit / 1024 / 1024 / 1024, digits: 3, unit: 'GB' }}
											/>
										</Grid>
									</Grid>
									<Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={4} style={{ marginBottom: 30 }}>
										<Grid item xs={12} md={3}>
											<PageChart
												size={4}
												title={<Trans>Process running</Trans>}
												data={$sparks.running.data}
												min={$sparks.running.min}
												max={$sparks.running.max}
												color={$sparks.running.color}
												current={{ value: $sparks.running.current, digits: 0, unit: '' }}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<PageChart
												size={4}
												title={<Trans>Process finished</Trans>}
												data={$sparks.finished.data}
												min={$sparks.finished.min}
												max={$sparks.finished.max}
												color={$sparks.finished.color}
												current={{ value: $sparks.finished.current, digits: 0, unit: '' }}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<PageChart
												size={4}
												title={<Trans>Process killed</Trans>}
												data={$sparks.killed.data}
												min={$sparks.killed.min}
												max={$sparks.killed.max}
												color={$sparks.killed.color}
												current={{ value: $sparks.killed.current, digits: 0, unit: '' }}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<PageChart
												size={4}
												title={<Trans>Process failed</Trans>}
												data={$sparks.failed.data}
												min={$sparks.failed.min}
												max={$sparks.failed.max}
												color={$sparks.failed.color}
												current={{ value: $sparks.failed.current, digits: 0, unit: '' }}
											/>
										</Grid>
									</Grid>
								</React.Fragment>
							) : (
								<Stack direction="column" justifyContent="center" alignItems="center" spacing={5} style={{ margin: '0px 100px 100px 100px' }}>
									<CircularProgress color="inherit" />
								</Stack>
							)}
							<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} style={{ marginBottom: 20 }}>
								<Typography variant="h2" gutterBottom>
									<Trans>Processes</Trans>
								</Typography>
							</Stack>
						</Grid>
					</Grid>
				}
				sidebarContent={Array.from($coreAlerts.values())
					.sort((a, b) => {
						return a.date.isBefore(b.date) ? 1 : a.date.isAfter(b.date) ? -1 : 0;
					})
					.map((alert) => (
						<Alert
							what={alert.what}
							key={alert.id}
							title={alert.title}
							core_name={alert.core_name}
							message={alert.message}
							level={alert.level}
							date={alert.date.format('YYYY.MM.DD HH:mm:ss')}
							variant="danger"
							link={alert.link}
						/>
					))}
			>
				<Stack direction="column" justifyContent="space-between" alignItems="flex-start" spacing={2}>
					<React.Fragment>
						<Table cells={processCells} rows={processRows} order="asc" orderBy="id" rowsPerPage={25} rowsPerPageOptions={[10, 25, 50, 100]}></Table>
					</React.Fragment>
				</Stack>
			</Page>
			<Dialog
				open={$coreDelete.open}
				onClose={handleCoreDeleteDialog}
				title={
					<React.Fragment>
						<DeleteForeverIcon style={{ marginBottom: -5, marginRight: 10 }} />
						<Trans>Do you want to delete this core?</Trans>
					</React.Fragment>
				}
				buttonsLeft={
					<Button variant="outlined" color="primary" onClick={handleCoreDeleteDialog}>
						<Trans>Abort</Trans>
					</Button>
				}
				buttonsRight={
					<Button variant="outlined" color="secondary" disabled={!allowWrite || $coreDelete.name !== $core.name} onClick={handleCoreDelete}>
						<Trans>Delete</Trans>
					</Button>
				}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography>
							<Trans>Deleting a core cannot be reversed. The core will not be able to send monitoring data anymore.</Trans>
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography>
							<Trans>Please enter the name of the core in the field below.</Trans>
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							fullWidth
							label={<Trans>Name</Trans>}
							value={$coreDelete.name}
							disabled={!allowWrite}
							onChange={handleCoreDeleteName}
						/>
					</Grid>
				</Grid>
			</Dialog>
			<Dialog
				open={$coreSettings}
				onClose={handleCoreSettingsAbort}
				title={
					<React.Fragment>
						<SettingsIcon style={{ marginBottom: -5, marginRight: 10 }} />
						<Trans>Settings</Trans>
					</React.Fragment>
				}
				size="large"
				buttonsRight={
					<Button variant="outlined" color="primary" onClick={handleCoreSettingsAbort}>
						<Trans>Got it</Trans>
					</Button>
				}
			>
				<Settings disabled={!allowWrite} groupid={groupid} core={$core} coreAPI={$coreAPI} />
			</Dialog>
			<Dialog
				open={$coreLog.open}
				onClose={handleCoreLogDialog}
				title={
					<React.Fragment>
						<ArticleIcon style={{ marginBottom: -5, marginRight: 10 }} />
						<Trans>Logging</Trans>
					</React.Fragment>
				}
				size="large"
				footer="false"
			>
				<Textarea value={$coreLog.data.join('\n')} scrollTo="bottom" />
			</Dialog>
			<Dialog
				open={$coreFs.open}
				onClose={handleCoreFsDialog}
				title={
					<React.Fragment>
						<FindInPageIcon style={{ marginBottom: -5, marginRight: 10 }} />
						<Trans>Filesystem</Trans>
					</React.Fragment>
				}
				size="large"
				footer="false"
			>
				<Table cells={filesystemCells} rows={filesystemRows} order="asc" orderBy="id" rowsPerPage={25} rowsPerPageOptions={[10, 25, 50, 100]}></Table>
			</Dialog>
		</React.Fragment>
	);
}

Core.defaultProps = {};
